import React from "react";

import first from "../../assets/icon/award1.svg";
import second from "../../assets/icon/award 2nd.svg";
import award_icon from "../../assets/icon/verify.svg";

interface ChildProps {
  productsData: any;
  userId: number;
  flagTitle: boolean;
}

const BiddingStar: React.FC<ChildProps> = ({
  productsData,
  userId,
  flagTitle,
}) => {
  return (
    <>
      {productsData?.bid_order === 1 && (
        <div>
          <img className="verify-icon" src={first} alt="" />
          <br />
          {flagTitle ? (
            <div className="text-yellow my-4">คุณชนะการประมูล</div>
          ) : (
            <></>
          )}
        </div>
      )}

    {/* {productsData?.product_purchased_by == userId && (
        <div>
          <img className="verify-icon" src={first} alt="" />
          <br />
          {flagTitle ? (
            <div className="text-yellow my-4">คุณชนะการประมูล</div>
          ) : (
            <></>
          )}
        </div>
      )} */}

      {productsData?.bid_order === 2 && productsData?.is_winner ===0 && productsData?.product_purchased_by == null && (
        <div>
          <img className="verify-icon" src={second} alt="" />
          <br />
          {flagTitle ? (
            <div className="text-yellow my-4">คุณชนะลำดับที่ 2</div>
          ) : (
            <></>
          )}
        </div>
      )}

      {productsData?.bid_order === 2 && productsData?.is_winner ===1 && productsData?.product_purchased_by == null && (
        <div>
          <img className="verify-icon" src={second} alt="" />
          <br />
          {flagTitle ? (
            <div className="text-yellow my-4">คุณเป็นผู้ชนะการประมูลเนื่องจากผู้ชนะลำดับที่ 1 สละสิทธิ์</div>
          ) : (
            <></>
          )}
        </div>
      )}

      {productsData?.bid_order === 3 && productsData?.product_purchased_by == null && (
        <div>
          <img className="verify-icon" src={award_icon} alt="" />
          <br />
          {flagTitle ? (
            <div className="text-yellow my-4">สินค้านี้มีผู้ชนะประมูลไปแล้ว</div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default BiddingStar;
