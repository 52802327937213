import React,{useState} from "react";
import "./footer.scss";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { TagFormat } from "../components";
import Spacer from "../components/Spacer";
import logo from "../assets/Logo2.png";
import facebook from "../assets/icon/facebook.svg";
import twitter from "../assets/icon/twitter.svg";
import line from "../assets/icon/Line.svg";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../components/Modal";

const Footer = () => {
  const navigate = useNavigate();
  const [isLoginModal, setLoginModal] = useState(false);

  const handleClose = () => setLoginModal(false);
  const handleShow = () => setLoginModal(true);

  return (
    <React.Fragment>
      <footer className="main-bg">
        <div className="max-width">
          {/* <Row>
            <Col></Col>
            <Col></Col>
          </Row> */}
          <Row className="mx-0">
            <Col xs="12" lg="4">
              <div className="logofooter">
                <img src={logo} className="m-auto w-100 h-100" alt='' />
              </div>
              <TagFormat
                className="footer_about_us_cont mt-4"
                Tag="p"
                text="คันยิสจ๊วตชีสแคมเปญน้องใหม่ แครกเกอร์หลวงตาเลดี้ ซูเปอร์เทป มอคค่าปิยมิตรป่าไม้เอ๋ อินดอร์ไลฟ์ เอ็นจีโอ ม็อบสะกอม งั้นภคันทลาพาธตื้บเช็ก"
              />
            </Col>
            <Spacer width="5rem" height={null} />
            <Col xs="12" lg="7" className="desktop-view">
              <Row>
                <Col>
                  <ul>
                    <li>บริการลูกค้า</li>
                    <li onClick={() => navigate("/faq")}>คำถามที่พบบ่อย</li>
                    <li onClick={() => navigate("/ProductAction")}>
                      วิธีการประมูลสินค้า
                    </li>
                    <li onClick={() => navigate("/PaymentMethod")}>
                      วิธีการชำระเงิน
                    </li>
                    <li onClick={() => navigate("/ProductWarrenty")}>
                      การรับประกันสินค้า
                    </li>
                    <li onClick={() => navigate("/MyCart")}>ติดตามสถานะจัดส่ง</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li onClick={() => navigate("/AboutUs")}>เกี่ยวกับเรา</li>
                    <li onClick={() => navigate("/Contactus")}>ติดต่อเรา</li>
                    <li onClick={() => navigate("/ItemOfAction")}>
                      แจ้งปัญหาการใช้งาน
                    </li>
                    <li onClick={() => navigate("/ReportsUsage")}>
                      ลงสินค้าประมูล
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>นโยบายด้านกฏหมาย</li>
                    <li onClick={() => navigate("/TermsOfUse")}>
                      เงื่อนไขการใช้งาน
                    </li>
                    <li onClick={() => navigate("/PrivacyPolicy")}>
                      นโยบายความเป็นส่วนบุคคล
                    </li>
                    <li onClick={() => navigate("/PDPACompliance")}>
                      การปฏิบัติตามข้อกำหนด PDPA
                    </li>
                  </ul>
                  <Row>
                    <TagFormat
                      className="follow_cont"
                      Tag="p"
                      text="Follow Us"
                    />
                    <div style={{ display: "flex" }}>
                      <Col lg="2" className="icon_bg">
                        <a href="https://www.facebook.com/storeignite" target="_blank" rel="noopener noreferrer">
                          <img src={facebook} alt="" />
                        </a>
                      </Col>
                      <Col lg="2" className="icon_bg">
                        <a href="#" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="" />
                        </a>
                      </Col>
                      <Col lg="2" className="icon_bg">
                        <a href="https://lin.ee/BgJ2OKU"  target="_blank" rel="noopener noreferrer">
                          <img src={line} alt="" />
                        </a>
                      </Col>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
            <div className="mobile-view">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>บริการลูกค้า</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                    <li onClick={() => navigate("/faq")}>คำถามที่พบบ่อย</li>
                    <li onClick={() => navigate("/ProductAction")}>
                      วิธีการประมูลสินค้า
                    </li>
                    <li onClick={() => navigate("/PaymentMethod")}>
                      วิธีการชำระเงิน
                    </li>
                    <li onClick={() => navigate("/ProductWarrenty")}>
                      การรับประกันสินค้า
                    </li>
                    <li onClick={() => navigate("/MyCart")}>ติดตามสถานะจัดส่ง</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={() => navigate("/AboutUs")}>
                    เกี่ยวกับเรา
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li onClick={() => navigate("/Contactus")}>ติดต่อเรา</li>
                      <li onClick={() => navigate("/ItemOfAction")}>
                        แจ้งปัญหาการใช้งาน
                      </li>
                      <li onClick={() => navigate("/ReportsUsage")}>
                        ลงสินค้าประมูล
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>นโยบายด้านกฏหมาย</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li onClick={() => navigate("/TermsOfUse")}>
                        เงื่อนไขการใช้งาน
                      </li>
                      <li onClick={() => navigate("/PrivacyPolicy")}>
                        นโยบายความเป็นส่วนบุคคล
                      </li>
                      <li onClick={() => navigate("/PDPACompliance")}>
                        การปฏิบัติตามข้อกำหนดPDPA
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Row>
                <div style={{ textAlign: "center" }}>
                  <TagFormat className="follow_cont" Tag="p" text="Follow Us" />
                </div>
                <div className="social-links text-center text-md-center">
                  <a href="https://www.facebook.com/storeignite" target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="" className="mx-1 cursor-pointer" />
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="" className="mx-1" />
                  </a>
                  <a href="https://lin.ee/BgJ2OKU" className="cursor-pointer" target="_blank" rel="noopener noreferrer">
                    <img src={line} alt="" className="mx-1 " />
                  </a>
                </div>
              </Row>
            </div>
          </Row>
        </div>
      </footer>
      <TagFormat
        className="footer_copyright text-center"
        text="© Copyright 2023 ignitestores.com. All Rights Reserved"
        Tag="p"
      />
       <ModalComponent isOpen={isLoginModal} isClose={handleClose} />
    </React.Fragment>
  );
};

export default Footer;
