import React from "react";
import "./component.scss";
import icon1 from "../assets/icon/why_us1.svg";
import icon2 from "../assets/icon/Why us 3.svg";
import icon3 from "../assets/icon/Why us 4.svg";
import icon4 from "../assets/icon/Why-us-1.svg";
import icon5 from "../assets/icon/Why-us-1.svg";
import Spacer from "./Spacer";

const WhyUs = () => {
  return (
    <div className="col-md-12 why-us px-0">
      <div className="header p-3 text-center mb-5">ทำไมต้องมาประมูลกับเรา</div>
      <div className="row m-0">
        <div className="col-md-12 col-lg-3 col-sm-12 col-xs-12 d-flex my-4">
          <img className="mb-auto px-2" src={icon1} alt='' />
          <div className="">
            <div className="header">รับประกันสินค้าลิขสิทธิ์แท้</div>
            <div className="content">
              ไคลแมกซ์บอดี้มอคค่าเซฟตี้ฮิ อิมพีเรียลสต๊อค ปฏิสัมพันธ์ทับซ้อนแซว
              คอมพ์
            </div>
          </div>
        </div>
        {/* <Spacer height='2rem' width='' /> */}
        <div className="col-md-12 col-lg-3 col-sm-12 col-xs-12 d-flex my-4">
          <img className="mb-auto px-2" src={icon2}></img>
          <div className="">
            <div className="header">จ่ายง่าย ครบทุกช่องทาง</div>
            <div className="content">
              เยอบีร่าพาสเจอร์ไรส์โซนรามาธิบดีเมคอัพ บ๋อยมุมมองแม็กกาซีน
              อันตรกิริยาบัสโหลยโท่ยอีโรติก{" "}
            </div>
          </div>
        </div>
        {/* <Spacer height='2rem' width='' /> */}
        <div className="col-md-12 col-lg-3 col-sm-12 col-xs-12 d-flex my-4">
          <img className="mb-auto px-2" src={icon3}></img>
          <div className="">
            <div className="header">ร่วมประมูลได้ตลอด 24 ชม.</div>
            <div className="content">
              พิสัยแคนยอนพล็อต เซ็นเซอร์ม้าหินอ่อนรุมบ้าทับซ้อน
              แอปเปิลโบกี้ล็อบบี้ตรวจทานดอกเตอร์ จุ๊ยรีวิวสกายลามะ{" "}
            </div>
          </div>
        </div>
        {/* <Spacer height='2rem' width='' /> */}
        <div className="col-md-12 col-lg-3 col-sm-12 col-xs-12 d-flex my-4">
          <img className="mb-auto px-2" src={icon4}></img>
          <div className="">
            <div className="header">จัดส่งปลอดภัย</div>
            <div className="content">
              ไลน์วิภัชภาคนางแบบ กรอบรูปรีพอร์ทงี้อ่อนด้อยอพาร์ตเมนต์
              สเปกสกายจิต
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
