import ApiService from "../config/api";

export const addAddressapi = async (data: any) => {
  try {
    let response = await ApiService.post(
      "user/address/createOrUpdateAddress",
      data
    );
    return { status: true, response: response };
  } catch (error: any) {
    return { status: false, response: error.message };
  }
};

export const getAddressApi = async () => {
  try {
    let response = await ApiService.get("user/myaddress");
    return { status: true, response: response };
  } catch (error: any) {
    return { status: false, response: error.message };
  }
};
export const updateAddress = async (data: any) => {
  try {
    let response = await ApiService.post("user/address/update", data);
    return { status: true, response: response };
  } catch (error: any) {
    return { status: false, response: error.message };
  }
};
export const deleteAddressApi = async (data: any) => {
  try {
    let response = await ApiService.delete(`user/address/delete/${data}`);
    return { status: true, response: response };
  } catch (error: any) {
    return { status: false, response: error.message };
  }
};
