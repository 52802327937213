import "../component.scss";
import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import { Link, useNavigate } from "react-router-dom";
import verify from "../../assets/icon/screen-chech.svg";
import { useDispatch } from "react-redux";
import { clearBiddingProductData } from "../../store/bidding/action";

const BiddingCompleteModal = ({ isOpen, isClose }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    isClose();
  };
  const handleRedirect = () => {
    dispatch(clearBiddingProductData());
    navigate("/MyCart");
  };
  return (
    <Modal show={isOpen}>
      <img src={close} alt="" onClick={closeModal} />
      <div className="col-md-12 mt-4 payment-complete">
        <div className="text-center cnt-container">
          <div className="text-center">
            <img src={verify} className="close-img" alt=""></img>
          </div>
          <div className="cnt mt-4">รายการสั่งซื้อ เสร็จสมบูรณ์</div>
          <div>
            ขอบคุณที่เข้าร่วมประมูลกับเรา เรากำลังเตรียมสินค้าของท่านและ
            จัดส่งภายใน 48 ชั่วโมง
          </div>
          <button className="mt-5" onClick={handleRedirect}>
            ดูบัญชีของฉัน
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BiddingCompleteModal;
