import { combineReducers } from "redux";

import auth from "./auth/reducer";
import login from "./login/reducer";
import loader from "./loader/loaderSlice";
import headerFooter from "./common/HeaderFooterSlice";
import biddingProduct from "./bidding/reducer";

export default combineReducers({
  auth,
  login,
  loader,
  headerFooter,
  biddingProduct,
});
