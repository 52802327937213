import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import CheckoutCreditCard from "./CheckoutCreditCard";
import ApiService from "../../config/api";
import Spacer from "../Spacer";
import CheckoutInternetBanking from "./CheckoutInternetBanking";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import { setBiddingPaymentStatus } from "../../store/bidding/action";
import { showLoader } from "../../store/loader/loaderSlice";

const ChoosePaymentModal = ({ isOpen, isClose }: any) => {
  const biddingProduct = useSelector((state: any) => state.biddingProduct);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const closeModal = () => {
    isClose();
  };

  console.log(biddingProduct);
  //PAY WITH CREDIT CARD
  const createCreditCards = async (token: any) => {
    console.log(biddingProduct);
    const data = {
      auctionId: biddingProduct?.auctionId,
      productId: biddingProduct?.productId,
      amount: biddingProduct?.biddingAmount * 100,
      token: token,
      shippingId: biddingProduct?.shippingId,
      paymentType: biddingProduct?.paymentType,
      depositAmount:biddingProduct?.depositAmount,
      serviceAmount:biddingProduct?.serviceAmount,
      isBuyOutPayment:biddingProduct?.isBuyOutPayment
    };
    try {
      const res = await ApiService.post("/payment/checkout-creditCard", data);

      if (res.authorizeUri) {
        window.location.href = res.authorizeUri;
      }
      dispatch(showLoader());

      // if (res && res.status === "successful") {
      //   dispatch(setBiddingPaymentStatus("PAID"));
      // } else {
      //   setError(res["message"]);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  //PAY WITH NETBANKING
  const createInternetBankingCharge = async (token: any) => {
    try {
      const data = {
        auctionId: biddingProduct?.auctionId,
        productId: biddingProduct?.productId,
        amount: biddingProduct?.biddingAmount * 100,
        token: token,
        shippingId: biddingProduct?.shippingId,
        paymentType: biddingProduct?.paymentType,
        depositAmount:biddingProduct?.depositAmount,
        serviceAmount:biddingProduct?.serviceAmount,
        isBuyOutPayment:biddingProduct?.isBuyOutPayment
      };
      const res = await await ApiService.post(
        "/payment/checkout-internetBanking",
        data
      );
      dispatch(showLoader());
      console.log(res);
      if (res.authorizeUri) {
        // props.clearCart()
        window.location.href = res.authorizeUri;
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (error) {
    toast.warning(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  const cart = {
    amount: biddingProduct?.biddingAmount * 100,
  };

  return (
    <>
      <Modal show={isOpen}>
        <img src={close} alt="" onClick={closeModal} />
        <Spacer height={"5rem"} width={0} />
        <CheckoutCreditCard
          cart={cart}
          createCreditCardCharge={createCreditCards}
        />
        <Spacer height={"2rem"} width={0} />
        <CheckoutInternetBanking
          cart={cart}
          createCreditCardCharge={createInternetBankingCharge}
        />
      </Modal>
    </>
  );
};
export default ChoosePaymentModal;
