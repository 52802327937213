import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import logging from "./config/logging";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "./layout/header";
import Footer from "./layout/footer";
import Home from "./layout/main";
import Product from "./pages/Product/Product";
import Register from "./pages/Register";
import Faq from "./pages/Faq";
import AboutUs from "./pages/Home/footerpages/AboutUs";
import ProductList from "./pages/Product/ProductList";
import ProductDetails from "./pages/Product/ProductDetails";
import MyFavorite from "./pages/Favourites/MyFavorite";
import MyAccount from "./pages/MyAccount";
import Address from "./pages/Address";
import BankDetails from "./pages/BankDetails";
import MyCart from "./pages/Product/MyCart";
import ProductCategory from "./pages/Product/ProductsCatergory";
import PaymentMethod from "./pages/paymentMethod";
import ProductWarranty from "./pages/Home/ProductWarranty";
import ChangePassword from "./pages/ChangePassword";
import TermsOfUse from "./pages/Home/footerpages/TermsOfUse";
import PrivacyPolicy from "./pages/Home/footerpages/PrivacyPolicy";
import PDPACompliance from "./pages/Home/footerpages/PDPACompliance";
import ItemOfAction from "./pages/Home/footerpages/ItemsOfAction";
import Contactus from "./pages/Home/footerpages/ContactUs";
import ReportsUsage from "./pages/Home/footerpages/Reports";
import Loader from "./loader";
import ModalComponent from "./components/Modal";
import { socket } from "./socket";
import { ConnectionState } from "./socket/ConnectionState";
import { Events } from "./socket/Events";
import { ConnectionManager } from "./socket/ConnectionManager";
import Protected from "./Protected";
import ProductAction from "./pages/ProductAction";
import ScrollToTopOnPageChange from "./layout/ScrollToTopOnPageChange";
import Preview from "./pages/Home/preview";
import PreviewPage from "./layout/previewPage";
import PaymentOrderAddress from "./pages/PaymentOrderAddress";

const App = () => {
  const sessionData = useSelector((state: any) => state.login);
  const isLoading = useSelector((state: any) => state?.loader?.loading);
  const isHeaderFooter = useSelector((state: any) => state?.headerFooter?.headerFooter);
  console.log(isHeaderFooter);
  console.log(isLoading);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState<any>([]);
  const [isBiddingLoading, setIsLoading] = useState(false);
  const [biddingValue, setBiddingValue] = useState<any>([]);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    const onFooEvent = (value: any) => {
      console.log(value);
      const data = value?.greets?.[0];
      setBiddingValue(data?.response?.aucResults);
      setFooEvents((previous: any) => [...previous, value.greeting]);
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("greeting-from-server", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("greeting-from-server", onFooEvent);
    };
  }, []);
  return (
    <div>
      {isLoading ? <Loader /> : <></>}
      {isHeaderFooter ? <Header /> : <></>}
      
      <div style={{ display: "none" }}>
        <ConnectionState isConnected={isConnected} />
        <Events events={fooEvents} />
        <ConnectionManager />
      </div>
      <ScrollToTopOnPageChange />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/preview/:type/:id" element={<PreviewPage />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/highlight/:id" element={<Product />} />
        <Route path="/productdetails" element={<ProductDetails />} />
        <Route path="/productlist" element={<ProductList />} />
        <Route path="/category" element={<ProductCategory />} />
        <Route path="/FAQ" element={<Faq />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/ProductWarrenty" element={<ProductWarranty />} />
        <Route path="/PaymentMethod" element={<PaymentMethod />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/TermsOfUse" element={<TermsOfUse />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/PDPACompliance" element={<PDPACompliance />} />
        <Route path="/ItemOfAction" element={<ItemOfAction />} />
        <Route path="/ProductAction" element={<ProductAction />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/ReportsUsage" element={<ReportsUsage />} />
        <Route
          path="/myAccount"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <MyAccount />
            </Protected>
          }
        />
        <Route
          path="/myFavorite"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <MyFavorite />
            </Protected>
          }
        />
        <Route
          path="/MyCart"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <MyCart />
            </Protected>
          }
        />
        <Route
          path="/myAddress"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <Address />
            </Protected>
          }
        />
        <Route
          path="/paymentOrderAddress"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <PaymentOrderAddress />
            </Protected>
          }
        />
        <Route
          path="/bankaccount"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <BankDetails />
            </Protected>
          }
        />
        <Route
          path="/PaymentMethod"
          element={
            <Protected isLoggedIn={sessionData.isAuthenticated}>
              <PaymentMethod />
            </Protected>
          }
        />
      </Routes>
      
      {isHeaderFooter ? <Footer /> : <></>}
      <ModalComponent isOpen={sessionData?.openLoginModal} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps)(App);
