import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CardThree from "../../components/CardThree";
import ApiService from "../../config/api";
import FooterSecondary from "../../layout/footerSecondary";
import WhyUs from "../../components/WhyUs";
import WrongIcon from "../../assets/icon/wrong.svg";
import CardFour from "../../components/CardFour";
const ProductCategory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchKeyword = location.state?.data;
  const filterClicked = location.state?.filterClicked;
  const [searchKey, seySearchKey] = useState("");
  const [isfilterClicked,setIsFilterClicked] = useState("");
  const [filterProducts, setFilterProducts] = useState<any>(null);
  const [actionPriceData, setActionPriceData] = useState("");
  const [isUpcoming, setUpcoming] = useState(false);
  const [error, setError] = useState(null);
  const [isCategoryOpen, setCategoryOpen] = useState(false);

  useEffect(() => {
 
    if(searchKeyword){
      console.log(searchKeyword);
      seySearchKey(searchKeyword);
    }
    
    setIsFilterClicked(filterClicked);
  }, [searchKeyword,filterClicked]);
  
  const getFilterProducts = async () => {
    const data = searchKey;
    dispatch(showLoader());
    // const filterres = await getFilterProductsApi({ name: data });
    const filterResponse = await ApiService.post('/user/filterProduct',{ name: data });
    if (filterResponse.status === "SUCCESS") {
      setFilterProducts(filterResponse?.response);
      dispatch(hideLoader());
    } else {
      setError(filterResponse.response);
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    if (searchKey || searchKey === '') {
      getFilterProducts();
    }
  }, [searchKey]);

  useEffect(() => {
    return;
  }, []);

  const filterSubmit = () => {
    const req = {
      name: searchKey,
      filter: actionPriceData,
      showUpComing: isUpcoming,
    };
    const getFilterProducts = async () => {
      const filterResponse = await ApiService.post('/user/filterProduct',req );
      dispatch(showLoader());
      setFilterProducts(filterResponse?.response);
      setCategory(false);
      dispatch(hideLoader());
    };
    getFilterProducts();
    return () => {
      getFilterProducts();
    };
  };

  const [isCatergory, setCategory] = useState(false);
  const handleFilterCont = () => {
    if (isCatergory) {
      setCategory(false);
    } else {
      setCategory(true);
    }
  };

  const actionCategoryPayload = [
    { id: 1, type: "upcoming", data: "Bidding1" },
    { id: 1, type: "upcoming", data: "Bidding4" },
    { id: 1, type: "Started", data: "Bidding3" },
    { id: 1, type: "upcoming", data: "Bidding2" },
  ];
  const [actionFilterData, setActionFilterData] = useState<any>(
    actionCategoryPayload
  );

  const listActionType = (data: any) => {
    if (data.value === "All" && data.checked === true) {
      setUpcoming(false);
      setActionFilterData(actionCategoryPayload);
    } else if (data.value === "Upcoming" && data.checked === false) {
      setUpcoming(true);
      const setActionFilter = actionCategoryPayload.filter(
        (item) => item.type === "upcoming"
      );
      setActionFilterData(setActionFilter);
    }
  };
  const handleActionPrice = (e: any) => {
    setActionPriceData(e.target.value);
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  
  return (
    <div className="bg-dark main-container-bg category-page">
      <div className="max-width">
      {filterProducts && (
        <div className="home-page-mobil-max-width">
        <div className="category-container">
          <div className="col-12 py-5 d-flex" style={{ position: "relative" }}>
            {/* {isCatergory && ( */}
            {isfilterClicked &&(
              <div
                className="col-lg-3 col-md-12 col-sm-12 col-12 cat-left-cont z-1"
              >
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div className="col-2 justify-content-start d-flex px-0 h-25 " >
                      <img src={WrongIcon} alt="WrongIcon" className="cursor-pointer" onClick={() => setIsFilterClicked('')}  />
                    </div>
                    <span className="filter_text">กรองสินค้า</span>
                    <span className="default_text">ค่าเริ่มต้น</span>
                  </div>
                  <div className="search_list_cont py-3">
                    <p>
                      ผลการค้นหา : {filterProducts.search_key}{" "}
                      {filterProducts.item_count} รายการ
                    </p>
                  </div>
                  <div className="sorting_date">
                    <div className="py-lg-3 py-sm-0">
                      <p>การเรียงลำดับข้อมูล</p>
                      <select
                        className="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        onChange={handleActionPrice}
                      >
                        {priceList.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <p>ประเภทการประมูล</p>
                      <div className="actionTypeFilter">
                        {actionType.map((item, key) => (  
                          <div key={key} className="py-1 col-12">
                            <input
                              type="radio"
                              name="actions"
                              value={item.value}
                              defaultChecked={item.checked}
                              onClick={() => listActionType(item)}
                            />
                            <label htmlFor={item.value}>{item.value}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={filterSubmit}
                      className="py-2 filter-products"
                    >
                      กรองสินค้า
                    </button>
                  </div>
                </div>
              </div>
            )}
          {/*  )} */}
          
            <div className={`col-lg-${isfilterClicked? 9 : 12}`}>
              <span className="category_title px-lg-4">
                {searchKeyword === "" ? "All Products" : searchKeyword} <span onClick={handleFilterCont}></span>
              </span>
              <div className={`row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-${isfilterClicked? 2 :3} row-cols-xl-${isfilterClicked? 3 :4} bidding-card`}>
                {filterProducts?.products?.map((item: any, index: any) => (
                  <div key={index} className="col">
                    <CardFour datas={item} />
                  </div>
                ))}
              </div>

            </div>
          </div>

          {/* MODAL  */}
          {/* <button onClick={() => setCategoryOpen(true)}>Open</button> */}
          {isCategoryOpen === true && (
            <div className="category_modal_conta py-5 px-4">
              <div className="d-flex justify-content-between">
                <p className="filter_text">กรองสินค้า</p>
                <p className="default_text">ค่าเริ่มต้น</p>
              </div>
              <div className="filter_sorting_date">
                <div className="mt-4">
                  <p>การเรียงลำดับข้อมูล</p>
                  <select className="form-select form-select-lg mb-3">
                    <option value="1">ราคามาก - น้อย</option>
                  </select>
                </div>
                <div className="mt-4">
                  <p>วันประมูลสินค้า</p>
                  <select
                    className="form-select form-select-lg mb-2"
                    aria-label=".form-select-lg example"
                  >
                    <option value="1">ราคามาก - น้อย</option>
                  </select>
                </div>
              </div>
              <button className="py-2 filter-products">กรองสินค้า</button>
            </div>
          )}
        </div>
        </div>
      )}
       {/* <WhyUs /> */}
       </div>
       
       <FooterSecondary />
    </div>
  );
};

export default ProductCategory;

const priceList = [
  { id: "high-low", text: "ราคาสูงไปต่ำ" },
  { id: "low-high", text: "ราคาต่ำไปสูง" },
  { id: "latest-old", text: "วันที่เริ่มต้นการประมูลจากล่าสุดไปเก่าที่สุด" },
  { id: "old-latest", text: "วันที่เริ่มต้นการประมูลเก่าที่สุดถึงล่าสุด" },
];
const actionType = [
  {
    id: 1,
    value: "All",
    checked: true,
  },
  {
    id: 1,
    value: "Upcoming",
    checked: false,
  },
];
