import "../../layout/main.scss";
import CardTwo from "../../components/CardTwo";
import CardThree from "../../components/CardThree";
import SendItemSection from "../../components/SendItemSection";
import WhyUs from "../../components/WhyUs";
import Banner from "../../components/Banner";
import CarouselScreen from "../../components/Carousel";
import { useEffect, useState } from "react";
import closeIconWhite from "../../assets/icon/close-white.svg";
import closeIconBlack from "../../assets/icon/close.svg";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { toast } from "react-toastify";
import ApiService from "../../config/api";
import { clearBiddingProductData } from "../../store/bidding/action";
import { Link, useNavigate, useParams } from "react-router-dom";
import CardFour from "../../components/CardFour";
import MobileCarouselScreen from "../../components/MobileCarousel";



const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseValue, setResponseValue] = useState<any>();
  const [announcementItems, setAnnouncementItems] = useState<any>();
  const [heroBannerItems, setHeroBannerItems] = useState<any>();
  const [slideBannerItems, setSlideBannerItems] = useState<any>();
  const [error, setError] = useState<string | null>(null); // Specify the error type as string | null

  let { type, id } = useParams();
  console.log(id);

  useEffect(() => {
    dispatch(clearBiddingProductData());
    getPreviewDetail();
  }, []);

  const getPreviewDetail = async () => {
    if (type === 'hero' || type === 'announcement' || type === 'slide') {
      getHomePageApi();
      dispatch(showLoader());
      const responseVal = await ApiService.post("/banner/detail", { bannerId: id });
      const data = responseVal?.response;
      if (responseVal.status === "SUCCESS") {
        const previewData = [];
        previewData.push(data);
        console.log(previewData);
        if (type === 'hero') {
          setHeroBannerItems(previewData);
        } else if (type === 'announcement') {
          setAnnouncementItems(previewData);
        } else if (type === 'slide') {
          setSlideBannerItems(previewData);
          console.log(slideBannerItems)
        }
        dispatch(hideLoader());
      } else {
        setError(responseVal.response);
        dispatch(hideLoader());
      }
    }
  };

  const getHomePageApi = async () => {
    dispatch(showLoader());
    const responseVal = await ApiService.get("/user/homePageData");
    const data = responseVal?.response;
    if (responseVal.status === "SUCCESS") {
      setResponseValue(data);

        if (type !== 'hero') {
          setHeroBannerItems(data.heroBannerItems);
        }

      if (type !== 'announcement') {
        setAnnouncementItems(data.announcementItems);
        }
     
      if (type !== 'slide') {
      setSlideBannerItems(data.slideBannerItems);
      }

      dispatch(hideLoader());
    } else {
      setError(responseVal.response);
      dispatch(hideLoader());
    }
  };

  if (error) {
    toast.warning(error, { // Assuming toast is imported from a toast library
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  const closeAnnouncement = (index: number) => {
    setAnnouncementItems((items: any) =>
      items.filter((_: any, i: any) => index !== i)
    );
  };

  return (
    <>
      {responseValue && (
        <>
          {announcementItems &&
            announcementItems.map((item: any, i: any) => (
              <div
                style={{
                  backgroundColor: item.bannercolor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                key={i}
                className="py-3 bannermessage"
              >
                <div  className="px-4"
                  style={{
                    marginBottom: "0px",
                    color: item.bannercolor == '#FFFFFF' ? '#000' : '#FFF',
                    fontSize: 14,
                    fontWeight: "300",
                  }}
                >
                  {item.bannermessage}
                </div>
                <img
                  onClick={() => closeAnnouncement(i)}
                  className="cursor-pointer"
                  style={{ position: "absolute", right: 22, width: "11px" }}
                  src={item.bannercolor === '#FFFFFF' ? closeIconBlack : closeIconWhite }
                  alt=""
                />
              </div>
            ))}
          <div className="max-width">
          <div className="row mx-0">
            <Banner datas={heroBannerItems} />
          </div>
          <div className="row mx-0">
            <CarouselScreen datas={slideBannerItems} />
            <MobileCarouselScreen datas={slideBannerItems} />
            <div className="home-page-mobil-max-width">
            <div className="row mx-0" id="hot-item">
              <div className="col px-md-0 hot-item">

              {responseValue.hotItems.length > 0 ? (
                <div className="card-primary-header">Hot Item</div>
              ) : (
                <div></div>
              )}
                
                <CardTwo datas={responseValue.hotItems} />
              </div>
            </div>
            {responseValue.productSectionItems.map((slide: any, i: any) => (
              <div className="mt-5 p-lg-0" key={i}>
                <div className="">
                  <div className="card-primary-header">
                    <span className="header-title-cnt">{slide.section}</span>
                    <Link to={`/productlist?sectionName=` + slide.section}>
                      <span className="header-sub-title pt-1">แสดงทั้งหมด</span>
                    </Link>
                  </div>
                </div>
                <div className="home-card-grid-container">
                {slide.products?.slice(0, 4).map((item: any, i: any) => (
                  <div key={i} className="home-card-grid-item">
                    <CardThree datas={item} />
                  </div>
                ))}
                </div>
                
              </div>
            ))}
            {responseValue.biddingItems.length > 0 &&(
            <div className="mt-5 p-lg-0" id="bidding-item">
              <div className="">
                <div className="card-primary-header">
                  <span className="header-title-cnt">Bidding{" "}</span>
                  <Link to={`/productlist?searchKey=bidding`}>
                    <span className="header-sub-title pt-1">แสดงทั้งหมด</span>
                  </Link>
                </div>
              </div>
              <div className="home-card-grid-container bidding-card">
              {responseValue.biddingItems
                ?.slice(0, 4)
                .map((slide: any, i: any) => (
                  <div key={i} className="home-card-grid-item">
                    <CardFour datas={slide} />
                  </div>
                ))}
              </div>
            </div>
            ) }
            </div>
            <SendItemSection></SendItemSection>
            <WhyUs></WhyUs>
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default Preview;
