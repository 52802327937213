import types from "./actionTypes";

export const logInStart = (form: any) => ({
  type: types.LOGIN_START,
  payload: form,
  openModalStatus: false,
});

export const logInSuccess = (user: any) => ({
  type: types.LOGIN_SUCCESS,
  payload: user,
});

export const logInFailure = (error: any) => ({
  type: types.LOGIN_FAILURE,
  payload: error,
});

export const openLoginModal = (status: boolean) => ({
  type: types.OPENLOGINMODAL,
  openModalStatus: status,
});

export const logOut = () => ({
  type: types.LOGOUT,
});
