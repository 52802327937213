import { TagFormat } from "../../../components";
import aboutus from "../../../assets/aboutus.png";
import onePieceLogo from "../../../assets/One-Piece-Logo.png";
import "../styles.scss";
import Spacer from "../../../components/Spacer";
const AboutUs = () => {
  return (
    <div className="about_conatiner">
      <div className="container">
        <TagFormat Tag="h3" className="aboutus-title" text="About Us" />
        <div className="img-cont">
          <img src={aboutus} alt="" />
        </div>
        <div className="desc-cont">
          <p className="aboutus-desciption">
            IGNITE STORE (อิ๊กไนท์ สโตร์) แบรนด์สินค้า Life -style
            ที่ออกแบบโดยใช้ลิขสิทธิ์คาแรคเตอร์ยอดฮิตจากภาพยนตร์จากประเทศสหรัฐอเมริกา
            และอนิเมชั่นการ์ตูนญี่ปุ่นที่มีชื่อเสียง มาผลิตเป็นสินค้าที่มีดีไซน์
            ราคาจับต้องได้และตอบโจทย์ทุกไลฟ์สไตล์ในปัจจุบัน
            มีดีไซน์ที่ชัดเจนโดดเด่นและทันสมัย สามารถนำไปใช้ได้ในชีวิตประจำวัน
            เป็นสินค้าที่ถูกลิขสิทธิ์จึงสามารถเก็บเป็นของสะสมหรือใช้เป็นของขวัญ
            ของฝากได้ในทุกโอกาส มั่นใจได้ว่าสินค้าของอิ๊กไนท์
            เป็นสินค้าลิขสิทธิแท้ทุกรายการ
          </p>{" "}
          <p>
            IGNITE STORE (อิ๊กไนท์ สโตร์) จัดจำหน่ายและดำเนินงานภายใต้ บริษัท
            พีเคเอ็น อินเตอร์ โฮลดิ้ง จำกัด ผู้ผลิตสินค้าพรีเมี่ยมชั้นนำอย่าง
            ถังป๊อบคอร์นโดราเอมอนเดอะมูฟวี่ ถังป๊อบคอร์นวันพีซ
            ถังป๊อบคอร์นโปเกม่อน ของเมเจอร์ซีนีเพล็ก
            การ์ดมาส์คไรเดอร์จีโอของขนมแคมปัส หรือของใช้ในครัวเรือนของบิ๊กซี
            โดยได้รับความเชื่อถือและไว้วางใจจากแบรนด์ชั้นนำมากมาย อาทิเช่น
            ยูนิลีเวอร์ บิ๊กซี ไลอ้อน สหพัฒน์ฯ และอื่น ๆ อีกมากมาย
            จึงทำให้มั่นใจได้ว่าสินค้าจาก IGNITE STORE มีคุณภาพ ถูกลิขสิทธิ์
            และคุ้มค่าคุ้มราคา"
          </p>
          <Spacer width="0" height="4rem" />
          <div className="col-12 aboutus-quotes-cont">
            We value honesty, integrity, and efficiency
          </div>
          <Spacer width="0" height="4rem" />
          <img className="center onepic" src={onePieceLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
