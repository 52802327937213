export const getBiddingCounterText = function (
  auctionstartdatetime,
  auctionclosedatetime
) {
  const now = new Date();
  const startDateTime = new Date(auctionstartdatetime);
  const endTime = new Date(auctionclosedatetime);
  if (now.getTime() <= startDateTime.getTime()) {
    return "จะเริ่มประมูลในอีก";
  } else if (now.getTime() <= endTime.getTime()) {
    return "จะปิดประมูลในอีก";
  } else {
    return "จะปิดประมูลในอีก";
  }
};
