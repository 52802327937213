import "../component.scss";
import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import { useNavigate } from "react-router-dom";
import shield from "../../assets/icon/cash.svg";
import verify from "../../assets/icon/payment_check.svg";

import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import ApiService from "../../config/api";
import { clearBiddingProductData, setBiddingPaymentStatus } from "../../store/bidding/action";
import { useState } from "react";

const PaymentCompleteModal = ({ isOpen, isClose, data }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPaymentCompleteModal, setPaymentCompleteModal] = useState(true);

  const biddingData = useSelector((state: any) => state.biddingProduct);
  var paymentData = {
    productId: biddingData?.productId,
    biddingAmount: biddingData?.biddingAmount,
    status: "",
    remarks: "-",
    referenceId: "",
    paymentType: "P",
    auctionId: biddingData?.auctionId,
    shippingId: data,
  };
  const closeModal = () => {
    isClose();
  };

  const handleRedirect = () => {
    dispatch(clearBiddingProductData());
    navigate("/MyCart");
  };

  const handleSuccess = async () => {
    paymentData.status = "PAID";
    paymentApiSend();
  };

  const handleFailure = async () => {
    paymentData.status = "FAILURE";
    paymentApiSend();
  };

  const paymentApiSend = async () => {
    dispatch(showLoader());
    const paymentInitiate = await ApiService.post(
      "/bidding/create/payment",
      paymentData
    );
    if (paymentInitiate.status === "SUCCESS") {
      dispatch(hideLoader());
      dispatch(setBiddingPaymentStatus(paymentData?.status));
      if (paymentData?.status === "PAID") {
        // setPaymentCompleteModal(true);
        navigate("/MyCart");
      } else {
        // navigate("/MyCart");
      }
    } else {
      dispatch(hideLoader());
    }
  };
  return (
    <Modal show={isOpen}>
      {/* <img src={close} alt="" onClick={closeModal} /> */}
      {!isPaymentCompleteModal ? (
        <div className="col-md-12 mt-4 payment-complete">
          <div className="text-center cnt-container">
            <div className="cnt mt-4">Payment Gateway Process....</div>
            <div className="text-center">
              <img src={shield} className="close-img" alt=""></img>
            </div>
            <div className="row">
              <div className="col-6">
                <button onClick={handleFailure}>Failure</button>
              </div>
              <div className="col-6">
                <button className="" onClick={handleSuccess}>
                  Success
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-12 mt-4 payment-complete">
          <div className="text-center cnt-container">
            <div className="text-center">
              <img src={verify} className="close-img" alt=""></img>
            </div>
            <div className="cnt mt-4">ชำระเงินเสร็จสมบูรณ์</div>
            <button className="mt-5" onClick={() => handleRedirect()}>
              เข้าร่วมการประมูล
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PaymentCompleteModal;
