// apiService.js
import axios from "axios";
import { useDispatch } from "react-redux";
import { logOut } from "../store/login/actions";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export const BASE_URL_IMG="https://igadminbeuat.cts-digital.com";
const BASE_URL = "https://igadminbeuat.cts-digital.com"; // Replace with your API base URL

// export const BASE_URL_IMG="http://localhost:8080";
//const BASE_URL = 'http://localhost:8080';

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

const apiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiService.interceptors.request.use(
  (config:any) => {
    const accessToken = getAccessToken(); // Retrieve your access token from wherever it's stored (e.g., localStorage)
    if (accessToken) {
      config.headers['x-auth-token'] = `${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const removeLocalItems = ()=>{
  localStorage.removeItem("user");
  localStorage.removeItem("access_token");
  window.location.replace('/');
 }


const ApiService = {
  // GET request
  get: async (url: any) => {
    try {
      const response = await apiService.get(url);
      return response.data;
    } catch (error: any) {
      console.error(error?.response);
      if(error?.response?.data?.statusCode===401){
        removeLocalItems();
      }
      throw new Error(error?.message);
    }
  },

  // POST request
  post: async (url: string, data: any) => {
    try {
      const response = await apiService.post(url, data);
      return response.data;
    } catch (error: any) {
      if(error?.response?.data?.statusCode===401){
        removeLocalItems();
      }
      throw new Error(error?.message);
    }
  },

  // PUT request
  put: async ({ url, data }: any) => {
    try {
      const response = await apiService.put(url, data);
      return response.data;
    } catch (error: any) {
      if(error?.response?.data?.statusCode===401){
        removeLocalItems();
      }
      throw new Error(error?.message);
    }
  },

  // DELETE request
  delete: async (url: any) => {
    try {
      const response = await apiService.delete(url);
      return response.data;
    } catch (error: any) {
      if(error?.response?.data?.statusCode===401){
        removeLocalItems();
      }
      throw new Error(error?.message);
    }
  }
}


export default ApiService;
