import { all, fork } from "redux-saga/effects";

import { watchFetchUserDetails } from "./auth/sagas";
import { watchFetchLoginDetails } from './login/sagas';

function* rootSaga() {
  yield all([
    fork(watchFetchUserDetails), 
    fork(watchFetchLoginDetails),
  ]);
}

export default rootSaga;
