import React from "react";
import Script from "react-load-script";
import "./checkout.scss";
import { useDispatch } from "react-redux";
import { showLoader } from "../../store/loader/loaderSlice";

const publicKey = "pkey_test_5y6j1irupynr28gdmsi";
let OmiseCard: any;

declare global {
  interface Window {
    OmiseCard: string;
  }
}

interface MyComponentProps {
  cart: any;
  createCreditCardCharge: any;
}

const CheckoutCreditCard: React.FC<MyComponentProps> = (props) => {

  const dispatch = useDispatch();

  const handleLoadScript = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey,
      currency: "thb",
      frameLabel: "Sabai Shop",
      submitLabel: "PAY NOW",
      buttonLabel: "Pay with Omise",
    });
  };

  const creditCardConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: [],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  const omiseCardHandler = () => {
    const { cart, createCreditCardCharge } = props;
    OmiseCard.open({
      frameDescription: "Invoice #3847",
      amount: cart.amount,
      onCreateTokenSuccess: (token: any) => {
        createCreditCardCharge(token);
      },
      onFormClosed: () => {},
    });
  };

  const handleClick = (e: any) => {
    dispatch(showLoader());
    e.preventDefault();
    creditCardConfigure();
    omiseCardHandler();
  };

  return (
    <div className="own-form">
      <Script url="https://cdn.omise.co/omise.js" onLoad={handleLoadScript} />
      <form>
        <button
          id="credit-card"
          className="btn"
          type="button"
          disabled={props.cart.amount === 0}
          onClick={handleClick}
        >
          Pay with Credit Card
        </button>
      </form>
    </div>
  );
};

export default CheckoutCreditCard;
