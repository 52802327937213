import "../component.scss";
import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import { useNavigate } from "react-router-dom";
import shield from "../../assets/icon/cash.svg";
import verify from "../../assets/icon/payment_check.svg";

import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import ApiService from "../../config/api";
import { clearBiddingProductData, setBiddingPaymentStatus } from "../../store/bidding/action";
import { useState } from "react";

const DepositPaymentCompleteModal = ({ isOpen, isClose, productId }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPaymentCompleteModal, setPaymentCompleteModal] = useState(true);

  const closeModal = () => {
    isClose();
  };

  const handleRedirect = () => {
    window.location.href = '/productdetails?productId='+productId;
  };

  return (
    <Modal show={isOpen}>      
        <div className="col-md-12 mt-4 payment-complete">
          <div className="text-center cnt-container">
            <div className="text-center">
              <img src={verify} className="close-img" alt=""></img>
            </div>
            <div className="cnt mt-4">ชำระเงินเสร็จสมบูรณ์</div>
            <button className="mt-5" onClick={() => handleRedirect()}>
              เข้าร่วมการประมูล
            </button>
          </div>
        </div>
   
    </Modal>
  );
};

export default DepositPaymentCompleteModal;
