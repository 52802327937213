import React, { useEffect, useState } from "react";
import "./page.scss";
import edit from "../assets/icon/edit.svg";
import trash from "../assets/icon/trash.svg";
import triangle from "../assets/icon/exclamation-triangle.svg";
import AddAddress from "../components/AddAddress";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";
import { deleteAddressApi } from "../controller/AddressFunction";
import { toast } from "react-toastify";
import Spacer from "../components/Spacer";
import PaymentCompletedModal from "../components/BiddingPaymentModal/PaymentCompleteModal";
import ApiService from "../config/api";
import { useLocation } from "react-router";
import BankDetailModal from "../components/BankDetailModal";
import RefundCompleteModal from "../components/BiddingPaymentModal/RefundCompleteModal";
import { setBiddingPaymentStatus } from "../store/bidding/action";

const BankDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentProcess = location.state;
  const [isAddressModal, setBankModal] = useState(false);
  const [isPaymentModal, setPaymentModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [error, setError] = useState(null);
  const [bankData, setBankData] = useState<any>([]);
  const [refundComplete, setRefundCompleteModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const biddingData = useSelector((state: any) => state.biddingProduct);

  const refundCompleteModalClose = () => {
    setRefundCompleteModal(false);
  };

  const refundCompleteModalShow = async () => {
    console.log("SELECTED",biddingData?.productId)
    if (selected === true) {
      if (biddingData?.productId) {
        dispatch(showLoader());
        let data = {
          productId: biddingData?.productId,
          biddingAmount: biddingData?.biddingAmount,
          status: "PAID",
          paymentType: "R",
          remarks: "",
          referenceId: "",
          actionId: biddingData?.auctionId,
        };
        const paymentInitiate = await ApiService.post(
          "/bidding/create/payment",
          data
        );
        if (paymentInitiate.status === "SUCCESS") {
          dispatch(setBiddingPaymentStatus(data?.status));
          dispatch(hideLoader());
          setRefundCompleteModal(true);
        } else {
          setError(paymentInitiate.response);
          dispatch(hideLoader());
        }
      }
    } else {
      toast.warning("Plese select the Bank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleShow = () => {
    setBankModal(true);
  };
  const addressModalClose = () => {
    setBankModal(false);
    setEditData([]);
    getBankDetails();
  };

  const paymentModalClose = () => {
    setPaymentModal(false);
  };

  const paymenthandleShow = () => {
    if (selected) {
      setPaymentModal(true);
    } else {
      toast.warning("Please select Bank Account!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setPaymentModal(true);
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    dispatch(showLoader());
    const getBankRes = await ApiService.get("/user/myBankDetails");
    if (getBankRes.status === "SUCCESS") {
      setBankData(getBankRes?.response?.bankDetails);
      dispatch(hideLoader());
      setSelected(false);
    } else {
      setError(getBankRes.response);
      dispatch(hideLoader());
    }
  };

  const editBank = (editItem: any) => {
    setBankModal(true);
    setEditData(editItem);
    setSelected(false);
  };

  const deleteBank = async (event: any, item: any, index: number) => {
    event.preventDefault();
    dispatch(showLoader());
    const getBankRes = await ApiService.post("/user/myBankDetails/delete", {
      id: parseInt(item),
    });
    if (getBankRes.status === "SUCCESS") {
      getBankDetails();
      setBankData(bankData);
      dispatch(hideLoader());
      setSelected(false);
    } else {
      setError(getBankRes.response);
      dispatch(hideLoader());
    }
  };
  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <div>
      <div className="address-page py-5 px-5">
        <div className="container">
          <div className="title text-center">บัญชีคืนเงิน</div>
          <Spacer height={"3rem"} width={0} />
          {bankData && (
            <>
              {bankData.map((item: any, key: number) => (
                <div key={key}>
                  <div className="address-container mt-3">
                    <div className="address-list">
                      <input
                        type="radio"
                        name="actions"
                        value={item.id}
                        className="mx-2"
                        onChange={() => setSelected(true)}
                      />
                      <div className="details p-3">
                        {item.accountnumber}
                        <br />
                        {item.accountname}
                        <br />
                        {item.bankname}
                      </div>
                    </div>
                    <div className="px-2">
                      <img
                        src={edit}
                        className="px-2"
                        alt=""
                        onClick={() => editBank(item)}
                      />
                      <img
                        src={trash}
                        className="px-2"
                        alt=""
                        onClick={(e) => deleteBank(e, item.id, key)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="text-center">
            <button className="add_address mt-5" onClick={handleShow}>
              + เพิ่มบัญชี
            </button>
          </div>
          <div className="text-center">
            <button
              onClick={
                paymentProcess === "refundFlow3"
                  ? refundCompleteModalShow
                  : paymenthandleShow
              }
              className="submit_btn my-5"
            >
              บันทึกเป็นบัญชีคืนเงิน
            </button>
            <div>
              <img
                src={triangle}
                className="mx-2"
                style={{ width: "16px", height: "16px" }}
                alt=""
              />
              <span className="warning_msg">
                หากยืนยันที่อยู่เสร็จแล้ว จะไม่สามารถเปลี่ยนได้ภายหลัง
              </span>
            </div>
          </div>
        </div>
      </div>
      <RefundCompleteModal
        isOpen={refundComplete}
        isClose={refundCompleteModalClose}
      />
      <BankDetailModal
        isOpen={isAddressModal}
        isClose={addressModalClose}
        data={editData}
      />
    </div>
  );
};

export default BankDetails;
