import "./component.scss";

interface GalleryItem {
  id: number;
  filecat: string;
  filename: string;
  filepath: string;
  filesize: string;
  filetype: string;
  containerid: number;
  contenttype: string;
}

interface Props {
  galleryList: GalleryItem[];
}

const ProductCardThree = ({ galleryList }: Props) => {
  console.log(galleryList);

  return (
   <div className="max-width" style={{backgroundColor:'#000'}}>
    {galleryList.map((item,key) => (
      <span key={key} className='bg_img px-0'>
        <img
          key={item.id}
          src={item.filepath}
          alt={item.filename}
          className="w-100 h-100"
        />
      </span>
    ))}
  </div>
);

};

export default ProductCardThree;