import React from "react";

type props = {
  height: any;
  width: any;
};
const Spacer = (prop: props) => {
  const { height, width } = prop;
  return (
    <>
      <div style={{ height: height, width: width }}></div>
    </>
  );
};

export default Spacer;
