import React from "react";

import first from "../../assets/icon/award1.svg";
import second from "../../assets/icon/award 2nd.svg";
import award_icon from "../../assets/icon/verify.svg";

interface ChildProps {
  productsData: any;
  flagTitle: boolean;
  userId:any
}

const BiddingOrderTxt: React.FC<ChildProps> = ({
  productsData,
  flagTitle,
  userId

}) => {
  return (
    <>
      {productsData?.bid_order === 1 && productsData?.product_purchased_by == null && (
        <div>
          {flagTitle ? (
            <span className="text-yellow my-4">คุณชนะการประมูล</span>
          ) : (
            <></>
          )}
        </div>
      )}

    {productsData?.product_purchased_by != null  && productsData?.product_purchased_by == userId && (
        <div>
          {flagTitle ? (
            <span className="text-yellow my-4">คุณชนะการประมูล</span>
          ) : (
            <></>
          )}
        </div>
      )}

    {productsData?.product_purchased_by != null  && productsData?.product_purchased_by != userId && (
        <div>
          {flagTitle ? (
            <span className="text-yellow my-4">สินค้านี้ผู้ซื้อไปแล้ว</span>
          ) : (
            <></>
          )}
        </div>
      )}

      {productsData?.bid_order === 2 && productsData?.is_winner ===0 && productsData?.product_purchased_by == null && (
        <div>
          {flagTitle ? (
            <span className="text-yellow my-4">คุณชนะลำดับที่ 2</span>
          ) : (
            <></>
          )}
        </div>
      )}

      {productsData?.bid_order === 2 && productsData?.is_winner ===1 && productsData?.refund_id == "0" && productsData?.product_purchased_by == null && (
        <div>
          {flagTitle ? (
            <span className="text-yellow my-4">คุณเป็นผู้ชนะการประมูลเนื่องจากผู้ชนะลำดับที่ 1 สละสิทธิ์</span>
          ) : (
            <></>
          )}
        </div>
      )}

      {productsData?.bid_order === 3 && productsData?.product_purchased_by == null && (
        <div>
          {flagTitle ? (
            <span className="text-yellow my-4">สินค้านี้มีผู้ชนะประมูลไปแล้ว</span>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default BiddingOrderTxt;
