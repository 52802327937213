import { useState, useEffect } from "react";
import "../page.scss";
import heartFull from "../../assets/icon/heart-full-red.svg";
import CardSlider from "../../components/CardSlider";
import cart_icon from "../../assets/icon/cart_white.svg";
import cancel from "../../assets/icon/close-circle.svg";
import shield from "../../assets/icon/shield-check.svg";
import backAffow from "../../assets/icon/chevron-left.svg";

import heart from "../../assets/icon/heart.svg";
import PaymentModal from "../../components/PaymentModal";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFavourite } from "../../controller/FavouriteFunction";
import { showLoader, hideLoader } from "../../store/loader/loaderSlice";
import ModalComponent from "../../components/Modal";
import BankDetailModal from "../../components/BankDetailModal";
import { toast } from "react-toastify";
import { openLoginModal } from "../../store/login/actions";
import ApiService from "../../config/api";
import { socket } from "../../socket";
import BiddingCounter from "../../components/biddingCounter";
import BiddingStar from "./BiddingStar";
import BiddingConfirmModal from "../../components/BiddingPaymentModal/BiddingConfirmModal";
import RefundCompleteModal from "../../components/BiddingPaymentModal/RefundCompleteModal";
import BiddingAmountRefundModal from "../../components/BiddingPaymentModal/BiddingAmountRefundModal";
import BuyOutPaymentModal from "../../components/BuyOutPaymentModal";
import BiddingOrderIcon from "../../components/BiddingOrderIcon";
import { hideHeaderFooter, showHeaderFooter } from "../../store/common/HeaderFooterSlice";
import BiddingOrderTxt from "./BiddingOrderTxt";
import DepositPaymentCompleteModal from "../../components/BiddingPaymentModal/DepositPaymentCompleteModal";
import { EditorState,ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DOMPurify from 'dompurify';

const ProductDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const sessionData = useSelector((state: any) => state.login);
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");

  const [contentBlock, setContentBlock] = useState<any>([]);
  const [productsData, setProductsData] = useState<any>([]);
  const [BuyOutproductsData, setBuyOutProductsData] = useState<any>([]);

  const [userId, setUserId] = useState(0);
  const [isBiddingConfirm, setBiddingConfirmModal] = useState(false);
  const [ispaymentModal, setPaymentModal] = useState(false);
  const [isBuyOutpaymentModal, setBuyOutPaymentModal] = useState(false);

  const [error, setError] = useState(null);
  const [showConfitmBtn, setShowConfitmBtn] = useState(false);
  const [isDepositAmountPaid, setIsDepositAmountPaid] = useState(false);
  const [activeAmountButton, setactiveAmountButton] = useState(null);
  const [latestAuctionAmt, setLatestAuctionAmt] = useState(0);
  const [isAuctionClosed, setAuctionClosedStatus] = useState(false);
  const [isAuctionStarted, setAuctionStartedStatus] = useState(false);
  const [isAuctionCreated, setAuctionCreatedStatus] = useState(false);
  const [isBiddingAmountRefund, setBiddingAmountRefund] = useState(false);
  const [isBuyOutStart, setBuyOutStart] = useState(false);
  const [isBiddingPaymentCompleted, setBiddingPaymentCompleted] =
    useState(false);
  const [isValidatedby, setIsValidatedby] = useState(false);

  const paymentModalClose = () => setPaymentModal(false);
  const paymentBuyOutModalClose = () => setBuyOutPaymentModal(false);

  const paymentModalShow = () => setPaymentModal(true);
  const biddingCondfirmModalClose = () => setBiddingConfirmModal(false);
  const biddingCondfirmModalShow = () => setBiddingConfirmModal(true);

  const [showMobileViewSecondPage, setShowMobileViewSecondPage] = useState(false);
  const [showPaymentSuccess, SetShowPaymentSuccess] = useState(false);
  const [isProductPurchased, SetIsProductPurchased] = useState(false);

  const biddingData = useSelector((state: any) => state.biddingProduct);

  useEffect(() => {
    dispatch(showLoader());
    getProductsDatApi();
    if(searchParams.get('paymentSuccess')){
      SetShowPaymentSuccess(true);
    }
  }, []);

  const getAllAuctionByAuctionId = async() => {
    const responseData = await ApiService.get(
      `/bidding/getAllAuctionByAuctionId/${productsData.auction_id}`
    );
    if (responseData.status === "SUCCESS") {
      console.log(responseData);
      productsData.latestAuctionList = {...responseData.response};
      console.log(productsData.latestAuctionList);
      setProductsData({
        ...productsData,
        latestAuctionList: responseData.response.map((item:any) => ({ ...item }))
      });
    }
  }

  const getProductsDatApi = async () => {
    const responseData = await ApiService.get(
      `/bidding/product/detail/${productId}`
    );
    if (responseData.status === "SUCCESS") {
      setProductsData(responseData?.response);
      setContentBlock(responseData?.response.detail)
      setUserId(responseData?.userId);
      setIsValidatedby(responseData?.response?.validatedby);
      if (responseData?.response?.deposit_id != "0") {
        setIsDepositAmountPaid(true);
      }
      if(responseData?.response?.product_purchased_by !=null){
        SetIsProductPurchased(true);
      }
      

      // const endDate = new Date(responseData?.response?.auctionclosedatetime);
      // if (
      //   new Date().getTime() >= endDate.getTime() &&
      //   responseData?.response?.auctionstatus !== "CLOSED"
      // ) {
      //   setAuctionClosedStatus(true);
      // }

      if (responseData?.response?.auctionstatus === "CLOSED") {
        setAuctionClosedStatus(true);
      }

      if (responseData?.response?.auctionstatus === "STARTED") {
        setAuctionStartedStatus(true);
      }
      if (responseData?.response?.auctionstatus === "CREATED") {
        setAuctionCreatedStatus(true);
        setAuctionStartedStatus(true);
      }

      if (responseData?.response?.winnerid) {
        setBiddingPaymentCompleted(true);
      }

      dispatch(hideLoader());
      if (responseData?.response?.latestAuctionList?.length) {
        setLatestAuctionAmt(
          responseData?.response?.latestAuctionList?.[0]?.bidding_amount
        );
      } else {
        setLatestAuctionAmt(parseInt(responseData?.response?.openingprice));
      }
    } else {
      setError(responseData.response);
      dispatch(hideLoader());
    }
  };

  const showSecondMobileSecondPage = () =>{
    dispatch(hideHeaderFooter());
    setShowMobileViewSecondPage(true);
  }

  const hideSecondMobileSecondPage = () =>{
    dispatch(showHeaderFooter());
    setShowMobileViewSecondPage(false);
  }

  const handleFavourite = async () => {
    if (sessionData.isAuthenticated) {
      dispatch(showLoader());
      const response = await addFavourite(productId);
      if (response.status === true) {
        productsData.is_favourite === 1
          ? (productsData.is_favourite = 0)
          : (productsData.is_favourite = 1);
        productsData.like_count = response.response.like_count;
      } else {
        productsData.is_favourite = 0;
      }
      dispatch(hideLoader());
    } else {
      dispatch(openLoginModal(true));
    }
  };

  //Socket
  const [fooEvents, setFooEvents] = useState<any>([]);
  const [value, setValue] = useState<any>("");
  const [isValueColor, setValueColor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [biddingValue, setBiddingValue] = useState<any>([]);

  useEffect(() => {
    const onFooEvent = (value: any) => {
      console.log(value);
      getProductsDatApi();
      handleBiddingAmoutClearBtn();
      // setLatestAuctionAmt(parseInt(value));
      // setShowConfitmBtn(false);
      setactiveAmountButton(null);
      setIsLoading(false);
      return;
      const data = value?.biddingAmount;
      setBiddingValue(data);
      setFooEvents((previous: any) => [...previous, value.greeting]);
      // const res = ApiService.post("/bidding/create/bidding");
    };

    socket.on("greeting-from-server", onFooEvent);

    return () => {
      socket.off("greeting-from-server", onFooEvent);
    };
  }, [fooEvents]);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    const req = {
      productId: productId,
      biddingAmount: value,
    };
    // socket.timeout(5000).emit("greeting-from-server", req, () => {
    //   setIsLoading(false);
    // });
    const res = await ApiService.post("/bidding/create/bidding", req);
    console.log(res);
    setIsLoading(false);
    if (res.status === "SUCCESS") {
      setShowConfitmBtn(false);
    } else {
      toast.warning(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowConfitmBtn(false);
      setactiveAmountButton(null);
    }
  };

  const onClickBuyOut = async () => {
    if (!isDepositAmountPaid) {
      alert("Pleaes pay deposit amount");
      return;
    }
    setValue(productsData?.purchaseprice);
    setAuctionStartedStatus(false);
    setAuctionClosedStatus(false);
    setBuyOutStart(true);
    let buyOutProductData = {
      purchaseAmount: productsData?.purchaseprice,
      depositAmount: productsData?.productdepositamount,
    };
    setBuyOutProductsData(buyOutProductData);
  };

  const canceClickBuyOutModal = async (e: any) => {
    window.location.reload();
  };



  const onSubmitBiddingConfirm = async (e: any) => {

    e.preventDefault();
    console.log(isBiddingPaymentCompleted);
    if ((productsData?.bid_order === null && productsData?.deposit_id === "0")  || (productsData?.bid_order === 1 && (productsData?.is_winner === 0 && productsData?.product_purchased_by == null))) {
      return;
    }
    if (
      (isAuctionClosed &&
        productsData?.is_winner === 1 &&
        !isBiddingPaymentCompleted && !isProductPurchased) ||
      (isAuctionClosed &&
        productsData?.is_winner === 0 &&
        !isBiddingPaymentCompleted &&
        productsData?.bid_order < 3 && !isProductPurchased)
    ) {
      setBiddingConfirmModal(true);
    } else if (
      (isAuctionClosed &&
      sessionData?.currentUser?.response?.userId !== productsData?.winnerid) ||
      (isProductPurchased && productsData.product_purchased_by == userId)
    ) {
      setBiddingAmountRefund(true);
    } else {
      return;
    }
  };

  const onSubmitBuyOutConfirm = async (e: any) => {
    e.preventDefault();
    if (sessionData.isAuthenticated) {
      setBuyOutPaymentModal(true);
    } else {
      dispatch(openLoginModal(true));
    }
  };

  const openDepositPaymentModel = () => {
    if (sessionData.isAuthenticated) {
      setPaymentModal(true);
    } else {
      dispatch(openLoginModal(true));
    }
  };
  const handleBiddingButton = (val: any, buttonNumber: any) => {
    setValue(val);
    setValueColor(!isValueColor);
    setactiveAmountButton(buttonNumber);
  };

  const handleBiddingAmoutClearBtn = () => {
    setValue("");
  };

  const handleBiddingBtn = () => {
    let leastbidAmount =
      Math.round(latestAuctionAmt) + parseFloat(productsData.minbidamount);
    console.log(leastbidAmount);
    if (value < leastbidAmount) {
      toast.warning("฿ " + leastbidAmount + " is minimum bit amount", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValue("");
    } else {
      setShowConfitmBtn(true);
    }
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const currencyFormat = (num: any) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const auctionButtonName = () => {
    // if (isBiddingPaymentCompleted) {
    //   return "";
    // } else 
    if ((productsData?.bid_order >= 3 && isAuctionClosed) || (productsData?.product_purchased_by !==null && productsData?.product_purchased_by != userId) || (productsData?.bid_order === null && productsData?.deposit_id !== "0")) { 
      return "รับเงินคืน";
    } else if (isAuctionClosed && productsData?.is_winner === 1) {
      return "ชำระค่าสินค้า";
    }
    else if (isAuctionClosed && productsData?.bid_order === 1 && productsData?.is_winner === 0) {
      return "สินค้านี้มีผู้ชนะประมูลไปแล้ว";
    }
     else if (
      isAuctionClosed &&
      productsData?.is_winner === 0 &&
      productsData?.refund_id == "0" &&
      productsData?.payment_id == "0" &&
      productsData?.bid_order === 2
    ) {
      return "ยืนยันรับสิทธิ์";
    } else {
      return "สินค้านี้มีผู้ชนะประมูลไปแล้ว";
    }

    // if (userId === productsData?.latestAuctionList?.[0]?.user_id) {
    //   return "ชำระค่าสินค้า";
    // } else if (
    //   userId !== productsData?.latestAuctionList?.[0]?.user_id &&
    //   userId == productsData?.latestAuctionList?.[1]?.user_id
    // ) {
    //   return "ยืนยันรับสิทธิ์";
    // } else if (
    //   userId !== productsData?.latestAuctionList?.[0]?.user_id &&
    //   userId !== productsData?.latestAuctionList?.[1]?.user_id &&
    //   productsData?.paymentid
    // ) {
    //   return "รับเงินคืน";
    // } else {
    //   return "สินค้านี้มีผู้ชนะประมูลไปแล้ว";
    // }
  };
  const safeHTML = DOMPurify.sanitize(contentBlock);
  return (
    <>
    <DepositPaymentCompleteModal isOpen={showPaymentSuccess} productId={productId}></DepositPaymentCompleteModal>
    <div className="bidding-page pt-5 product-disktop-view">
      {productsData && (
        <>
          <div className="max-width px-lg-3 px-xl-0">
            <div className="row">
              <div className="col">
                <div className="d-flex flex-row justify-content-center align-items-center mb-4">

                  {(productsData?.product_purchased_by == null || productsData?.product_purchased_by == userId) &&  (
                             <BiddingStar
                             productsData={productsData}
                             userId={userId}
                             flagTitle={false}
                           />
                          )} 

                  <div className="px-3">
                    <div className="number-txt">
                      No. {productsData.runningno}
                    </div>
                    <div className="product_name">{productsData.name}</div>
                  </div>
                  <div onClick={() => handleFavourite()} className="like_cnt">
                    <img
                      className="like_img"
                      src={productsData.is_favourite === 1 ? heartFull : heart}
                      alt=""
                    />
                    <span className="like_count">
                      {productsData.like_count}
                    </span>
                  </div>
                </div>
                {productsData.fileList && (
                  <CardSlider data={productsData}></CardSlider>
                )}
                <div className="mt-4">
                  <div className="ignite_txt">IGNITE</div>
                  <div>
                    <span className="amount_txt">ราคาเปิดประมูล :</span>{" "}
                    <span className="number-txt px-4">
                      {parseInt(productsData?.openingprice)} ฿
                    </span>
                  </div>
                </div>

                <div className="product-detail my-4">
                <div dangerouslySetInnerHTML={{ __html: safeHTML }} /> 
                </div>

                <table className="product-table">
                  <tr>
                    <td className="title">ขนาดสินค้า</td>
                    <td>{productsData?.productsize}</td>
                  </tr>
                  <tr>
                    <td className="title">น้ำหนัก</td>
                    <td>{parseInt(productsData?.productweight)}</td>
                  </tr>
                  <tr>
                    <td className="title">วัสดุ</td>
                    <td>{productsData?.materialdetail}</td>
                  </tr>
                </table>

                {!isDepositAmountPaid ? (
                  <div className="condition-section my-5 pb-2">
                    <div className="title py-4 text-center">
                      เงื่อนไข เข้าร่วมการประมูล
                    </div>
                    <div className="cnt pt-1 text-center px-5">
                      ออยล์พลานุภาพคำตอบช็อต กิฟท์มาร์เก็ตวิกกรอบรูปแจม
                      เวสต์เอนทรานซ์ตรวจทานแบล็ค รองรับลาตินแลนด์รามาธิบดี{" "}
                    </div>

                    <table className="mx-auto my-2">
                      <tr>
                        <td className="title"> ประกันมัดจำสินค้า</td>
                        <td className="title">ค่าบริการ</td>
                        <td className="title">ราคาซื้อขาด</td>
                      </tr>
                      <tr>
                        <td className="amount">
                          {parseInt(productsData?.productdepositamount)} ฿
                        </td>
                        <td className="amount">
                          {parseInt(productsData?.servicecharge)} ฿
                        </td>
                        <td className="amount">
                          {parseInt(productsData?.purchaseprice)} ฿
                        </td>
                      </tr>
                      <tr></tr>
                    </table>
                  </div>
                ) : (
                  <p></p>
                )}
              </div>

              <div className="col px-2" style={{ maxWidth:'335px',minWidth:'335px' }}>
                <div className="action-container py-3 px-3">
                  <div className="text-center timer_txt">จะปิดประมูลอีก</div>
                  <div className="text-center timer">
                    <BiddingCounter data={productsData} />
                    {/* 
                    {timeRemaining.hours} H : {timeRemaining.minutes} M :{" "}
                    {timeRemaining.seconds} S */}
                  </div>

                  <hr />
                  <div className="text-center amount_txt">ราคาประมูลล่าสุด</div>
                  <div className="text-center amount">
                    {currencyFormat(latestAuctionAmt)} ฿
                  </div>

                  <hr />
                  {isAuctionStarted && (
                    <div>
                      {!isDepositAmountPaid && !showConfitmBtn && (
                        <button
                          className="deposit_pay_btn mt-5"
                          onClick={openDepositPaymentModel}
                        >
                          จองสิทธิ์ประมูล
                        </button>
                      )}
            
                      {isDepositAmountPaid && !showConfitmBtn && (
                        <div>
                          <div className="d-flex flex-row justify-content-around mt-4">
                            <button
                              onClick={() =>
                                handleBiddingButton(
                                  Math.round(latestAuctionAmt) +
                                    parseFloat(productsData.minbidamount),
                                  1
                                )
                              }
                              className={
                                activeAmountButton === 1
                                  ? "active_amt_btn"
                                  : "amt_btn"
                              }
                            >
                              {currencyFormat(
                                Math.round(latestAuctionAmt) +
                                  parseFloat(productsData.minbidamount)
                              )}{" "}
                              ฿
                            </button>
                            <button
                              onClick={() =>
                                handleBiddingButton(
                                  Math.round(latestAuctionAmt) +
                                    parseFloat(productsData.minbidamount) * 2,
                                  2
                                )
                              }
                              className={
                                activeAmountButton === 2
                                  ? "active_amt_btn"
                                  : "amt_btn"
                              }
                            >
                              {currencyFormat(
                                Math.round(latestAuctionAmt) +
                                  parseFloat(productsData.minbidamount) * 2
                              )}{" "}
                              ฿
                            </button>
                            <button
                              onClick={() =>
                                handleBiddingButton(
                                  Math.round(latestAuctionAmt) +
                                    parseFloat(productsData.minbidamount) * 3,
                                  3
                                )
                              }
                              className={
                                activeAmountButton === 3
                                  ? "active_amt_btn"
                                  : "amt_btn"
                              }
                            >
                              {currencyFormat(
                                Math.round(latestAuctionAmt) +
                                  parseFloat(productsData.minbidamount) * 3
                              )}{" "}
                              ฿
                            </button>
                          </div>

                          <div className="action_price_txt text-center mt-4">
                            ราคาลงประมูล
                          </div>
                          {/* <form className="position-relative"> */}
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="ระบุราคาลงประมูล"
                              className="action_price_btn mt-4 text-center"
                              name="ระบุราคาลงประมูล"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                            />

                            {value && (
                              <img
                                src={cancel}
                                alt=""
                                className="bidding_amount_cancel_btn"
                                onClick={handleBiddingAmoutClearBtn}
                              />
                            )}
                          </div>
                          <button
                            disabled={isLoading || value === ""}
                            className="bit_btn mt-4"
                            onClick={handleBiddingBtn}
                          >
                            เสนอราคา
                          </button>
                          {/* </form> */}
                        </div>
                      )}
                      {isDepositAmountPaid && showConfitmBtn && (
                        <div className="row conformation-container mx-0">
                          <div className="col-12 mt-1 mb-3">
                            <div className="auction_txt">ราคาลงประมูล</div>
                            <div className="auction_amt">
                              {currencyFormat(Math.round(value))}{" "}
                            </div>
                          </div>
                          <div className="col-6 px-2 ">
                            <button
                              className="cancel_btn"
                              onClick={() => setShowConfitmBtn(false)}
                            >
                              ยกเลิก
                            </button>
                          </div>
                          <div className="col-6 px-2">
                            <button
                              className="col-6 bid_btn"
                              onClick={onSubmit}
                            >
                              เสนอราคา
                            </button>
                          </div>
                        </div>
                      )}
                      <div
                        className="text-center cursor-pointer my-5"
                        onClick={onClickBuyOut}
                      >
                        <span className="mx-2">
                          <img src={cart_icon} alt="" />
                        </span>

                        <span className="buy_cnt">
                          ซื้อเลยเพียง&nbsp;
                          <b>
                            {currencyFormat(
                              Math.round(productsData?.purchaseprice)
                            )}
                          </b>
                          &nbsp;บาท
                        </span>
                      </div>
                    </div>
                  )}
  
                  {isAuctionClosed && (
                    <div className="conformation-container mb-3">
                      <div className="col-12 mt-1 mb-3 text-center">

                      {productsData?.product_purchased_by == null && (
                             <BiddingStar
                             productsData={productsData}
                             userId={userId}
                             flagTitle={true}
                           />
                          )}

                      {(productsData?.product_purchased_by != null || productsData?.product_purchased_by == userId) &&   (
                           <BiddingOrderTxt productsData={productsData} flagTitle={true} userId={userId} ></BiddingOrderTxt>
                          )}

                      

                        
                      </div>

                      <div className="text-center">
                        {productsData?.bid_order === 1 &&
                          productsData?.is_winner === 0 && productsData?.product_purchased_by == null &&  (
                            <>
                              <h3 className="text-white">
                                Oops... Your payment time is over!
                              </h3>
                            </>
                          )}

                    {/* {productsData?.product_purchased_by != null  && productsData?.product_purchased_by != userId &&  (
                            <>
                              <h3 className="text-white my-4">
                              สินค้านี้ผู้ซื้อไปแล้ว
                              </h3>
                            </>
                          )} */}

                        {productsData?.refund_id != "0" ? (
                          <h3 className="text-white">Refund Initiated!</h3>
                        ) : (
                          <>
                            {isBiddingPaymentCompleted ? (
                              <>
                                {sessionData?.currentUser?.response?.userId ===
                                productsData?.winnerid ? (
                                  <h3 className="text-white">
                                    Order Placed Successfully!
                                  </h3>
                                ) : (
                                  <>
                                    <button
                                      className="full-bid-btn"
                                      onClick={onSubmitBiddingConfirm}
                                    >
                                      {auctionButtonName()}
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {productsData?.bid_order === 1 &&
                                productsData?.is_winner === 0 ? (
                                  <></>
                                ) : (
                                  <button
                                    className="full-bid-btn"
                                    onClick={onSubmitBiddingConfirm}
                                  >
                                    {auctionButtonName()}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {isBuyOutStart && (
                    <div>
                      <div className="row conformation-container mx-0">
                        <div className="col-12 mt-1 mb-3">
                          <div className="buyout_text text-left">
                            ราคาซื้อขาด
                          </div>
                          <div className="auction_amt">
                            {currencyFormat(Math.round(value))} ฿
                          </div>
                        </div>
                        <div className="col-6 ">
                          <button
                            className="cancel_btn"
                            onClick={canceClickBuyOutModal}
                          >
                            ยกเลิก
                          </button>
                        </div>
                        <div className="col-6 ">
                          <button
                            className="col-6 bid_btn"
                            onClick={onSubmitBuyOutConfirm}
                          >
                            สินค้า
                          </button>
                        </div>
                      </div>
                      <br />
                    </div>
                  )}

                  <table className="other_bidding_list_tbl">
                    <tr>
                      <td className="left_head">ร่วมผู้ประมูลล่าสุด</td>
                      <td className="right_head cursor-pointer" ><span onClick={() => getAllAuctionByAuctionId()}>แสดงทั้งหมด</span></td>
                    </tr>
                    {productsData?.latestAuctionList &&
                      productsData?.latestAuctionList.map(
                        (val: any, key: number) => (
                          <tr key={key}>
                            <td>
                              <div className="main_content">{val.username}</div>
                              <div className="sub_content">
                                Bid {val.time_ago}
                              </div>
                            </td>
                            <td className="amount">{val?.bidding_amount} ฿</td>
                          </tr>
                        )
                      )}
                  </table>
                </div>
                {isValidatedby == true &&  (
                  <button className="bit_with_confidence_btn my-3 d-flex align-items-center px-3 text-left ">
                    <img src={shield} alt="" />
                    <span className="px-3">
                      ประมูลด้วยความมั่นใจ ว่าได้ของแท้ 100% และการรับประกันสินค้าจากเรา
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {ispaymentModal && (
        <PaymentModal
          isOpen={ispaymentModal}
          productData={productsData}
          isClose={paymentModalClose}
        />
      )}
      {isBiddingConfirm && (
        <BiddingConfirmModal
          isOpen={isBiddingConfirm}
          isClose={biddingCondfirmModalClose}
          productData={productsData}
        />
      )}
      {biddingData?.paymentStatus === "REFUND_INITIATE" && (
        <RefundCompleteModal isOpen={true} />
      )}

      {biddingData?.paymentMode === "Internet Banking" && (
        <BankDetailModal productData={productsData} isOpen={biddingCondfirmModalClose} />
      )}

      {isBiddingAmountRefund && (
        <BiddingAmountRefundModal
          isOpen={isBiddingAmountRefund}
          isClose={() => setBiddingAmountRefund(false)}
          productData={productsData}
        />
      )}
      {isBuyOutpaymentModal && (
        <BuyOutPaymentModal
          isOpen={isBuyOutpaymentModal}
          productData={productsData}
          isClose={paymentBuyOutModalClose}
        />
      )}
    </div>
    <div className="product-mobile-view bidding-page">
 {/* First page start */}
      {!showMobileViewSecondPage && 

<div className="mobile-view-page1">
<div className="position-relative mobile-prod-img">
{(productsData?.product_purchased_by == null || productsData?.product_purchased_by == userId) &&  (
  <BiddingOrderIcon bid_order={productsData?.bid_order} productsData={productsData} userId={userId} ></BiddingOrderIcon>
)}
  

  {productsData.fileList && (
    <CardSlider data={productsData}></CardSlider>
  )}
</div>

<div className="px-3 pb-1">
  <div className="name-fav-icon d-flex align-items-center justify-content-between">
    <div className="number-container">
      <div className="number-txt">
        No. {productsData.runningno}
      </div>
    </div>
    <div>
    <div onClick={() => handleFavourite()} className="like_cnt">
      <img
        className="like_img"
        src={productsData.is_favourite === 1 ? heartFull : heart}
        alt=""
      />
      <span className="like_count">
        {productsData.like_count}
      </span>
    </div>
    </div>
    
  </div>

  <div className="product_name">{productsData.name}</div>

  <div className="mt-4">
    <div className="ignite_txt">IGNITE</div>
    <div>
      <span className="amount_txt">ราคาเปิดประมูล :</span>{" "}
      <span className="opening-bid-number-txt px-4">
        {parseInt(productsData?.openingprice)} ฿
      </span>
    </div>
  </div>

  {isValidatedby == true && (
    <button className="bit_with_confidence_btn my-3 d-flex align-items-center px-3 text-left">
      <img src={shield} alt="" />
      <span className="px-3">
        ประมูลด้วยความมั่นใจ ว่าได้ของแท้
        100%และการรับประกันสินค้าจากเรา
      </span>
    </button>
  )}

  <div className="product-detail my-4">
    <div dangerouslySetInnerHTML={{ __html: safeHTML }} /> 
  </div>

  <table className="product-table">
    <tr>
      <td className="title">ขนาดสินค้า</td>
      <td className="text-right">{productsData?.productsize}</td>
    </tr>
    <tr>
      <td className="title">น้ำหนัก</td>
      <td className="text-right">{parseInt(productsData?.productweight)}</td>
    </tr>
    <tr>
      <td className="title">วัสดุ</td>
      <td className="text-right">{productsData?.materialdetail}</td>
    </tr>
  </table>

  {!isDepositAmountPaid && 
    <div className="condition-section my-5 pb-2">
      <div className="title py-4 text-center">
        เงื่อนไข เข้าร่วมการประมูล
      </div>
      <div className="cnt pt-1 text-center px-2">
        ออยล์พลานุภาพคำตอบช็อต กิฟท์มาร์เก็ตวิกกรอบรูปแจม
        เวสต์เอนทรานซ์ตรวจทานแบล็ค รองรับลาตินแลนด์รามาธิบดี{" "}
      </div>

      <table className="mx-auto my-2">
        <tr>
          <td className="title"> ประกันมัดจำสินค้า</td>
          <td className="title">ค่าบริการ</td>
          <td className="title">ราคาซื้อขาด</td>
        </tr>
        <tr>
          <td className="amount">
            {parseInt(productsData?.productdepositamount)} ฿
          </td>
          <td className="amount">
            {parseInt(productsData?.servicecharge)} ฿
          </td>
          <td className="amount">
            {parseInt(productsData?.purchaseprice)} ฿
          </td>
        </tr>
        <tr></tr>
      </table>
    </div>
}

</div>
  <div className="product-footer d-flex align-items-center px-3">
    {productsData?.bid_order && (productsData?.product_purchased_by == null || productsData?.product_purchased_by == userId) && 
    
    <div className="d-flex justify-content-start align-items-center" style={{width:'50px',height:'50px'}}>
        <BiddingOrderIcon bid_order={productsData?.bid_order} productsData={productsData} userId={userId} ></BiddingOrderIcon>
    </div>
    }
    
    <div>
    <div className="text-left">
               <div className="amount_txt">ราคาประมูลล่าสุด</div>
               <div className="amount mt-1">
                 {currencyFormat(latestAuctionAmt)} ฿
               </div>
               <div className="mt-1">
               {!isAuctionClosed &&
               <BiddingCounter showCounter={true} data={productsData} />
               }
               {isAuctionClosed &&
               <BiddingOrderTxt productsData={productsData} flagTitle={true} userId={userId} ></BiddingOrderTxt>
               }
               </div>
               
             </div>
    </div>
    <div>

    {sessionData?.currentUser?.response?.userId === productsData?.winnerid && isBiddingPaymentCompleted && 
     <h6 className="text-right">Order Placed Successfully!</h6>
    }

    {productsData?.refund_id != "0" &&
          <h6 className="text-white text-right">Refund Initiated!</h6>
    }


    {isAuctionStarted && !isDepositAmountPaid  && 
               <button
                 className="participate-in-bid-btn"
                 onClick={openDepositPaymentModel}
               >
                 จองสิทธิ์ประมูล
               </button>
             }

{isAuctionClosed && isDepositAmountPaid && productsData.payment_id === '0'  && productsData.refund_id === '0' &&

<>
<button
  className="bid-close-btn"
  onClick={onSubmitBiddingConfirm}
>
  {auctionButtonName()}
</button>
</>}

{!isAuctionClosed && isDepositAmountPaid &&

<>
                               
<button
  className="participate-in-bid-btn"
  onClick={showSecondMobileSecondPage}
>
เสนอราคา
</button>

</>}

{isAuctionClosed && !isDepositAmountPaid &&

<>
                               
<button
  className="participate-in-bid-btn"
>
ถูกประมูลออกไป
</button>

</>}
    </div>
  </div>
</div>

      }

       {/* second page start */}

{showMobileViewSecondPage && 
 <div className="mobile-view-page2 action-container">
 <div className="back_button_cont_bg position-relative py-2">
   <div className="text-center timer_txt">จะปิดประมูลอีก</div>
   <div className="text-center timer mt-2">
     
     <BiddingCounter data={productsData} />
     {/* 
     {timeRemaining.hours} H : {timeRemaining.minutes} M :{" "}
     {timeRemaining.seconds} S */}
   </div>
   <img className="back_arrow_img" onClick={hideSecondMobileSecondPage} src={backAffow} />
 </div>

 <div className="px-3 pb-5">
 {/* <div className="text-center amount_txt">ราคาประมูลล่าสุด</div>
         <div className="text-center amount">
           {currencyFormat(latestAuctionAmt)} ฿
         </div> */}

         <div className="img-and-price-cont mt-3">
           <div className="d-flex" style={{width:'100%'}}>
           <div>

              <img className="prod-img" src={productsData?.fileList?.[0]?.filepath}></img>
    
           </div>
           <div style={{width:'100%'}} className="d-flex flex-column justify-content-between ps-3">
             <div>
             <div className="number-txt">No. {productsData.runningno}</div>
             <div className="product-name mt-2">{productsData.name}</div>
             </div>
             
             <div className="d-flex justify-content-between mb-2">
               <div className="text-center amount_txt">ราคาประมูลล่าสุด</div>
               <div className="text-center amount">
               {productsData?.bid_order && 
                          <span>
                          <BiddingOrderIcon bid_order={productsData?.bid_order} productsData={productsData} userId={userId} ></BiddingOrderIcon>
                          </span>
                          }
               <span> {currencyFormat(latestAuctionAmt)} ฿ </span>
               </div>
             </div>
           </div>
           </div>
           
         </div>

   
         {isAuctionStarted && (
           <div>
             {!isDepositAmountPaid && !showConfitmBtn && (
               <button
                 className="deposit_pay_btn mt-5"
                 onClick={openDepositPaymentModel}
               >
                 จองสิทธิ์ประมูล
               </button>
             )}
   
             {isDepositAmountPaid && !showConfitmBtn && (
               <div>
                 <div className="d-flex flex-row justify-content-between mt-4">
                   <button
                     onClick={() =>
                       handleBiddingButton(
                         Math.round(latestAuctionAmt) +
                           parseFloat(productsData.minbidamount),
                         1
                       )
                     }
                     className={
                       activeAmountButton === 1
                         ? "active_amt_btn"
                         : "amt_btn"
                     }
                   >
                     {currencyFormat(
                       Math.round(latestAuctionAmt) +
                         parseFloat(productsData.minbidamount)
                     )}{" "}
                     ฿
                   </button>
                   <button
                     onClick={() =>
                       handleBiddingButton(
                         Math.round(latestAuctionAmt) +
                           parseFloat(productsData.minbidamount) * 2,
                         2
                       )
                     }
                     className={
                       activeAmountButton === 2
                         ? "active_amt_btn"
                         : "amt_btn"
                     }
                   >
                     {currencyFormat(
                       Math.round(latestAuctionAmt) +
                         parseFloat(productsData.minbidamount) * 2
                     )}{" "}
                     ฿
                   </button>
                   <button
                     onClick={() =>
                       handleBiddingButton(
                         Math.round(latestAuctionAmt) +
                           parseFloat(productsData.minbidamount) * 3,
                         3
                       )
                     }
                     className={
                       activeAmountButton === 3
                         ? "active_amt_btn"
                         : "amt_btn"
                     }
                   >
                     {currencyFormat(
                       Math.round(latestAuctionAmt) +
                         parseFloat(productsData.minbidamount) * 3
                     )}{" "}
                     ฿
                   </button>
                 </div>

                 <div className="action_price_txt text-center mt-4">
                   ราคาลงประมูล
                 </div>
                 {/* <form className="position-relative"> */}
                 <div className="position-relative">
                   <input
                     type="text"
                     placeholder="ระบุราคาลงประมูล"
                     className="action_price_btn mt-2 text-center"
                     name="ระบุราคาลงประมูล"
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                   />

                   {value && (
                     <img
                       src={cancel}
                       alt=""
                       className="bidding_amount_cancel_btn"
                       onClick={handleBiddingAmoutClearBtn}
                     />
                   )}
                 </div>
                 <button
                   disabled={isLoading || value === ""}
                   className="bit_btn mt-4"
                   onClick={handleBiddingBtn}
                 >
                   เสนอราคา
                 </button>
                 {/* </form> */}
               </div>
             )}
             {isDepositAmountPaid && showConfitmBtn && (
               <div className="row conformation-container mx-0">
                 <hr className="mt-3"/>
                 <div className="col-12 mt-1 mb-3 mt-5">
                   <div className="auction_txt">ราคาลงประมูล</div>
                   <div className="auction_amt">
                     {currencyFormat(Math.round(value))}{" "}
                   </div>
                 </div>
                 <div className="col-6 px-2 ">
                   <button
                     className="cancel_btn"
                     onClick={() => setShowConfitmBtn(false)}
                   >
                     ยกเลิก
                   </button>
                 </div>
                 <div className="col-6 px-2">
                   <button
                     className="col-6 bid_btn"
                     onClick={onSubmit}
                   >
                     เสนอราคา
                   </button>
                 </div>
               </div>
             )}
             <div
               className="text-center cursor-pointer my-5"
               onClick={onClickBuyOut}
             >
               <span className="mx-2">
                 <img src={cart_icon} alt="" />
               </span>

               <span className="buy_cnt">
                 ซื้อเลยเพียง&nbsp;
                 <b>
                   {currencyFormat(
                     Math.round(productsData?.purchaseprice)
                   )}
                 </b>
                 &nbsp;บาท
               </span>
             </div>
           </div>
         )}

         {/* {isAuctionClosed && (
           
           <div className="conformation-container mb-3">
             <div className="col-12 mt-1 mb-3 text-center">
               <BiddingStar
                 productsData={productsData}
                 userId={userId}
                 flagTitle={true}
               />
             </div>

             <div className="text-center">
               {productsData?.bid_order === 1 &&
                 productsData?.is_winner === 0 && (
                   <>
                     <h3 className="text-white">
                       Oops... Your payment time is over!
                     </h3>
                   </>
                 )}

               {productsData?.refund_id != "0" ? (
                 <h3 className="text-white">Refund Initiated!</h3>
               ) : (
                 <>
                   {isBiddingPaymentCompleted ? (
                     <>
                       {sessionData?.currentUser?.response?.userId ===
                       productsData?.winnerid ? (
                         <h3 className="text-white">
                           Order Placed Successfully!
                         </h3>
                       ) : (
                         <>
                           <button
                             className="full-bid-btn"
                             onClick={onSubmitBiddingConfirm}
                           >
                             {auctionButtonName()}
                           </button>
                         </>
                       )}
                     </>
                   ) : (
                     <>
                       {productsData?.bid_order === 1 &&
                       productsData?.is_winner === 0 ? (
                         <></>
                       ) : (
                         <button
                           className="full-bid-btn"
                           onClick={onSubmitBiddingConfirm}
                         >
                           {auctionButtonName()}
                         </button>
                       )}
                     </>
                   )}
                 </>
               )}
             </div>
           </div>
         )} */}

         {isBuyOutStart && (
           <div>
             <div className="row conformation-container mx-0 mt-5">
               <div className="col-12 mt-1 mb-3">
                 <div className="buyout_text text-left">
                   ราคาซื้อขาด
                 </div>
                 <div className="auction_amt">
                   {currencyFormat(Math.round(value))} ฿
                 </div>
               </div>
               <div className="col-6 ">
                 <button
                   className="cancel_btn"
                   onClick={canceClickBuyOutModal}
                 >
                   ยกเลิก
                 </button>
               </div>
               <div className="col-6 ">
                 <button
                   className="col-6 bid_btn"
                   onClick={onSubmitBuyOutConfirm}
                 >
                   สินค้า
                 </button>
               </div>
             </div>
             <br />
           </div>
         )}

         <table className="other_bidding_list_tbl">
           <tr>
             <td className="left_head">ร่วมผู้ประมูลล่าสุด</td>
             <td className="right_head"><span onClick={() => getAllAuctionByAuctionId()}>แสดงทั้งหมด</span></td>
           </tr>
           {productsData?.latestAuctionList &&
             productsData?.latestAuctionList.map(
               (val: any, key: number) => (
                 <tr key={key}>
                   <td>
                     <div className="main_content">{val.username}</div>
                     <div className="sub_content">
                       Bid {val.time_ago}
                     </div>
                   </td>
                   <td className="amount">{val?.bidding_amount} ฿</td>
                 </tr>
               )
             )}
         </table>
         </div>
</div>

}
        
       
        
    
              
    </div>
    </>
  );
};
export default ProductDetails;
