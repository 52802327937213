
import "./page.scss";
import search from "../assets/icon/magnifying-glass.svg";
import msgIcon from "../assets/icon/chat-bubble.svg";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {

  return (
    <div className="faq py-5">

    <div className="header">FAQ</div>
    <div className="sub_title">
      คำถามที่พบบ่อยของสมาชิก
    </div>
    <div className="max-width">
      <Tabs
        defaultActiveKey="tab1"
        id="uncontrolled-tab-example"
        className="mb-3 mt-5"
      >
        <Tab eventKey="tab1" title="ค่าธรรมเนียม & การคืนเงิน">
          <div className="col-md-12 m-auto">
            <div className="d-flex position-relative px-2 w-0">
              <div className="position-absolute">
                <div className="search-icon px-2 py-2">
                  <img src={search} alt='' />
                </div>
              </div>
              <input type="text" className="header-input form-control border-0 text-white"  placeholder="รายละเอียด..." id="inlineFormInputGroup" />
            </div>
          </div>

    <div className="row mt-5 p-2 ">
      <div className=" col-lg-6 col-md-12 p-1 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      <div className=" col-lg-6 col-md-12 p-1 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      <div className=" col-lg-6 col-md-12 p-1 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>
    </div>

          
        </Tab>

        <Tab eventKey="tab2" title="การจัดส่ง & การติดตามพัสดุ">
        <div className="col-md-12 m-auto">
        <div className="d-flex position-relative px-2 w-0">
              <div className="position-absolute">
                <div className="search-icon px-2 py-2">
                  <img src={search} alt='' />
                </div>
              </div>
              <input type="text" className="header-input form-control border-0 text-white" placeholder="รายละเอียด..." id="inlineFormInputGroup" />
            </div>
          </div>

    <div className="row mt-5">
      <div className="col-md-6 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      <div className="col-md-6 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      <div className="col-md-6 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>
    </div>

          
        </Tab>

        <Tab eventKey="tab3" title="การประมูล และกติกาการประมูล">
        <div className="col-md-12 m-auto">
          <div className="d-flex position-relative px-2 w-0">
              <div className="position-absolute">
                <div className="search-icon px-2 py-2">
                  <img src={search} alt='' />
                </div>
              </div>
              <input type="text" className="header-input form-control border-0 text-white" placeholder="รายละเอียด..." id="inlineFormInputGroup" />
            </div>
          </div>

    <div className="row mt-5">
      <div className="col-md-6 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      <div className="col-md-6 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      <div className="col-md-6 mt-3 accordion-max-width">
        <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="d-flex align-items-center">
                  <div className="img-container"><img src={msgIcon} alt='' /></div>
                  <div className="mx-2">สามารถดูสินค้าจริงได้ที่ไหนบ้าง ?</div>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="cnt">ลูกค้าสามารถดูสินค้าได้ที่ Showroom Ignite Store @Siam Paragon ชั้น 11 </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>
    </div>

          
        </Tab>

      </Tabs>

      </div>

  


    </div>
  );
};
export default Faq;
