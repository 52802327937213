import React, { useState } from "react";
import "./component.scss";
import "./CardSlider.scss";
import BiddingCounter from "./biddingCounter";
import {Carousel} from "react-responsive-carousel";

const CardSlider = (props: any) => {
  const data = props?.data;
  const showCounter = props?.showCounter;
  const MAX_THUMBNAILS = 3;
  const [activeIndex, setActiveIndex] = useState(0);
  const [sliderData, setSliderData] = useState(data?.fileList[0]);
  const handleClick = (i:number) => {
    const slider = data?.fileList[i];
    setSliderData(slider);
    setActiveIndex(i);
  }
  const imageCount = data?.fileList?.length || 0;

  return (
    
    <div className="product-img-slider" >

      {data.iframeurl ? (
          <a href={data.iframeurl} target="_link">
          <div style={{ backgroundColor: "#000", textAlign: "center" }}>
            <img
              className="main-img"
              src={sliderData?.filepath}
              alt="main-img"
            />
          </div>
          </a>
      ) : (
        <div style={{ backgroundColor: "#000", textAlign: "center" }}>
          <img
            className="main-img"
            src={sliderData?.filepath}
            alt="main-img"
          />
        </div>
      )}

      <div className="d-flex start-img align-items-center">
        <div
          className="d-flex small-img mt-1 mr-1">
          {data?.fileList?.map((item: any, key: number) => (
            <div
              key={key}
              className={`thumbnail ${activeIndex === key ? "active" : ""}`}
              style={{
                marginRight: "5px",
                opacity: activeIndex === key ? 1 : 0.5,
              }}
              onClick={() => handleClick(key)}
            >
              <img onClick={() => handleClick(key)} src={item?.filepath} alt="" />
            </div>
          ))}
        </div>
        {!showCounter && (
          <div className="counter-detail d-block d-lg-none">
            <div className="txt">เหลือเวลา</div>
            <BiddingCounter data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSlider;
