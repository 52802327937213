import React, { useEffect, useState } from "react";
import "./page.scss";
import edit from "../assets/icon/edit.svg";
import trash from "../assets/icon/trash.svg";
import triangle from "../assets/icon/exclamation-triangle.svg";
import AddAddress from "../components/AddAddress";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";
import { deleteAddressApi } from "../controller/AddressFunction";
import { toast } from "react-toastify";
import Spacer from "../components/Spacer";
import ApiService from "../config/api";
import { useLocation, useNavigate } from "react-router";
import {
  setBiddingAllPaymentData,
  setBiddingBillingAddressId,
  setBiddingPaymentStatus,
  setBiddingShippingId,
} from "../store/bidding/action";
import PaymentCompleteModal from "../components/BiddingPaymentModal/PaymentCompleteModal";
import BiddingCompleteModal from "../components/BiddingPaymentModal/BiddingCompleteModal";
import ChoosePaymentModal from "../components/BiddingPaymentModal/ChoosePaymentModal";
import { useSearchParams } from "react-router-dom";

const PaymentOrderAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.state;
  const [urlParams, setSearchParams] = useSearchParams();
  const [isAddressModal, setAddressModal] = useState(false);
  const [isConfirmPaymentModal, setConfirmPaymentModal] = useState(false);
  const [isBiddingPaymentComplete, setBiddingPaymentComplete] = useState(false);
  const [isBiddingCompleteModal, setbiddingCompleteModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [error, setError] = useState(null);
  const [addressData, setAddressData] = useState<any>([]);
  const biddingData = useSelector((state: any) => state.biddingProduct);
  const handleShow = () => {
    setSelected(false);
    setAddressSelectionId("");
    setAddressModal(true);
  };
  const addressModalClose = () => {
    setAddressModal(false);
    setEditData([]);
    getAddress();
  };
console.log(searchParams);
  const [selectedCheckbox, setSelected] = useState(false);

  const paymenthandleShow = async () => {
    console.log(biddingData);
    if (addressData.find((item:any)=>{return  item.isreceiptaddress === 'Y' })) {
      handleSelectAddress(addressData.find((item:any)=>{return item.isreceiptaddress === 'Y'}).id);
      // console.log(addressData.find((item:any)=>{return item.isdefaultaddress === '1'}).id);
      // if (biddingData?.paymentStatus === "") {
      //   dispatch(setBiddingBillingAddressId(addressData.find((item:any)=>{return item.isdefaultaddress === '1'}).id));
      // } else {
      //   dispatch(setBiddingShippingId(addressData.find((item:any)=>{return item.isdefaultaddress === '1'}).id));
      // }
      // console.log(biddingData);
      if (biddingData?.productId) {
        if (biddingData?.paymentStatus === "PAID") {
          setbiddingCompleteModal(true);
        } else {
          dispatch(showLoader());

        //  dispatch(setBiddingPaymentStatus(data?.status));
            dispatch(hideLoader());
            setConfirmPaymentModal(true);

          // let data = {
          //   productId: biddingData?.productId,ig_r_order
          //   biddingAmount: biddingData?.biddingAmount,
          //   status: "PAYMENT_INITIATED",
          //   remarks: "-",
          //   referenceId: "",
          //   paymentType: "P",
          //   auctionId: biddingData?.auctionId,
          // };
          // const paymentInitiate = await ApiService.post(
          //   "/bidding/create/payment",
          //   data
          // );
          // if (paymentInitiate.status === "SUCCESS") {
          //   dispatch(setBiddingPaymentStatus(data?.status));
          //   dispatch(hideLoader());
          //   setConfirmPaymentModal(true);
          // } else {
          //   setError(paymentInitiate.response);
          //   dispatch(hideLoader());
          // }
        }
      }
    } else {
      toast.warning("Please select address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const payDepositAmount = async () => {
    if (selectedCheckbox) {
      if (biddingData?.productId) {
        dispatch(showLoader());
        let data = {
          productId: biddingData?.productId,
          biddingAmount: biddingData?.biddingAmount,
          status: "PAID",
          paymentType: "D",
          remarks: "",
          referenceId: "",
          actionId: biddingData?.auctionId,
        };
        const paymentInitiate = await ApiService.post(
          "/bidding/create/payment",
          data
        );
        if (paymentInitiate.status === "SUCCESS") {
          dispatch(setBiddingPaymentStatus(data?.status));
          dispatch(hideLoader());
          setConfirmPaymentModal(true);
          // navigate(`/productdetails?productId=${biddingData?.productId}`);
        } else {
          setError(paymentInitiate.response);
          dispatch(hideLoader());
        }
      }
    } else {
      toast.warning("Please select address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    getAddress();
    console.log(urlParams.get("omiseToken"));
    if (urlParams.get("omiseToken") || urlParams.get("omiseSource")) {
      getPaymentBiddingDetails();
    }
  }, []);

  const getPaymentBiddingDetails = async () => {
    const paymentDetails = await ApiService.get("/payment/cache/get");
    if (paymentDetails.status === "SUCCESS") {
      let payment = paymentDetails.response[0];
      console.log(payment);
      dispatch(
        setBiddingAllPaymentData(
          payment?.product_id,
          payment?.amount,
          payment?.auction_id,
          payment?.status,
          payment?.shipping_address,
          payment?.billing_address
        )
      );
      setBiddingPaymentComplete(true);
    }
  };

  const getAddress = async () => {
    dispatch(showLoader());
    const getAddressRes = await ApiService.get("/user/myaddress");
    if (getAddressRes.status === "SUCCESS") {
      setAddressData(getAddressRes?.response?.address);
      dispatch(hideLoader());
      setSelected(false);
    } else {
      setError(getAddressRes.response);
      dispatch(hideLoader());
    }
  };

  const editAddress = (editItem: any) => {
    setSelected(false);
    setAddressSelectionId("");
    setAddressModal(true);
    setEditData(editItem);
    setSelected(false);
  };

  const deleteAddress = async (event: any, item: any, index: number) => {
    event.preventDefault();
    dispatch(showLoader());
    const getAddressRes = await deleteAddressApi(item);
    if (getAddressRes.status === true) {
      setAddressData((addressData: any) =>
        addressData.filter((_: any, i: number) => index !== i)
      );
      dispatch(hideLoader());
      setSelected(false);
    } else {
      setError(getAddressRes.response);
      dispatch(hideLoader());
    }
  };
  const [addressSelectionId, setAddressSelectionId] = useState("");


  const selectDefaultAddress = async (id: any) => {
    let data = {
      id:id,
      type: biddingData?.paymentStatus === "" ? 'billing' : 'shipping'
    };

    const requestRes = await ApiService.post(
      "/user/setDefaultAddress",
      data
    );

    if (requestRes.status === "SUCCESS") {
      getAddress();
    }
  };

  const handleSelectAddress = async (id: any) => {
    dispatch(showLoader());
    let data = {
      product_id: biddingData?.productId,
      amount: biddingData?.biddingAmount,
      status: biddingData?.paymentStatus,
      shipping_address: biddingData?.shippingId,
      billing_address: biddingData?.billingAddressId,
      auction_id: biddingData?.auctionId,
    };
    if (biddingData?.paymentStatus === "") {
      data["billing_address"] = id;
    } else {
      data["shipping_address"] = id;
    }
    const paymentInitiate = await ApiService.post(
      "/payment/cache/insert",
      data
    );
    if (paymentInitiate.status === "SUCCESS") {

      dispatch(hideLoader());
      // setSelected(true);
      // setAddressSelectionId(id);
      // if (biddingData?.paymentStatus === "") {
      //   dispatch(setBiddingBillingAddressId(id));
      // } else {
      //   dispatch(setBiddingShippingId(id));
      // }
      // dispatch(hideLoader());
    } else {
      //setError(paymentInitiate.response);
      dispatch(hideLoader());
    }
  };
  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <div>
      <div className="address-page py-5 py-5">
        <div className="container">
          <div className="title text-center">
            {(biddingData?.productId === 0 &&
              biddingData?.paymentStatus === "") ||
            biddingData?.paymentStatus === "PAID"
              ? `ที่อยู่`
              : `เลือกที่อยู่ใบเสร็จ`}
          </div>
          {(biddingData?.productId === 0 ||
            biddingData?.paymentStatus === "PAID") && (
            <div className="sub_title text-center">กรอกข้อมูลส่วนตัว</div>
          )}
          <Spacer height={"3rem"} width={0} />
          {addressData && (
            <>
              {addressData.map((item: any, key: number) => (
                <div key={key}>
                  <div className="address-container mt-3">
                    <div className="address-list">
                      <input
                        type="radio"
                        name="actions"
                        value={item.id}
                        checked={biddingData?.paymentStatus === "" ? item.isreceiptaddress === "Y" : item.isdeliveryaddress === "Y" }
                        className="mx-2"
                        onChange={() => selectDefaultAddress(item.id)}
                      />
                      <div className="details p-3">
                        {item.name} <br />
                        {item.contactnumber} <br />
                        {item.address1},
                        {item.address2},
                        {item.province},
                        {item.city},
                        {item.country},
                        <br /> {item.pincode}
                      </div>
                    </div>
                    <div className="px-2">
                      <img
                        src={edit}
                        className=""
                        alt=""
                        onClick={() => editAddress(item)}
                      />
                      <img
                        src={trash}
                        className=""
                        alt=""
                        onClick={(e) => deleteAddress(e, item.id, key)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="text-center">
            <button className="add_address mt-5" onClick={handleShow}>
              + เพิ่มที่อยู่
            </button>
          </div>
          <div className="text-center">
            <button
              onClick={
                searchParams === "depositPayment"
                  ? payDepositAmount
                  : paymenthandleShow
              }
              className="submit_btn my-5"
            >
              บันทึกเป็นที่อยู่ใบเสร็จ
            </button>
            <div>
              <img
                src={triangle}
                className="mx-2"
                style={{ width: "16px", height: "16px" }}
                alt=""
              />
              <span className="warning_msg">
                หากยืนยันที่อยู่เสร็จแล้ว จะไม่สามารถเปลี่ยนได้ภายหลัง
              </span>
            </div>
          </div>
        </div>
      </div>

      <AddAddress
        isOpen={isAddressModal}
        isClose={addressModalClose}
        data={editData}
      ></AddAddress>

      {/* {isPaymentModal && (
          <ChoosePaymentModal
            isOpen={isPaymentModal}
            isClose={paymentModalClose}
            // data={addressSelectionId}
          />
        )} */}

      {isBiddingCompleteModal && (
        <BiddingCompleteModal isOpen={isBiddingCompleteModal} />
      )}
      {isConfirmPaymentModal && (
        <ChoosePaymentModal
          isOpen={isConfirmPaymentModal}
          isClose={() => setConfirmPaymentModal(false)}
          data={addressSelectionId}
        />
      )}
      {isBiddingPaymentComplete && (
        <PaymentCompleteModal
          isOpen={isBiddingPaymentComplete}
          isClose={() => setBiddingPaymentComplete(false)}
          data={addressSelectionId}
        />
      )}
    </div>
  );
};

export default PaymentOrderAddress;
