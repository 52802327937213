// reducers.js
const initialState = {
  productId: 0,
  biddingAmount: 0,
  auctionId: 0,
  paymentStatus: "",
  shippingId: 0,
  billingAddressId: 0,
  paymentType: "P",
  serviceAmount:0,
  depositAmount:0,
  paymentMode:"Card",
  isBuyOutPayment:false
};

const biddingProduct = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_BIDDING_DATA":
      return {
        ...state,
        productId: action.payload.productId,
        biddingAmount: action.payload.biddingAmount,
        auctionId: action.payload.auctionId,
        depositAmount:action.payload.depositAmount,
        serviceAmount:action.payload.serviceAmount
      };
    case "SET_BIDDING_ALL_PAYMENT_DATA":
      return {
        ...state,
        productId: action.payload.productId,
        biddingAmount: action.payload.biddingAmount,
        auctionId: action.payload.auctionId,
        paymentStatus: action.payload.paymentStatus,
        shippingId: action.payload.shippingId,
        billingAddressId: action.payload.billingAddressId,
      };
    case "SET_BIDDING_PAYMENT_STATUS":
      return {
        ...state,
        paymentStatus: action.payload,
      };
    case "SET_BIDDING_PAYMENT_MODE":
      return {
        ...state,
        paymentMode: action.payload,
      };
    case "SET_DEPOSITE_PAYMENT_TYPE":
      return {
        ...state,
        paymentType: action.payload,
      };
    case "SET_BIDDING_SHIPPING_STATUS":
      return {
        ...state,
        shippingId: action.payload,
      };
    case "SET_BIDDING_BILLING_ADDRESS_STATUS":
      return {
        ...state,
        billingAddressId: action.payload,
      };
    case "SET_BUYOUT_PAYMENT":
    return {
      ...state,
      isBuyOutPayment: action.payload,
    };
    case "CLEAR_BIDDING_DATA":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default biddingProduct;
