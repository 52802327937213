
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../layout/main.scss";
import "./component.scss";

const MobileCarouselScreen = (props: any) => {
  const sliderData = props.datas;
  const [index, setIndex] = useState(0);
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };

  const redirectToNewTab = (url: any) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const getImage = (list: any, type: any) => {
    if (list && list.length) {
      if (type === "mobile") {
        const img = list.find((val: any) => val.filecat === "mobile");
        return img ? img.filepath : list[0].filepath;
      } else {
        const img = list.find(
          (val: any) => val.filecat !== "mobile" || val.filecat == null
        );
        return img ? img.filepath : list[0].filepath;
      }
    }
    return null;
  };
  const isLastSlide = (index: number, length: number) => {
    return index === length - 1;
  };

  return (
    <div className="margin-35 px-0 mb-carousel mt-4" style={{ cursor: "pointer" }}>
      <Carousel
        className="crsl"
        autoPlay
        // infiniteLoop
        centerMode
        interval={10000}
        showArrows={false}
        showThumbs={true}
        showStatus={false}
        
        // onClickItem={handleSelect}
      >
        {sliderData &&
          sliderData.map((slide: any, i: number) =>
            slide.filelist
            .filter((file: any) => file.filecat === 'mobile')
            .map((file: any, key: number) => (
              <div key={key}
              onClick={() => redirectToNewTab(slide?.bannerurl)}
              >
                <img
                  src={file.filepath}
                  alt=""
                //   style={isLastSlide(key, slide.filelist.length) ? { transform: "translate3d(0%, 0px, 10%)" } : {}}
                />
                <div className="banner-message">
                {/* <p>Highlight</p> */}
                <h3>{slide.bannertopic}</h3>
                </div>
              </div>
            ))
          )}
      </Carousel>
    </div>
  );
};

export default MobileCarouselScreen;
