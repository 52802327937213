import React, { useEffect, useState } from "react";
import "../page.scss";
import { Link } from "react-router-dom";
import heart_icon from "../../assets/icon/empty-heart.svg";
import heart_icon_red from "../../assets/icon/heart-full-red.svg";
import { addFavourite, getFavourite } from "../../controller/FavouriteFunction";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { toast } from "react-toastify";
import BiddingCounter from "../../components/biddingCounter";
import { BASE_URL_IMG } from "../../config/api";
import { getBiddingCounterText } from "../../services/Utils";
import BiddingOrderIcon from "../../components/BiddingOrderIcon";

const MyFavorite = () => {
  const dispatch = useDispatch();
  const [favData, setFavData] = useState<any>([]);
  const [error, setError] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [noData, setNoData] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const userDetail:any = localStorage.getItem('user');

  useEffect(() => {
    getMyAccountApi();
  }, []);

  const buttonHtml:any=(item:any)=>{
    if(item.auctionstatus == 'CLOSED' && item.is_winner == 1){
      return '<button class="button green">ชำระค่าสินค้า</button>';
    } else if(item.auctionstatus != 'CLOSED' && item.deposit_id == '0'){
      return '<button class="button">จองสิทธิ์ประมูล</button>';
    } else if(item.auctionstatus != 'CLOSED' && item.deposit_id != '0'){
      return '<button class="button">เสนอราคา</button>';
    } else if(item.auctionstatus == 'CLOSED'){
      return '<button class="button close_gray">ปิดประมูล</button>';
    }
    
  }

  const getMyAccountApi = async () => {
    dispatch(showLoader());
    let pageNumber = pageNo + 1;
    const myFavRes = await getFavourite(pageNumber);
    if (myFavRes.status === true) {
      // setFavData((favData: any) => [...favData, myFavRes?.response?.response]);
      // setFavData(myFavRes?.response?.response);
      if (myFavRes?.response?.response?.length === 0) {
        setNoData(true);
      } else {
        setPageNo(pageNumber);
        setFavData((favData: any) => [
          ...favData,
          ...myFavRes?.response?.response,
        ]);
      }

      dispatch(hideLoader());
    } else {
      setError(myFavRes.response);
      dispatch(hideLoader());
    }
  };

  const removeFavlist = async (event: any, proId: any) => {
    event.preventDefault();
    const response = await addFavourite(proId);
    if (response?.status === true && response?.response?.status === "SUCCESS") {
      setFavData((favData: any) =>
        favData.filter((data: any) => data.product_id !== proId)
      );
    }
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <div className="my-favorite py-5 ">
      <div className="container">
        <div className="title">My Favorite</div>
        {favData && favData.length <= 0 ? (
          <div className="empty-favorite text-center">
            <div>
              <img className="img" src={heart_icon} alt="" />
              <div className="txt1 mt-4">คุณยังไม่มีรายการสินค้าที่ถูกใจ</div>
              <Link to="/">
                <div className="txt2 mt-3"> ค้นหารายการสินค้าที่ถูกใจ </div>
              </Link>
            </div>
          </div>
        ) : (
          <>
            {favData.map((item: any, key: number) => (
              <Link to={`/productdetails?productId=${item?.product_id}`}>
                <div key={key} className="favorite-list mt-4">
                  <div className="row d-none d-md-flex">
                    <div className="col-md-8 d-flex">
                      <div
                        className="left p-2 col-2"
                        style={{
                          backgroundImage: `url("${item?.filepath}"`,
                        }}><BiddingOrderIcon bid_order={item?.bid_order} productsData={item} userId={userDetail?.userId} ></BiddingOrderIcon></div>
                      <div className="main_container px-3 col pt-2">
                        <div className="mb-2">
                          <div className="no-txt d-flex flex-column"> {item?.is_winner === 1 && <span className="win-txt mr-2">คุณชนะการประมูล</span>}No. {item?.runningno}</div>
                          <div className="name" style={{fontSize: "16px"}}>{item?.name}</div>
                        </div>

                        <div className="row pb-2">
                          <div className="col-6">
                            <div className="amount-txt">ราคาประมูลล่าสุด</div>
                            <div className="amount" style={{fontSize: "16px"}}>
                              {item.latestbiddingamount
                                ? item.latestbiddingamount
                                : item.minbidamount}{" "}
                              ฿
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="time-txt">
                              {getBiddingCounterText(
                                item?.auctionstartdatetime,
                                item?.auctionclosedatetime
                              )}
                            </div>
                            <BiddingCounter data={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex align-items-center gap-3 flex-column p-2">
                      <div
                        className="like_cnt"
                        onClick={(event) =>
                          removeFavlist(event, item?.product_id)
                        }
                      >
                        <img className="like_img" src={heart_icon_red} alt="" />
                        <span className="like_count">{item.like_count}</span>
                      </div>
                      <div className="text-right" style={{
                         width:'100%',
                        }} dangerouslySetInnerHTML={{ __html: buttonHtml(item) }} />
                      
                      {/* {item.auctionstatus !== "CLOSED" && (
                        <button className="button">
                          {item.deposit_id === "0"
                            ? "จองสิทธิ์ประมูล"
                            : "เข้าร่วมประมูล"}
                        </button>
                      )} */}

                      
                    </div>
                  </div>
                     <div className="d-md-none d-sm-flex" >
                      <div className="d-flex gap-1 w-100">
                         <div className="">
                          <BiddingOrderIcon bid_order={item?.bid_order} productsData={item} userId={userDetail?.userId} ></BiddingOrderIcon>
                             <div
                              className="left"
                              style={{
                                backgroundImage: `url("${item?.filepath}"`,
                                width: "175px",
                                height: "175px"
                              }}
                            ></div>
                         </div>
                         <div className="main_container p-2 w-75">
                          
                              <div className="mb-2">
                                <div className="no-txt d-flex flex-column"> {item?.is_winner === 1 && <span className="win-txt mr-2">คุณชนะการประมูล</span>}No. {item?.runningno}</div>
                                <div className="name truncate-line2">{item?.name}</div>
                              </div>
                              <div className="d-flex justify-content-between">
                              <div className="">
                              <div className="amount-txt">ราคาประมูลล่าสุด</div>
                                  <div className="amount">
                                    {item.latestbiddingamount
                                      ? item.latestbiddingamount
                                      : item.minbidamount}{" "}
                                    ฿
                                  </div>
                                  <div className="time-txt">
                                    {getBiddingCounterText(
                                      item?.auctionstartdatetime,
                                      item?.auctionclosedatetime
                                    )}
                                  </div>
                            <BiddingCounter data={item} />
                              </div>
                              <div className="align-items-center">
                              <div
                        className="like_cnt"
                        onClick={(event) =>
                          removeFavlist(event, item?.product_id)
                        }
                      >
                        <img className="like_img" src={heart_icon_red} alt="" />
                        <span className="like_count">{item.like_count}</span>
                      </div>
                      {/* {item.auctionstatus !== "CLOSED" && (
                        <button className="button">
                          {item.deposit_id === "0"
                            ? "จองสิทธิ์ประมูล"
                            : "เข้าร่วมประมูล"}
                        </button>
                      )} */}
                              </div>
                              </div>
                              </div>
                         </div>
                      </div>
                    </div> 
              </Link>
            ))}
          </>
        )}
        {!noData && (
          <>
            <div className="text-center">
              <button
                className="bottom_btn my-5"
                onClick={() => [setExpanded(!expanded), getMyAccountApi()]}
              >
                {expanded ? "แสดงรายการน้อยลง" : "แสดงรายการเพิ่มเติม"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default MyFavorite;
