import React, { useState } from "react";
import "./footer.scss";
import ApiService from "../config/api";
import { toast } from "react-toastify";

const FooterSecondary = () => {

  const [email, setEmail] = useState('');

  const handleInputChange = (event:any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event:any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    event.preventDefault();
    if(!email || email === ''){
      toast.warning('Please enter the email', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(!emailRegex.test(email)){
      toast.warning('Please enter valid email', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    
    try {
        const favData = { email: email };
        let response = await ApiService.post("/user/subscribe", favData);
        console.log(response);
        if(response){
          toast.warning('Saved successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEmail('');
        }
       
      // Optionally handle success here, like showing a success message or redirecting

    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally handle error here, like showing an error message
    }
  };

  return (
    <div className="footer-secondary">
      <div className="bg"> 
      <div className="max-width">
      <div className="row py-5 px-3 mx-0">
          <div className="col-md-6">
            <div className="primary-cont">สมัครติดตามข่าวสาร</div>
            <div className="secondary-cont">
              สมัครรับข่าวสารกับเราวันนี้ เพื่อรับข้อมูลสินค้า โปรโมชั่น
              ส่วนลดและข้อเสนอพิเศษก่อนใคร
            </div>
          </div>
          <div className="col-md-6 d-flex my-4 my-md-0">
            <div className="field-cnt d-flex">
   
            <input
              type="email"
              className="input"
              placeholder="Enter your email"
              value={email}
              onChange={handleInputChange}
            />
            <button type="button" onClick={handleSubmit} className="footer-secondary-btn">รับข่าวสาร</button>

            </div>
          </div>
        </div>
      </div>
       
      </div>
    </div>
  );
};

export default FooterSecondary;
