import { Link, useNavigate } from "react-router-dom";
import "./component.scss";

const Banner = (props: any) => {
  const navigate = useNavigate();
  const bannerData = props.datas[0];

  const redirectToNewTab = (url:any) => {
    if(url){
      window.open(url, '_blank');
    }
  };

  return (
    <>
    <div onClick={() => redirectToNewTab(bannerData?.bannerurl)} className="banner-parent px-md-5">
      {bannerData && (
        <>
          <video autoPlay muted loop className="video-width">
            <source src={bannerData?.filelist[0]?.filepath} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
          <div className="col-md-12 px-0 mt-md-4 banner-content">
            <div className="main_container">
              <div className="text-white text-left px-0 main_content">
                {bannerData && bannerData.bannertopic}
              </div>
              <div className="text-white text-left mt-2 px-0 sub_content">
                {bannerData && bannerData.bannermessage}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    </>
  );
};

export default Banner;
