import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div>
      <div className="overlay "></div>
      <div className="loading-container">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <div>Loading...</div>
      </div>
    </div>
  );
};

export default Loader;
