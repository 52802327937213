import "./page.scss";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import PasswordChecklist from "react-password-checklist";
import ApiService from "../config/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spacer from "../components/Spacer";
import screenCheck from "../assets/icon/screen-chech.svg";

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const forgotEmail = location.state?.forgotEmail;
  const [errormsg, setErrormsg] = useState(false);
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordChangeText, setPasswordChangeText] = useState(false);
  const [checkPasswordModal, setCheckPasswordModal] = useState(false);
  const handleSetPassword = (e: any) => {
    setPassword((form.password = e.target.value));
  };
  const handleSetMatchPassword = (event: any) => {
    setMatchPassword((form.confirmPassword = event.target.value));
  };
  const [form, setForm] = useState({
    password: password,
    confirmPassword: matchPassword,
  });
  const [userData, setUserData] = useState(0);
  // useEffect(() => {
  //   const storedUserData = JSON.parse(localStorage.getItem("user") || "{}");
  //   setUserData(storedUserData.response.id);
  // }, []);

  const onchangePassword = async (e: any) => {
    e.preventDefault();
    if (form.password === "" || form.confirmPassword === "") {
      setErrormsg(true);
    } else if (
      password !== matchPassword &&
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)
    ) {
      setErrormsg(true);
    } else {
      setErrormsg(false);
      if (forgotEmail) {
        let reqData = {
          email: forgotEmail,
          password: form.password,
        };
        const response = await ApiService.post(
          "/user/update/password",
          reqData
        );
        if (response.status === "SUCCESS") {
          setCheckPasswordModal(true)
          setPasswordChangeText(true);
        }
      } else {
        let reqData = {
          // id: userData,
          password: form.password,
        };
        const response = await ApiService.post(
          "/user/update/password",
          reqData
        );
        if (response.status === "SUCCESS") {
          setCheckPasswordModal(true);
          setPasswordChangeText(true);
        }
      }
    }
  };
  return (
    <>
      <div className="change-password">
      {!checkPasswordModal ?(

        <div className="container">
          <div className="header py-5">เปลี่ยนรหัสผ่าน</div>
          {passwordChangeText && (
            <div className="success-change-pass">
              <h6>Password Changed Successfully</h6>
            </div>
          )}
          <form className="form-control-container">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>รหัสผ่าน</Form.Label>
              <Form.Control
                className={
                  errormsg === true && form.password === ""
                    ? "form-control-error"
                    : "form-control"
                }
                type="password"
                placeholder=""
                onChange={handleSetPassword}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
              <Form.Control
                type="password"
                className={
                  errormsg === true && form.confirmPassword === ""
                    ? "form-control-error"
                    : "form-control"
                }
                onChange={handleSetMatchPassword}
              />
            </Form.Group>

            <div className="password-checker my-4">
              {/* <div className="d-flex align-items-center mt-2">
          <span className="circle"></span>
          <span className="txt">ตัวอักษรภาษาอังกฤษพิมพ์เล็กอย่างน้อย 1 </span>
        </div>

        <div className="d-flex align-items-center mt-2">
          <span className="circle"></span>
          <span className="txt"> ตัวตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว </span>
        </div>

        <div className="d-flex align-items-center mt-2">
          <span className="circle"></span>
          <span className="txt">ตัวอักขระพิเศษอย่างน้อย 1 ตัว ( @ # $ % ! & ? ) </span>
        </div>

        <div className="d-flex align-items-center mt-2">
          <span className="circle"></span>
          <span className="txt">ตัวเลขอย่างน้อย 1 ตัว </span>
        </div>

        <div className="d-flex align-items-center mt-2">
          <span className="circle"></span>
          <span className="txt">จำนวนตัวอักษรทั้งหมดไม่น้อยกว่า 8 ตัว </span>
        </div> */}
              <PasswordChecklist
                rules={[
                  "lowercase",
                  "capital",
                  "specialChar",
                  "number",
                  "minLength",
                  "match",
                ]}
                minLength={8}
                value={password}
                valueAgain={matchPassword}
                onChange={(isValid) => {}}
                iconComponents={{
                  ValidIcon: (
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50px",
                        backgroundColor: "green",
                        textAlign: "center",
                        lineHeight: "20px",
                        fontSize: "12px",
                      }}
                    >
                      &#x2713;
                    </div>
                  ),
                  InvalidIcon: (
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50px",
                        backgroundColor: "#737576",
                      }}
                    ></div>
                  ),
                }}
                messages={{
                  lowercase: "ตัวอักษรภาษาอังกฤษพิมพ์เล็กอย่างน้อย 1 ตัว",
                  capital: "ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว",
                  specialChar:
                    "ตัวอักขระพิเศษอย่างน้อย 1 ตัว ( @ # $ % ! & ? )",
                  number: "ตัวเลขอย่างน้อย 1 ตัว",
                  minLength: "จำนวนตัวอักษรทั้งหมดไม่น้อยกว่า 8 ตัว",
                  match: "รหัสผ่านและยืนยันการจับคู่รหัสผ่าน",
                }}
              />
            </div>

            <input
              onClick={onchangePassword}
              type="submit"
              className="button mt-2 mb-5"
              value="เปลี่ยนรหัสผ่าน"
            />
          </form>
        </div>
      )
      :
      (
      <div className="register-second-page pt-4">
        <div className="text-center">
          <img
            src={screenCheck}
            className="mx-2"
            style={{ width: "100px", height: "100px" }}
            alt=''
          />
        </div>

        <div className="sub_title my-5">รีเซ็ตรหัสผ่านเสร็จสมบูรณ์</div>

        <p className="text-center my-4 content-style">
          ขอบคุณที่ร่วมเป็นส่วนหนึ่งกับเรา <br />{" "}
          ของให้มีความสุขกับการประมูลสินค้า คุณภาพจากเรา
        </p>
        <Spacer height="30px" width="" />
        <Link to="/">
          {" "}
          <button className="submit-btn my-5">กลับสู่หน้าหลัก</button>
        </Link>
      </div>
      )}
      </div>
    </>
  );
};
export default ChangePassword;
