import React, { useState } from "react";
import "./style.scss";
import { TagFormat } from "../../components";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import Spacer from "../../components/Spacer";
import triangless from "../../assets/icon/exclamation-triangle.svg";
import envelop from "../../assets/icon/envelope-open.svg";
import screenCheck from "../../assets/icon/screen-chech.svg";
import phone from "../../assets/icon/phone-message.svg";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PasswordChecklist from "react-password-checklist";
import ApiService from "../../config/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openLoginModal } from "../../store/login/actions";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { toast } from "react-toastify";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const forgotKey = location.state;
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  React.useEffect(() => {
    if (forgotKey === 9) {
      setTabSelect(forgotKey);
    } else {
      setTabSelect(1);
    }
  }, [forgotKey]);
  const [tabSelect, setTabSelect] = useState(1);
  const [isActive, setIsActive] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [error, setError] = useState(null);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSelect = async (key: any) => {
    if (form.email === "" || password === "" || matchPassword === "") {
      setErrormsg(true);
    } else if (isActive === false) {
      setErrormsg(true);
    } else if (
      password !== matchPassword &&
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)
    ) {
      setErrormsg(true);
    } else if (isEmailValid) {
      let formData = { email: form.email };
      const response: any = await ApiService.post(
        "/user/checkUserExist",
        formData
      );
      if (response && response?.isExist === true) {
        toast.warning(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setErrormsg(true);
      } else {
        setTabSelect(key);
        setErrormsg(false);
      }
    } else {
      setErrormsg(true);
    }
  };

  //REGISTER FORM SUBMISSION

  const [birthDay, setbirthDayDate] = useState('');
  const [gender, setGender] = useState("Select");
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");

  const [form, setForm] = useState({
    name: "",
    username: "",
    lastName: "",
    email: "",
    password: "",
    gender: "",
    birthDay: birthDay,
    isSystemUser: "N",
  });

  const [emailOTPForm, setemailOTPForm] = useState({
    otp: "",
  });

  const chooseGender = (event: any) => {
    // setGender((form.gender = eventkey));
    const value = event.target.value;
    setGender((form.gender = value));
    // setErrormsg(value !== "")
  };
  const dataPicker = (e: any) => {
    const selectedDate = e.target.value;
    const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format

    if (selectedDate > currentDate) {
      setbirthDayDate('');
      toast.warning("Please select a date not in the future.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      const currentDate = new Date().toISOString().slice(0, 10);
      setbirthDayDate(currentDate);
      return; // Prevent setting state if date is in future
    }
    
    setbirthDayDate((form.birthDay = selectedDate));
  };

  const handleSetPassword = (e: any) => {
    setPassword((form.password = e.target.value));
  };
  const handleSetMatchPassword = (event: any) => {
    setMatchPassword(event.target.value);
  };
  const [isEmailValid, setValidEmail] = useState(true);
  const validateEmail = (email: string) => {
    setValidEmail(emailRegex.test(email));
  };
  const handleChange = (e: any) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
    validateEmail(e.target.value);
  };
  const termsAndCond = (event: any) => {
    setIsActive(!isActive);
  };

  const signup = async () => {
    try {
      const response: any = await ApiService.post("/user/create", form);
      if (response.id) {
        localStorage.setItem("user_id", response.id);
        localStorage.setItem("email", response.email);
        setTabSelect(3);
      } else {
        setErrormsg(true);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };
  const onSubmitForm = (e: any) => {
    console.log(form.birthDay);
    e.preventDefault();
    if (
      form.name === "" ||
      form.username === "" ||
      form.lastName === "" ||
      form.gender === "" ||
      form.birthDay === undefined
    ) {
      setErrormsg(true);
    } else if (isActive === false) {
      setErrormsg(true);
    } else {
      signup();
    }
  };

  const handleChangeEmailOtp = (e: any) => {
    const otpState = {
      ...emailOTPForm,
      [e.target.name]: e.target.value,
    };
    setemailOTPForm(otpState);
  };
  const verifyEmailOTPCallForgotPassword = async () => {
    try {
      let req = {
        email: forgotEmail,
        otp: emailOTPForm.otp,
      };
      const response: any = await ApiService.post("/user/verifyEmailOTP", req);
      if (response?.status === "SUCCESS") {
        navigate("/changepassword", { state: { forgotEmail }, replace: true });
      } else {
        toast.warning(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setErrormsg(true);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };
  const verifyEmailOTPCall = async () => {
    try {
      let req = {
        id: localStorage.getItem("user_id"),
        otp: emailOTPForm.otp,
      };
      const response: any = await ApiService.post("/user/verifyEmailOTP", req);
      if (response.response === 1) {
        setTabSelect(5);
      } else {
        toast.warning(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setErrormsg(true);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };
  const VerifyEmailOTPForForgot = () => {
    if (emailOTPForm.otp === "" || emailOTPForm.otp.length < 5) {
      setErrormsg(true);
    } else {
      setErrormsg(false);
      verifyEmailOTPCallForgotPassword();
    }
  };
  const VerifyEmailOTP = (event: any) => {
    if (emailOTPForm.otp === "" || emailOTPForm.otp.length < 5) {
      setErrormsg(true);
    } else {
      setErrormsg(false);
      verifyEmailOTPCall();
    }
  };

  const [mobileForm, setmobileForm] = useState({
    phone: "",
  });

  const handleChangeMobileForm = (e: any) => {
    const mobileState = {
      ...mobileForm,
      [e.target.name]: e.target.value,
    };

    setmobileForm(mobileState);
  };

  const sendMobileOTPCall = async () => {
    try {
      let req = {
        id: localStorage.getItem("user_id"),
        phone: mobileForm.phone,
      };

      const response: any = await ApiService.post("/user/sendMobileOTP", req);

      //   const response = {
      //     "code": "000",
      //     "detail": "OK.",
      //     "result": {
      //         "token": "e759cf72-5b6c-44c5-a1b6-a2adfff708b7",
      //         "ref_code": ""
      //     }
      // }
      if (response.result) {
        localStorage.setItem("token", response.result.token);
        setTabSelect(7);
      } else {
        setErrormsg(true);
      }
    } catch (error: any) {}
  };

  const sendMobileOTP = (event: any) => {
    if (mobileForm.phone === "") {
      setErrormsg(true);
    } else {
      setErrormsg(false);
      sendMobileOTPCall();
    }
  };

  const [mobileOTPForm, setmobileOTPForm] = useState({
    otp: "",
  });

  const handleChangeMobileOTPForm = (e: any) => {
    const otpState = {
      ...mobileOTPForm,
      [e.target.name]: e.target.value,
    };

    setmobileOTPForm(otpState);
  };

  const verifyMobileOTPCall = async () => {
    try {
      let req = {
        token: localStorage.getItem("token"),
        otp: mobileOTPForm.otp,
      };

      const response: any = await ApiService.post("/user/verifyMobileOTP", req);

      //   const response = {
      //     "code": "000",
      //     "detail": "OK.",
      //     "result": {
      //         "token": "e759cf72-5b6c-44c5-a1b6-a2adfff708b7",
      //         "ref_code": ""
      //     }
      // }
      if (response.result.status) {
        setTabSelect(8);
      } else {
        setErrormsg(true);
      }
    } catch (error: any) {}
  };

  const VerifyMobileOTP = (event: any) => {
    if (mobileOTPForm.otp === "" || mobileOTPForm.otp.length < 5) {
      setErrormsg(true);
    } else {
      setErrormsg(false);
      verifyMobileOTPCall();
    }
  };
  const [forgotEmail, setForgotEmail] = useState("");

  const forgothandleChange = (event: any) => {
    setForgotEmail(event.target.value);
  };
  const handleForgotEmail = async (event: any) => {
    event.preventDefault();
    dispatch(showLoader());
    const response = await ApiService.post("/user/sendChangePasswordLink", {
      email: forgotEmail,
    });
    if (response.status === "SUCCESS") {
      setTabSelect(3);
      dispatch(hideLoader());
    } else {
      toast.warning(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError(response.response);
      dispatch(hideLoader());
    }
  };
  const resendMailOTP = async () => {
    try {
      let req = {
        to: localStorage.getItem("email"),
        id: localStorage.getItem("user_id"),
      };

      const response: any = await ApiService.post("/user/resentEmailOTP", req);
      if (response.result) {
        //setTabSelect(8)
      } else {
        //setErrormsg(true)
      }
    } catch (error: any) {}
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <React.Fragment>
      <div className="main_bg">
        <div className="container">
          <Tabs
            defaultActiveKey="tab6"
            id="uncontrolled-tab-example"
            className="mb-3 mt-5"
            activeKey={tabSelect}
            onSelect={handleSelect}
          >
            <Tab eventKey="1" title="ค่าธรรมเนียม & การคืนเงิน">
              <div className="row text-center">
                <TagFormat
                  className="register_title"
                  Tag="p"
                  text="ลงทะเบียนเป็นสมาชิก"
                />
                <TagFormat
                  className="register_subtitle"
                  Tag="p"
                  text="IGNITE Limited Edition"
                />
              </div>
              <div className="center_main_cont">
                <Row>
                  <Col lg="6" className="left_container px-lg-5 py-4 px-4">
                    <p className="text-lg-center font_regular">
                      สิทธ์ประโยนช์การเป็นสมาชิก
                    </p>
                    <div className="col-lg-10 col-12 left_cont">
                      <div className="left_cont_list">
                        <TagFormat text="1" Tag="h2" />
                        <Spacer width="2rem" height="0" />
                        <TagFormat
                          text="มอบตัว เอ็นจีโอเหมยเซ็กซ์สไปเดอร์ ไทยแลนด์แต๋วลิสต์ สเตอริโอโปรดักชั่นตรวจทานจิตเภทพาเหรด"
                          Tag="p"
                        />
                      </div>
                      <Spacer width="0" height="2rem" />
                      <div className="left_cont_list">
                        <TagFormat text="2" Tag="h2" />
                        <Spacer width="2rem" height="0" />
                        <TagFormat
                          text="มอบตัว เอ็นจีโอเหมยเซ็กซ์สไปเดอร์ ไทยแลนด์แต๋วลิสต์ สเตอริโอโปรดักชั่นตรวจทานจิตเภทพาเหรด"
                          Tag="p"
                        />
                      </div>
                      <Spacer width="0" height="2rem" />
                      <div className="left_cont_list">
                        <TagFormat text="3" Tag="h2" />
                        <Spacer width="2rem" height="0" />
                        <TagFormat
                          text="มอบตัว เอ็นจีโอเหมยเซ็กซ์สไปเดอร์ ไทยแลนด์แต๋วลิสต์ สเตอริโอโปรดักชั่นตรวจทานจิตเภทพาเหรด"
                          Tag="p"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" className="right_container py-4 px-sm-5 px-4">
                    <form>
                      <Col lg="12">
                        <div>
                          <div>
                            <TagFormat
                              className="input_label"
                              Tag="p"
                              text="อีเมล"
                            />
                            <input
                              className={
                                (errormsg === true && form.email === "") ||
                                (form.email !== "" && isEmailValid === false)
                                  ? "col-12 register_inputField_error"
                                  : "col-12 register_inputField"
                              }
                              placeholder="Enter your email address"
                              onChange={handleChange}
                              name="email"
                            />
                            {((errormsg === true && form.email === "") || (form.email !== "" && isEmailValid === false)) && (
                              <p className="error-message text-danger" style={{fontSize:'15px'}}>Check the email format and ensure it is in lowercase.</p>
                            )}
                          </div>

                          <Spacer height="20px" width={undefined} />
                          <div>
                            <TagFormat
                              className="input_label"
                              Tag="p"
                              text="รหัสผ่าน"
                            />
                            <input
                              type="password"
                              className={
                                errormsg === true && password === ""
                                  ? "col-12 register_inputField_error"
                                  : "col-12 register_inputField"
                              }
                              placeholder="Password"
                              onChange={handleSetPassword}
                              name="password"
                            />
                          </div>
                          <Spacer height="20px" width={undefined} />
                          <div>
                            <TagFormat
                              className="input_label"
                              Tag="p"
                              text="ยืนยันรหัสผ่าน"
                            />
                            <input
                              type="password"
                              className={
                                errormsg === true && matchPassword === ""
                                  ? "col-12 register_inputField_error"
                                  : "col-12 register_inputField"
                              }
                              placeholder="Confirm password"
                              onChange={handleSetMatchPassword}
                              name="confirmPassword"
                            />
                          </div>
                        </div>
                        <Spacer height="1rem" width="" />
                        <div className="password_rule_item">
                          <PasswordChecklist
                            rules={[
                              "lowercase",
                              "capital",
                              "specialChar",
                              "number",
                              "minLength",
                              "match",
                            ]}
                            minLength={8}
                            value={password}
                            valueAgain={matchPassword}
                            onChange={(isValid) => {}}
                            iconComponents={{
                              ValidIcon: (
                                <div
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    borderRadius: "50px",
                                    backgroundColor: "green",
                                    textAlign: "center",
                                    lineHeight: "16px",
                                    fontSize: "10px",
                                  }}
                                >
                                  &#x2713;
                                </div>
                              ),
                              InvalidIcon: (
                                <div
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    borderRadius: "50px",
                                    backgroundColor: "#737576",
                                  }}
                                ></div>
                              ),
                            }}
                            messages={{
                              lowercase:
                                "ตัวอักษรภาษาอังกฤษพิมพ์เล็กอย่างน้อย 1 ตัว",
                              capital:
                                "ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว",
                              specialChar:
                                "ตัวอักขระพิเศษอย่างน้อย 1 ตัว ( @ # $ % ! & ? )",
                              number: "ตัวเลขอย่างน้อย 1 ตัว",
                              minLength:
                                "จำนวนตัวอักษรทั้งหมดไม่น้อยกว่า 8 ตัว",
                              match: "รหัสผ่านและยืนยันการจับคู่รหัสผ่าน",
                            }}
                          />
                        </div>
                        <Spacer height="30px" width="" />
                        <div className="terms_and_condition">
                          <input
                            type="checkbox"
                            id="acceptTerms"
                            checked={isActive}
                            onChange={termsAndCond}
                          />
                          <p className="terms_and_condition_text px-5">
                            ยืนยันเข้าใช้งาน ถือว่าคุณได้ยอมรับ{" "}
                            <u className="pointer">เงื่อนไขการใช้งาน</u>และ
                            <br />
                            รับทราบ
                            <u className="pointer">นโยบายความเป็นส่วนตัว</u> ของ
                            ignitestores.com
                          </p>
                        </div>
                        <Spacer height="20px" width="" />
                        <div className="col-12 register_button_cont text-center">
                          <button
                            type="button"
                            onClick={() => handleSelect(2)}
                            className="col-12 col-lg-10 register_button"
                          >
                            ลงทะเบียน
                          </button>
                          <Spacer height="20px" width="" />
                          <p
                            onClick={() => dispatch(openLoginModal(true))}
                            className="new_account_text cursor-pointer"
                          >
                            เป็นสมาชิกอยู่แล้ว? เข้าสู่ระบบ
                          </p>
                        </div>
                      </Col>
                    </form>
                  </Col>
                </Row>
              </div>
            </Tab>

            <Tab eventKey="2" title="Hii">
              <div className="register-second-page">
                <div className="title">ลงทะเบียนเป็นสมาชิก</div>
                <div className="sub_title"> กรอกข้อมูลส่วนตัว</div>
                <Spacer height="40px" width={undefined} />
                <div>
                  <div>
                    <TagFormat
                      className="field-lable"
                      Tag="p"
                      text="ชื่อที่แสดงในการประมูล"
                    />
                    <input
                      className={
                        errormsg === true && form.username === ""
                          ? "col-12 register_inputField_error"
                          : "col-12 register_inputField"
                      }
                      placeholder=""
                      onChange={handleChange}
                      name="username"
                    />
                  </div>
                  <div className="mt-1">
                    <img
                      src={triangless}
                      className="mx-2"
                      style={{ width: "16px", height: "16px" }}
                      alt=""
                    />
                    <span className="warning_msg">
                      หากยืนยันที่อยู่เสร็จแล้ว จะไม่สามารถเปลี่ยนได้ภายหลัง
                    </span>
                  </div>
                  <Spacer height="40px" width={undefined} />
                  <div>
                    <TagFormat className="field-lable" Tag="p" text="ชื่อ" />
                    <input
                      className={
                        errormsg === true && form.name === ""
                          ? "col-12 register_inputField_error"
                          : "col-12 register_inputField"
                      }
                      placeholder=""
                      onChange={handleChange}
                      name="name"
                    />
                  </div>
                  <Spacer height="20px" width={undefined} />
                  <div>
                    <TagFormat className="field-lable" Tag="p" text="นามสกุล" />
                    <input
                      className={
                        errormsg === true && form.lastName === ""
                          ? "col-12 register_inputField_error"
                          : "col-12 register_inputField"
                      }
                      placeholder=""
                      onChange={handleChange}
                      name="lastName"
                    />
                  </div>
                  <div className="mt-1">
                    <img
                      src={triangless}
                      className="mx-2"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <span className="warning_msg">
                      หากยืนยันที่อยู่เสร็จแล้ว จะไม่สามารถเปลี่ยนได้ภายหลัง
                    </span>
                  </div>
                </div>
                <Spacer height="40px" width={undefined} />

                <div className="">
                  <TagFormat className="field-lable" Tag="p" text="เพศ" />
                  {/* <Dropdown onSelect={chooseGender}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {gender}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="ชาย" href="#/action-1">
                        ชาย
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="หญิง" href="#/action-2">
                        หญิง
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="ไม่ระบุเพศ" href="#/action-3">
                        ไม่ระบุเพศ
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <select
                    id="dropdown"
                    value={gender}
                    onChange={chooseGender}
                    className={
                      errormsg === true && form.gender === ""
                        ? "col-12 register_inputField_error"
                        : "col-12 register_inputField"
                    } // Conditionally apply the 'invalid' class
                  >
                    <option value="">เลือกเพศของคุณ ..</option>
                    <option value="1">ชาย</option>
                    <option value="2">หญิง</option>
                    <option value="3">ไม่ระบุเพศ</option>
                  </select>
                </div>
                <Spacer height="20px" width={undefined} />
                <div>
                  <TagFormat className="field-lable" Tag="p" text="วันเกิด" />

                  <Form.Control
                      className={
                        errormsg === true && form.birthDay === undefined
                          ? "col-12 custom-date-input register_inputField_error"
                          : "col-12 custom-date-input register_inputField"
                      }

                      type="date"
                      value = {birthDay}
                      onChange={(e) => {
                        dataPicker(e);
                      }}
                      defaultValue={getCurrentDate()}
                     
  
                    />

                  {/* <DatePicker
                    className={
                      errormsg === true && form.birthDay === undefined
                        ? "col-12 register_inputField_error"
                        : "col-12 register_inputField"
                    }
                    selected={birthDay}
                    onChange={dataPicker}
                  /> */}
                </div>

                <Spacer height="30px" width="" />
                <button className="submit-btn" onClick={onSubmitForm}>
                  ยืนยันข้อมูล
                </button>
              </div>
            </Tab>

            <Tab eventKey="3" title="Hiisds">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={envelop}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <div className="sub_title my-5">ยืนยันอีเมลของคุณ</div>

                <p className="text-center my-4 content-style">
                  ระบบได้ส่งรหัสผ่านการสมัครเข้า <br /> เมลที่คุณสมัคร{" "}
                  {forgotEmail === "" ? form.email : forgotEmail}
                </p>

                <p className="text-center my-4 content-style">
                  ข้อความอีเมลจะเป็นหลักฐาน <br />{" "}
                  ในการทำธุระกรรมต่างๆที่เกิดขึ้นในการประมูล
                </p>

                <Spacer height="30px" width="" />
                <button className="submit-btn" onClick={() => setTabSelect(4)}>
                  ยืนยันข้อมูล
                </button>

                {/* <div className="content-style text-center my-5">
                  {" "}
                  ยังไม่ได้อีเมลยืนยัน <u onClick={resendMailOTP}>ส่งใหม่อีกครั้ง</u>{" "}
                </div> */}
              </div>
            </Tab>

            <Tab eventKey="4" title="Hiisdssdsas">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={envelop}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <div className="sub_title my-5">กรอกรหัสจากอีเมล</div>

                <p className="text-center my-4 content-style">
                  โปรดกรอกรหัสเพื่อยืนยันอีเมล
                </p>
                <Spacer height="30px" width="" />
                <input
                  type="text"
                  className={
                    errormsg === true
                      ? "col-12 register_inputField_error text-center"
                      : "col-12 register_inputField text-center"
                  }
                  placeholder=""
                  name="otp"
                  maxLength={5}
                  onKeyDown={(event) => {
                    if (
                      !/[0-9]/.test(event.key) &&
                      event.key !== "Backspace" &&
                      event.key !== "Delete" &&
                      event.key !== "ArrowRight" &&
                      event.key !== "ArrowLeft"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChangeEmailOtp}
                />

                <Spacer height="30px" width="" />
                <button
                  className="submit-btn"
                  type="submit"
                  onClick={
                    forgotEmail === ""
                      ? VerifyEmailOTP
                      : VerifyEmailOTPForForgot
                  }
                >
                  ยืนยันข้อมูล
                </button>

                <div className="content-style text-center my-5">
                  {" "}
                  ยังไม่ได้อีเมลยืนยัน{" "}
                  <u
                  className="pointer" 
                  onClick={
                    forgotEmail === ""
                      ? resendMailOTP
                      : handleForgotEmail
                  }
                  >ส่งใหม่อีกครั้ง</u>{" "}
                </div>
              </div>
            </Tab>

            <Tab eventKey="5" title="Hiisds">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={envelop}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <div className="sub_title my-5">ยืนยันอีเมลเสร็จสิ้น</div>

                <p className="text-center my-4 content-style">
                  ข้อความอีเมลจะเป็นหลักฐาน <br />{" "}
                  ในการทำธุระกรรมต่างๆที่เกิดขึ้นในการประมูล
                </p>

                <Spacer height="30px" width="" />
                <button
                  className="submit-btn mt-5"
                  onClick={() => setTabSelect(6)}
                >
                  ไปที่หน้ายืนยันเบอร์โทรศัพท์
                </button>
              </div>
            </Tab>

            <Tab eventKey="6" title="Hiisdssdsas">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={phone}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <div className="sub_title my-5">ยืนยันเบอร์โทรศัพท์</div>

                <p className="text-center my-4 content-style">
                  เพื่อความโปรดภัยในการใช้งาน และทำธุรกรรมทางการเงิน <br />{" "}
                  โปรดยืนยันเบอร์โทรศัพท์ของท่าน
                </p>
                <Spacer height="30px" width="" />
                <TagFormat
                  className="field-lable"
                  Tag="p"
                  text="เบอร์โทรศัพท์"
                />
                <input
                  className={
                    errormsg === true
                      ? "col-12 register_inputField_error text-center"
                      : "col-12 register_inputField text-center"
                  }
                  placeholder=""
                  name="phone"
                  onChange={handleChangeMobileForm}
                />

                <Spacer height="30px" width="" />
                <button className="submit-btn" onClick={sendMobileOTP}>
                  ยืนยัน
                </button>
              </div>
            </Tab>

            <Tab eventKey="7" title="Hiisdssdsas">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={phone}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <div className="sub_title my-5">ยืนยันเบอร์โทรศัพท์</div>

                <p className="text-center my-4 content-style text-center">
                  โปรดกรอกรหัส OTP ทาง SMS
                </p>
                <Spacer height="30px" width="" />
                <input
                  className={
                    errormsg === true
                      ? "col-12 register_inputField_error text-center"
                      : "col-12 register_inputField text-center"
                  }
                  placeholder=""
                  name="otp"
                  maxLength={6}
                  onChange={handleChangeMobileOTPForm}
                />

                <Spacer height="30px" width="" />
                <button className="submit-btn" onClick={VerifyMobileOTP}>
                  ยืนยัน
                </button>

                <div className="content-style text-center my-5">
                  {" "}
                  ไม่ได้ SMS{" "}
                  <u onClick={() => setTabSelect(6)}>ลองใหม่อีกครั้ง</u>{" "}
                </div>
              </div>
            </Tab>

            <Tab eventKey="8" title="Hiisdsas">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={screenCheck}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                    alt=''
                  />
                </div>

                <div className="sub_title my-5">ลงทะเบียนเสร็จสมบูรณ์</div>

                <p className="text-center my-4 content-style">
                ขอบคุณที่ร่วมลงทะเบียนกับเรา ของให้มีความสุขกับการประมูลสินค้า คุณภาพจากเรา
                </p>

                <Spacer height="30px" width="" />
                <Link to="/">
                  {" "}
                  <button className="submit-btn my-5">กลับสู่หน้าหลัก</button>
                </Link>
              </div>
            </Tab>
            <Tab eventKey="9" title="Hiisdssdsas">
              <div className="register-second-page">
                <div className="text-center">
                  <img
                    src={envelop}
                    className="mx-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <div className="sub_title my-5">ยืนยันอีเมลของคุณ</div>

                <p className="text-center my-4 content-style">
                  โปรดกรอกอีเมลที่คุณสมัคร <br />
                  เพื่อใช้ในการรีเซตรหัสผ่านของคุณ
                </p>
                <Spacer height="30px" width="" />
                <input
                  className={
                    errormsg === true
                      ? "col-12 register_inputField_error text-center"
                      : "col-12 register_inputField text-center"
                  }
                  placeholder=""
                  name="forgotemail"
                  onChange={forgothandleChange}
                  value={forgotEmail}
                />

                <Spacer height="30px" width="" />
                <button
                  className="submit-btn"
                  type="submit"
                  onClick={handleForgotEmail}
                >
                  ยืนยัน
                </button>

                {/* <div className="content-style text-center my-5">
                  {" "}
                  ยังไม่ได้อีเมลยืนยัน{" "}
                  <u onClick={resendMailOTP}>ส่งใหม่อีกครั้ง</u>{" "}
                </div> */}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
