import "../../components/component.scss";
import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import ApiService from "../../config/api";
import { toast } from "react-toastify";

const ChangeUserNameModal = ({ isOpen, isClose, userName }: any) => {
  const dispatch = useDispatch();

  const [oldusername, setOldusername] = useState("");
  const [newUsername, setNewUsername] = useState("");

  const closeModal = () => {
    isClose();
  };

  const validationSet = () => {
    return newUsername.trim() !== "";
  };

  useEffect(() => {
    setOldusername(userName?.userDetails?.username);
  }, [userName]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUsername(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = validationSet();
    if (isValid) {
      dispatch(showLoader());
      try {
        await ApiService.post("user/changeUserName", {
          username: newUsername,
        });
        closeModal();
        setNewUsername('');
        dispatch(hideLoader());
      } catch (error) {
        console.error("Error while changing username:", error);
        dispatch(hideLoader());
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.warning("Please fill the form fields!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={isOpen}>
      <img src={close} alt="" onClick={closeModal} />
      <div className="col-md-12 payment-modal">
        <div className="cnt-container">
          <div className="cnt mt-4 text-center">เปลี่ยนชื่อแสดงผลในระบบ</div>
          <div className="input-cont first-input-cont mt-5">
            <label>ชื่อแสดงผลในระบบ</label>
            <div className="input1">
              <input
                type="text"
                defaultValue={oldusername}
                readOnly
                name="oldusername"
                className="col-12 register_inputField"
              />
            </div>
          </div>
          <div className="input-cont mt-2">
            <label>ชื่อแสดงผลในระบบใหม่</label>
            <div className="input1">
              <input
                type="text"
                value={newUsername}
                onChange={handleInputChange}
                name="newUsername"
                className="col-12 register_inputField"
              />
            </div>
          </div>
          <button className="mt-5 register_button" onClick={handleSubmit}>
            เปลี่ยนชื่อแสดงผล
          </button>
          <button className="mt-4" onClick={closeModal}>
            ยกเลิก
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeUserNameModal;
