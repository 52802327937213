import React from "react";
import "./main.scss";
import Home from "../pages/Home";
import FooterSecondary from "./footerSecondary";

const Main = () => {
  return (
    <div className="bg-dark main-container-bg">
      <Home />
      <FooterSecondary />
    </div>
  );
};

export default Main;
