import { TagFormat } from "../../../components";
import Spacer from "../../../components/Spacer";
import "../styles.scss";

const ItemsOfAction = () => {
  return (
    <div className="terms-of-use">
      <div className="max-width py-2">
        <TagFormat
          Tag="h3"
          className="aboutus-title"
          text="แจ้งปัญหาการใช้งาน"
        />
        <div>
          <p>
          กรอก
          </p>
        </div>
      </div>
    </div>
  );
};

export default ItemsOfAction;
