import React from "react";
import "./component.scss";

const SendItemSection = () => {
  return (
    <div className="max-width col-md-12 my-5 send-item-section">
      <div className="header py-5">สนใจนำสินค้าเข้าร่วมประมูล</div>
      <div className="content">
        ไคลแมกซ์บอดี้มอคค่าเซฟตี้ฮิ อิมพีเรียลสต๊อค <br /> ปฏิสัมพันธ์ทับซ้อนแซว
        คอมพ์ เทควันโดไคลแมกซ์อพาร์ตเมนท์สะบึม <br /> ร็อคจังโก้ซินโดรม
        ปิกอัพบูติค<br />ชาร์จ สเปกสปาฮัลโลวีน โจ๋เอสเปรส
      </div>

      <div className="send-item-section-btn my-5">ติดต่อลงสินค้าประมูล</div>
    </div>
  );
};

export default SendItemSection;
