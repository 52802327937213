import React, { useState, useEffect } from "react";

const BiddingCounter = (props: any) => {
  const auctionclosedatetime = props?.data?.auctionclosedatetime;
  const auctionstartdatetime = props?.data?.auctionstartdatetime;

  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const startDateTime = new Date(auctionstartdatetime).getTime();
      const endTime = new Date(auctionclosedatetime).getTime();

      let diff;
      if (now < startDateTime) {
        diff = Math.max(startDateTime - now, 0);
      } else {
        diff = Math.max(endTime - now, 0);
      }

      const durationInSeconds = Math.floor(diff / 1000);
      setDuration(durationInSeconds);

      // Clear interval when the end date is reached
      if (now >= endTime) {
        clearInterval(intervalId);
        setDuration(0); // Reset duration to 0 when the end date is reached
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [auctionclosedatetime, auctionstartdatetime]);

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    if (typeof hours === "number" && isNaN(hours) || props?.data?.auctionstatus == 'CLOSED') {
      return `0h : 0m : 0s`;
    } else {
      return `${hours}h : ${minutes}m : ${remainingSeconds}s`;
    }
  };

  return (
    <>
      <div className="time">{formatDuration(duration)}</div>
    </>
  );
};

export default BiddingCounter;