import React from "react";
import "./main.scss";
import Home from "../pages/Home";
import FooterSecondary from "./footerSecondary";
import Preview from "../pages/Home/preview";

const PreviewPage = () => {
  return (
    <div className="bg-dark main-container-bg">
      <Preview />
      <FooterSecondary />
    </div>
  );
};

export default PreviewPage;
