import "./component.scss";
import emptyImg from '../assets/Image-Hero-Banner.png'
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
import "../layout/main.scss";

const CarouselScreen = (props: any) => {
  const sliderData = props.datas;
  const [index, setIndex] = useState(0);
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };

  const redirectToNewTab = (url: any) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const getImage = (list: any, type: any) => {
    if (list && list.length) {
      if (type === "mobile") {
        const img = list.find((val: any) => val.filecat === "mobile");
        return img ? img.filepath : list[0].filepath;
      } else {
        const img = list.find((val: any) => val.filecat !== "mobile" || val.filecat == null);
        return img ? img.filepath : list[0].filepath;
      }
    }
    return null;
  };

  return (
    <div className="mt-5 px-0 dk-carousel mb-5" style={{ cursor: 'pointer' }}>
      <Carousel controls={false} activeIndex={index} onSelect={handleSelect}>
        {sliderData &&
          sliderData.map((slide: any, i: number) =>
          slide.filelist
          .filter((file: any) => file.filecat === 'desktop')
          .map((file: any, key: number) => (
            <Carousel.Item key={`${slide.id}-${key}`} onClick={() => redirectToNewTab(slide?.bannerurl)}>
              <img className="w-100 desktop-image" src={file.filepath} alt="" />
              {/* <img className="w-20 mobile-image" src={getImage(slide.filelist, "mobile")} alt="" /> */}
              <Carousel.Caption>
                <h3>{slide.bannertopic}</h3>
                <p>{file.bannermessage}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))
          )}
      </Carousel>
    </div>
  );
};

export default CarouselScreen;
