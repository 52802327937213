import ApiService from "../../config/api";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { useDispatch } from "react-redux";
import ProductCardOne from "../../components/ProductCardOne";
import ProductCardTwo from "../../components/ProductCardTwo";
import WhyUs from "../../components/WhyUs";
import "../../components/component.scss";
import ".././page.scss";
import { useParams } from "react-router-dom";
import FooterSecondary from "../../layout/footerSecondary";
import { Link } from 'react-router-dom';

const Product = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [responseValue, setResponseValue] = useState<any>();

  useEffect(() => {
    getProductsApi();
  }, []);


  const getProductsApi = async () => {
    dispatch(showLoader());
    const payload = { bannerId: id };
    const responseVal = await ApiService.post("/banner/detail", payload);
    const data = await responseVal?.response;
    if (responseVal.status === "SUCCESS") {
      setResponseValue(data);
      dispatch(hideLoader());
    } else {
      setError(responseVal.response);
      dispatch(hideLoader());
    }
  };
  return (
    <div className="product-highlight main-container-bg">
      <div>
        <div className="hilight-header">
        <div className="max-width header-title p-3">
          <div className="title">{responseValue?.bannertopic}</div>
          <Link to={responseValue?.bannerurl}>
            <button>เข้าร่วมประมูล</button>
          </Link>
        </div>
        </div>
        <div className="row ProductCardOne mx-0 justify-content-center">
          <ProductCardOne productItem={responseValue} />
        </div>

        <div className="p-3">
          <ProductCardTwo productItem={responseValue} />
        </div>

        <div className="py-5 text-center hightlight-footer-btn-cont">
        <Link to={responseValue?.bannerurl}>
          <button className="hightlight-footer-btn">เข้าร่วมประมูล</button>
        </Link>
        </div>
        <div className="max-width">
        <WhyUs />
        </div>
    
        <FooterSecondary />
      </div>
    </div>
  );
};
export default Product;
