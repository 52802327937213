import { TagFormat } from "../../components";

const ProductWarranty = () => {
  return (
    <div className="terms-of-use px-3">
      <div className="max-width">
        <TagFormat
          Tag="h3"
          className="aboutus-title"
          text="การรับประกันสินค้า"
        />
        <div className="pb-5">
          <p>
          1. ความเสียหายอันเกิดจากความบกพร่อง หรือผิดพลาดจากโรงงานผู้ผลิต ทางบริษัทฯรับประกันการซ่อมและเปลี่ยนอะไหล่ให้ฟรี โดยไม่คิดมูลค่าภายในระยะเวลา 6 เดือน นับจากวันที่ซื้อสินค้า
          </p>

          <p>
          2. การรับประกันนี้ไม่ครอบคลุมถึงความชำรุดเสียหาย ในกรณีดังต่อไปนี้

          </p>

          <p className="px-4">
            a. การซ่อม ดัดแปลง แก้ไข โดยบุคคลอื่น ซึ่งไม่ใช่ช่างของบริษัท
          </p>

          <p className="px-4">
          b. การใช้สินค้าผิดประเภท หรืออุบัติเหตุ การขนส่งความประมาทเกี่ยวกับการรักษาผิดวิธี ภัยธรรมชาติ ฯลฯ
          </p>

          <p className="px-4">
          c. การต่อสายพ่วงที่ยาวเกินไป การใช้สายไฟที่เล็ก หรือยาวเกินไป และแรงดันไฟฟ้าต่างจากที่ระบุ หรือเกิดไฟตกไฟเกิน ฯลฯ
          </p>

          <p>
          3. การรับประกันนี้ไม่รวมค่าขนส่งและค่าเดินทาง การที่ลูกค้ามีความประสงค์จะให้บริการ ณ สถานที่ใช้งาน บริษัทฯ จะต้องคิดค่าให้บริการ
          </p>

          <p>
          4. บริษัทฯ จะยกเลิกการรับประกันทันที เมื่อบัตรรับประกันถูกขีดฆ่า หรือแก้ไข อันทำให้บัตรรับประกันนี้มีข้อความไม่ครบหรือข้อมูลที่เปลี่ยนแปลงไม่สมบูรณ์
          </p>

          <p>
          5. บัตรรับประกันนี้ใช้เฉพาะเครื่่องมือที่ถูกระบุในบัตรนี้เท่านั้น
          </p>

          <p>
          6. บัตรรับประกันนี้ใช้เฉพาะกับผู้ซื้อที่ระบุอยู่ในบัตรเท่านั้น ไม่สามารถโอนสิทธิ์ เปลี่ยนมือให้ผู้อื่นได้
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductWarranty;
