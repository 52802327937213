import { createSlice } from "@reduxjs/toolkit";

export const HeaderFooterSlice = createSlice({
  name: "headerFooter",
  initialState: { headerFooter: true },
  reducers: {
    showHeaderFooter: (state:any) => {
      state.headerFooter = true;
    },
    hideHeaderFooter: (state:any) => {
      state.headerFooter = false;
    },
  },
});
// Action creators
export const { showHeaderFooter, hideHeaderFooter } = HeaderFooterSlice.actions;
export const HeaderFooterStatus = (state: any) => state.headerFooter;
export default HeaderFooterSlice.reducer;
