import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TagFormat } from ".";
import Spacer from "./Spacer";
import close from "../assets/icon/close-white.svg";
import {
  logInStart,
  logInSuccess,
  openLoginModal,
} from "../store/login/actions";
import { useDispatch, useSelector } from "react-redux";
import close_icon from "../assets/icon/close-circle.svg";
import { useNavigate } from "react-router-dom";
import ApiService from "../config/api";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";

const ModalComponent = ({ isOpen }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [errormsg, setErrormsg] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;

  const [isEmailValid, setValidEmail] = useState(true);
  const validateEmail = () => {
    setValidEmail(emailRegex.test(form.email));
  };

  const [isLoginFail, setIsLoginFail] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
    issystemuser:"N",
  });
  const handleChange = (e: any) => {
    setErrormsg(false)
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
    validateEmail();
  };
  const termsAndCond = (event: any) => {
    setIsActive(!isActive);
  };
  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    dispatch(showLoader());
    if (
      form.email === "" ||
      form.password === "" ||
      isActive === false ||
      isEmailValid === false
    ) {
      dispatch(hideLoader());
      setErrormsg(true);
    } else {
      setErrormsg(false);

      const responseData = await ApiService.post("/user/auth/signIn", form);
      if (responseData?.status === "SUCCESS") {
        localStorage.setItem("user", JSON.stringify(responseData));
        localStorage.setItem(
          "access_token",responseData?.response?.accessToken
        );
        dispatch(logInSuccess(responseData));
        dispatch(hideLoader());
        dispatch(openLoginModal(false));
        navigate("/",{ replace: true });
      } else {
        if(responseData?.status === "FAILURE" && responseData?.message === "invalid-mail"){
            setIsLoginFail(true);
            dispatch(hideLoader());
        } else {
          setErrormsg(true);
          dispatch(hideLoader());
        }
      }
    }
  };

  const goBack = (e: any) => {
    setIsLoginFail(false);
  };

  const closeModal = (event: any) => {
    setIsLoginFail(false);
    dispatch(openLoginModal(false));
  };
  const handleForgotPassword = () => {
    navigate('/Register',{state:9, replace:true});
    dispatch(openLoginModal(false));
  }
  const handleRegister = () => {
    navigate('/Register');
    dispatch(openLoginModal(false));
  }
  return (
    <>
      <Modal show={isOpen}>
        <img src={close} alt="" onClick={closeModal} />
        {isLoginFail ? (
          <div className="col-md-12 mt-4 login-error-modal">
            <div className="text-center">
              <img src={close_icon} className="close-img" alt=""></img>
              <div className="content my-5">เมลของคุณไม่สามารถใช้ได้!</div>
              <div className="sub-cnt">
                โปรดติดต่อเจ้าหน้าที่ เพื่อทำการปลดล็อคเมลของคุณ โปรดติดต่อที่{" "}
                <u>ignite.auction@pkn.co.th</u>
              </div>
              <button className="mt-5" onClick={goBack}>
                ย้อนกลับ
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="modal-title">เข้าสู่ระบบ</p>
            <Spacer height="30px" width="" />
            <form>
              <div>
                <div>
                  <TagFormat className="input_label" Tag="p" text="อีเมล" />
                  <input
                    className={
                      (errormsg === true && form.email === "") ||
                      (form.email !== "" && isEmailValid === false) ||
                      (errormsg === true)
                        ? "col-12 register_inputField_error"
                        : "col-12 register_inputField"
                    }
                    onChange={handleChange}
                    name="email"
                  />
                </div>
                {errormsg === true && (<p className="pt-1 input_error_text">ไม่พบที่อยู่อีเมลในระบบ</p>)}
                <Spacer height="20px" width={undefined} />
                <div>
                  <div>
                  <TagFormat className="" Tag="p" text="รหัสผ่าน" />
                  <input
                    className={
                      (errormsg === true && form.password === "") || (errormsg === true)
                        ? "col-12 register_inputField_error"
                        : "col-12 register_inputField"
                    }
                    onChange={handleChange}
                    name="password"
                    type="password"
                  />
                  </div>
                  {errormsg === true && (<p className="pt-1 input_error_text">รหัสผ่านไม่ถูกต้อง</p>)}
                </div>
              </div>

              <Spacer height="30px" width="" />
              <div className="terms_and_condition">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  checked={isActive}
                  onChange={termsAndCond}
                />
                <label
                  htmlFor="acceptTerms"
                  className="terms_and_condition_text"
                >
                  ยืนยันเข้าใช้งาน ถือว่าคุณได้ยอมรับ{" "}
                  <span>
                    <a href="/TermsOfUse">
                      เงื่อนไขการใช้งาน
                    </a>
                  </span> และรับทราบ 
                  <span>
                    <a href="/PrivacyPolicy">
                     นโยบายความเป็นส่วนตัว 
                    </a>
                  </span> 
                   ของ ignitestores.com
                </label>
              </div>
              <Spacer height="30px" width="" />
              <div className="col-12 register_button_cont text-center">
                <button
                  className="col-12 register_button"
                  onClick={onSubmitForm}
                >
                  เข้าสู่ระบบ
                </button>

                <Spacer height="30px" width="" />
                <p onClick={handleRegister} className="register-member">ลงทะเบียนเป็นสมาชิก</p>
                <Spacer height="30px" width="" />
                <p onClick={handleForgotPassword} className="forgot-account">ลืมบัญชีใช้งาน ?</p>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </>
  );
};
export default ModalComponent;
