import React, { useState, useEffect } from "react";
import heartFull from "../assets/icon/heart-full-red.svg";
import heart from "../assets/icon/heart.svg";

import "./component.scss";
import { Link } from "react-router-dom";
import { addFavourite } from "../controller/FavouriteFunction";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";
import { toast } from "react-toastify";
import { openLoginModal } from "../store/login/actions";
import { url } from "inspector";
import { BASE_URL_IMG } from "../config/api";
import moment from "moment";
import BiddingCounter from "./biddingCounter";
import { getBiddingCounterText } from "../services/Utils";
import BiddingOrderIcon from "./BiddingOrderIcon";

const CardTwo = (props: any) => {
  const dispatch = useDispatch();
  const hotItems = props?.datas[0];
  const sessionData = useSelector((state: any) => state.login);
  const [error, setError] = useState(null);

  const userDetail:any = localStorage.getItem('user');

  const handleFavourite = async (event: any, productId: any) => {
    event.preventDefault();
    if (sessionData.isAuthenticated) {
      dispatch(showLoader());
      const response = await addFavourite(productId);
      if (
        response?.status === true &&
        response?.response?.status === "SUCCESS"
      ) {
        hotItems.is_favourite === 1
          ? (hotItems.is_favourite = 0)
          : (hotItems.is_favourite = 1);
        hotItems.like_count = response.response.like_count;
      } else {
        hotItems.is_favourite = 0;
      }
      dispatch(hideLoader());
    } else {
      dispatch(openLoginModal(true));
    }
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <>
      {hotItems && (
        <Link to={`/productdetails?productId=${hotItems.product_id}`}>
          <div className="col-md-12 mt-4 card-three highlight-mobile-view">
            <div className="top position-relative">
            {(hotItems?.product_purchased_by == null || hotItems?.product_purchased_by == userDetail?.userId) &&  (
            <BiddingOrderIcon bid_order={hotItems?.bid_order} productsData={hotItems} userId={userDetail?.userId}></BiddingOrderIcon>
            )}
              <img src={hotItems?.filepath} alt="" />
            </div>
            <div className="main_container">
              <div className="row mx-0 mt-3">
                <div className="col-6">
                  <div className="no-txt">No. {hotItems.runningno}</div>
                  <div className="name">{hotItems.name}</div>
                </div>
                <div
                  className="col-6"
                >
                  <div className="like_cnt" onClick={(e) => handleFavourite(e, hotItems.product_id)}>
                    <img
                      className="like_img"
                      src={hotItems.is_favourite === 1 ? heartFull : heart}
                      alt=""
                    />
                    <span className="like_count">{hotItems.like_count}</span>
                  </div>
                </div>
              </div>
              <div className="row mx-0 my-4">
                <div className="col-6">
                  <div className="amount-txt">ราคาประมูลล่าสุด</div>
                  <div className="amount">
                    {hotItems.latestbiddingamount
                      ? hotItems.latestbiddingamount
                      : hotItems.minbidamount}{" "}
                    ฿
                  </div>
                </div>
                <div className="col-6 text-right">
                  <div className="time-txt">
                    {getBiddingCounterText(
                      hotItems?.auctionstartdatetime,
                      hotItems?.auctionclosedatetime
                    )}
                  </div>
                  <BiddingCounter data={hotItems} />
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-12 mt-4 card-three highlight-disktop-view">
            <div className="top position-relative" style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url("${hotItems?.filepath}")`
                        }}>
                          
                          {(hotItems?.product_purchased_by == null || hotItems?.product_purchased_by == userDetail?.userId) &&  (
            <BiddingOrderIcon bid_order={hotItems?.bid_order} productsData={hotItems} userId={userDetail?.userId}></BiddingOrderIcon>
            )}
              {/* <img src={hotItems?.filepath} alt="" /> */}
              {/* <img
                src={
                  hotItems?.blobcontent === null
                    ? hotItems
                    : `data:${hotItems?.filetype};base64,${hotItems?.blobcontent}`
                }
                alt=""
              /> */}


      <div className="main_container">
              <div className="row mx-0 mt-3">
                <div className="col-6">
                  <div className="no-txt">No. {hotItems.runningno}</div>
                  <div className="name">{hotItems.name}</div>
                </div>
                <div
                  className="col-6"
                >
                  <div className="like_cnt" onClick={(e) => handleFavourite(e, hotItems.product_id)}>
                    <img
                      className="like_img"
                      src={hotItems.is_favourite === 1 ? heartFull : heart}
                      alt=""
                    />
                    <span className="like_count">{hotItems.like_count}</span>
                  </div>
                </div>
              </div>
              <div className="row mx-0 my-2">
                <div className="col-6 my-1">
                  <div className="amount-txt">ราคาประมูลล่าสุด</div>
                  <div className="amount">
                    {hotItems.latestbiddingamount
                      ? hotItems.latestbiddingamount
                      : hotItems.minbidamount}{" "}
                    ฿
                  </div>
                </div>
                <div className="col-6 text-right my-1">
                  <div className="time-txt">
                    {getBiddingCounterText(
                      hotItems?.auctionstartdatetime,
                      hotItems?.auctionclosedatetime
                    )}
                  </div>
                  <BiddingCounter data={hotItems} />
                </div>
              </div>
            </div>

            </div>
            
          </div>
          
        </Link>
      )}
    </>
  );
};

export default CardTwo;
