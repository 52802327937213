import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loader",
  initialState: { loading: false },
  reducers: {
    showLoader: (state:any) => {
      state.loading = true;
    },
    hideLoader: (state:any) => {
      state.loading = false;
    },
  },
});
// Action creators
export const { showLoader, hideLoader } = loaderSlice.actions;
export const loaderStatus = (state: any) => state.loading;
export default loaderSlice.reducer;
