import { TagFormat } from "../../../components";
import Spacer from "../../../components/Spacer";
import '../styles.scss'
const TermsOfUse = () => {
  return (
    <div className="terms-of-use">
      <div className="max-width py-2">
        <TagFormat
          Tag="h3"
          className="aboutus-title"
          text="เงื่อนไขการใช้งาน"
        />
        <div>
          <p>
            ข้อกำหนดการใช้งานทั่วไปของ ignitestores.com
            ข้อกำหนดการใช้งานทั่วไปเหล่านี้และเอกสารอื่น ๆ ที่เราอ้างถึงในข้อ 1
            (รวมกันเรียกว่า 'ข้อกำหนดการใช้งาน')
            ใช้กับทุกวิถีทางของการใช้ตลาดออนไลน์ของเรา (ตามที่กำหนดไว้ในข้อ 2)
            โดยการเข้าถึงหรือใช้ตลาดออนไลน์ของเรา
            คุณตกลงที่จะผูกพันตามข้อกำหนดการใช้งาน
            โปรดอ่านอย่างละเอียดเพื่อให้คุณทราบถึงสิทธิ์และหน้าที่ของคุณเมื่อคุณใช้ตลาดออนไลน์ของเรา
          </p>
          <Spacer height={20} width={0} />
          <p>
            หากคุณต้องการอ่านข้อกำหนดการใช้งานเหล่านี้เป็นภาษาญี่ปุ่น
            โปรดคลิกที่นี่
            หากมีข้อขัดแย้งระหว่างข้อกำหนดการใช้งานฉบับภาษาอังกฤษกับฉบับภาษาอื่น
            ให้ยึดฉบับภาษาอังกฤษเป็นหลัก
          </p>
          <Spacer height={20} width={0} />
          <p>
            <u>ข้อ 1: นโยบายที่เกี่ยวข้องอื่นๆ ของเรา</u>
          </p>
          <Spacer height={20} width={0} />
          <p>
            หากคุณขายหรือซื้อล็อตในตลาดออนไลน์ของเรา
            เอกสารต่อไปนี้จะมีผลบังคับใช้ด้วย
            เอกสารเหล่านี้เป็นส่วนสำคัญของเงื่อนไขการใช้งานของเรา
            และโดยการใช้ตลาดออนไลน์ของเรา
            แสดงว่าคุณตกลงที่จะผูกพันตามเอกสารเหล่านี้ หากมีข้อขัดแย้งใดๆ
            ระหว่างข้อกำหนดการใช้งานทั่วไปเหล่านี้กับข้อกำหนดของผู้ขาย
            ข้อกำหนดของผู้ซื้อ หรือนโยบาย
            ให้ยึดข้อกำหนดของเอกสารเฉพาะเหล่านั้นเป็นหลัก
          </p>
          <Spacer height={20} width={0} />
          <p>
            ข้อกำหนดผู้ขาย หากคุณส่งล็อตสำหรับการประมูลในตลาดออนไลน์ของเรา
            จะมีการใช้กฎเฉพาะสำหรับผู้ขาย อ่านได้ที่นี่ ข้อกำหนดของผู้ซื้อ
            หากคุณซื้อล็อตในตลาดออนไลน์ของเรา จะใช้กฎเฉพาะสำหรับผู้ซื้อ
            อ่านได้ที่นี่ นโยบาย เพื่อให้ตลาดออนไลน์ของเราปลอดภัยและเชื่อถือได้
            เรามีนโยบายหลายอย่างที่ใช้กับทุกการใช้งานตลาดออนไลน์ของเรา (เช่น
            นโยบายคำติชม นโยบายสิทธิ์ในการถอนของสหภาพยุโรป
            และนโยบายผู้ขายมืออาชีพ) คุณสามารถดูนโยบายของเราได้ที่นี่
          </p>
          <Spacer height={20} width={0} />
          <p>
            <u>ข้อ 2: การบังคับใช้ข้อกำหนดการใช้งาน</u>
          </p>
          <Spacer height={20} width={0} />
          <p>
            ข้อกำหนดการใช้งานของเราใช้กับข้อตกลงทั้งหมดและความสัมพันธ์ทางกฎหมายทั้งหมดระหว่าง
            ignitestores.com B.V. ('ignitestores.com', 'เรา' หรือ 'เรา') กับคุณ
            ด้วยเหตุนี้ จึงเป็นส่วนหนึ่งของข้อตกลงที่มีผลผูกพันทางกฎหมาย
            ("ข้อตกลง") ระหว่างเรากับคุณ
            สิ่งเหล่านี้ใช้กับการเข้าถึงและการใช้เว็บไซต์ ignitestores.com
            ของคุณ รวมถึงหน้าย่อยและโดเมนย่อยของเว็บไซต์ แอปพลิเคชันมือถือ
            แท็บเล็ต และอุปกรณ์อัจฉริยะอื่นๆ อินเทอร์เฟซโปรแกรมแอปพลิเคชันของเรา
            (รวมเรียกว่า 'แอปพลิเคชัน') และทั้งหมด
            บริการที่เกี่ยวข้องที่เสนอผ่านเว็บไซต์และแอปพลิเคชันของเรา
            (การเข้าถึง การใช้งาน และบริการที่เกี่ยวข้องเรียกรวมกันว่า 'บริการ'
            ของเรา) เรายังอาจอ้างถึงแอปพลิเคชันและบริการของเราว่าเป็น
            'ตลาดออนไลน์' ของเรา นอกจากนี้
            เพื่อให้ตลาดออนไลน์ของเราทำงานอย่างปลอดภัยและเป็นระเบียบ
            โดยการยอมรับข้อกำหนดการใช้งาน คุณตกลงว่าสัญญาซื้อขายมาตรฐาน
            ("สัญญาซื้อขาย") จะเป็นส่วนหนึ่งของสัญญาซื้อขายระหว่าง
            ผู้ซื้อและผู้ขายเกี่ยวกับทุกการซื้อและการขายที่ทำผ่านตลาดออนไลน์ของเรา
            เงื่อนไขการใช้งานเหล่านี้อาจอธิบายและกำหนดสิทธิและภาระผูกพันเพิ่มเติมบางอย่างที่จะเป็นส่วนหนึ่งของสัญญาซื้อขายที่คุณทำกับผู้ใช้รายอื่น
            เมื่อยอมรับเงื่อนไขการใช้งาน
            แสดงว่าคุณตกลงล่วงหน้าว่าคุณจะผูกพันตามสัญญาซื้อขายและสิทธิ์และภาระผูกพันดังกล่าวข้างต้น
            (ซึ่งจะเป็นส่วนสำคัญของสัญญาซื้อขายแต่ละฉบับ) ต่อคู่สัญญาของคุณ
            (หากมี ไม่ว่าอย่างใดอย่างหนึ่ง ผู้ซื้อหรือผู้ขาย) ดังนั้น
            สัญญาซื้อขายและข้อกำหนดเพิ่มเติมดังกล่าวสามารถเรียกใช้กับคุณและ/หรือโดยคุณ
            และจะแทนที่ข้อกำหนดอื่นใดที่ใช้บังคับกับความสัมพันธ์ทางสัญญาระหว่างผู้ขายและผู้ซื้อ
          </p>
          <Spacer height={20} width={0} />
          <p>
            การบังคับใช้ข้อกำหนดและเงื่อนไขของคุณหรือบุคคลที่สามถูกปฏิเสธโดยชัดแจ้ง
            ข้อกำหนดการใช้งานเหล่านี้มีผลเหนือข้อกำหนดและเงื่อนไขใดๆ
            ที่มีอยู่ในหรืออ้างถึงในเอกสารอื่นใด รวมถึงการติดต่อใดๆ
            ที่ไม่สอดคล้องกับข้อกำหนดการใช้งาน หากข้อกำหนดหรือเงื่อนไขอื่นๆ
            ดังกล่าวมีผลบังคับใช้
            หากมีข้อขัดแย้งระหว่างข้อกำหนดการใช้งานฉบับภาษาอังกฤษกับฉบับภาษาอื่น
            ให้ยึดฉบับภาษาอังกฤษเป็นหลัก ข้อกำหนดและแนวคิด (ทางกฎหมาย) ใดๆ
            ที่ใช้ในข้อกำหนดการใช้งานเหล่านี้จะถูกตีความตามกฎหมายของประเทศเนเธอร์แลนด์
            ตามข้อ 15
          </p>
          <Spacer height={20} width={0} />
          <p>
            <u>ข้อ 3: ความเป็นส่วนตัวของคุณ</u>
          </p>
          <Spacer height={20} width={0} />
          <p>
            การเคารพการประมวลผลข้อมูลส่วนบุคคลของคุณอย่างเหมาะสมเป็นสิ่งสำคัญสำหรับเรา
            ในนโยบายความเป็นส่วนตัวของเรา
            คุณสามารถอ่านวิธีที่เราจัดการข้อมูลส่วนบุคคลของคุณ
            โดยการเข้าถึงหรือใช้ตลาดออนไลน์ของเรา
            คุณรับทราบว่าเราสามารถประมวลผลข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ที่อธิบายไว้ในนโยบายความเป็นส่วนตัวของเรา
            เพื่อให้ผู้ใช้ของเราสามารถเรียกร้องสิทธิ์และปฏิบัติตามข้อผูกพันภายใต้สัญญาซื้อขาย
            เราให้ข้อมูลส่วนบุคคลที่จำเป็นแก่ผู้ใช้ดังกล่าว (เช่น ชื่อ
            ที่อยู่อีเมล หมายเลขโทรศัพท์ และที่อยู่จัดส่ง) ของผู้ใช้รายอื่น
            ตามที่เราได้ให้ไว้ โดยผู้ใช้รายอื่นดังกล่าว ตัวอย่างเช่น
            ผู้ขายต้องการข้อมูลนี้เพื่อให้สามารถจัดส่งล็อตไปยังผู้ซื้อได้
            ignitestores.com
            ไม่สามารถรับประกันหรือรับผิดต่อความจริงและความถูกต้องของข้อมูลส่วนบุคคลที่ผู้ใช้ให้ไว้
            ซึ่งเราแบ่งปันกับผู้ใช้รายอื่นหรือบุคคลที่สาม (เช่น
            ผู้ให้บริการจัดส่ง) ตามสัญญาซื้อขาย ทั้ง ignitestores.com
            และผู้ใช้ที่ได้รับข้อมูลส่วนบุคคลถือเป็นผู้ควบคุมข้อมูลส่วนบุคคลที่แยกจากกันและเป็นอิสระภายใต้ข้อมูลที่เกี่ยวข้อง
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
