import { useEffect, useState, useRef } from "react";
import "./style.scss";
import { TagFormat } from "../../components";
import Spacer from "../../components/Spacer";
import ExlamationTriangle from "../../assets/icon/exclamation-triangle.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { toast } from "react-toastify";
import ApiService from "../../config/api";
import { clearBiddingProductData } from "../../store/bidding/action";
import moment from "moment";
import ChangeUserNameModal from "./ChangeUserNameModal";

const MyAccount = () => {
  const naviagate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [myAccountData, setMyAccountData] = useState<any>({});
  const [userName, setUserName] = useState("");
  const [isDisable, setDisable] = useState(true);
  const [error, setError] = useState(null);
  const [showChangeUserNameModal, setShowChangeUserNameModal] = useState(false)

  useEffect(() => {
    dispatch(clearBiddingProductData());
    getMyAccountApi();
  }, []);

  const getMyAccountApi = async () => {
    dispatch(showLoader());
    const myaccountRes = await ApiService.get("/user/myaccount");
    if (myaccountRes.status === "SUCCESS") {
      setMyAccountData(myaccountRes?.response);
      dispatch(hideLoader());
    } else {
      setError(myaccountRes.response);
      dispatch(hideLoader());
    }
  };

  const handleChange = (e: any) => {
    const addname = e.target.value;
    setUserName(addname);
  };

  const userNameEdit = async () => {

    setShowChangeUserNameModal(true);
    return;
    if (!isDisable) {
      dispatch(showLoader());
      await ApiService.post("user/changeUserName", {
        username: userName,
      });
      dispatch(hideLoader());
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const forgotEmail = '';
  return (
    <div className="main-cont my-account-page">
      <div className="container">
        <TagFormat Tag="p" text="My Account"></TagFormat>
        {myAccountData && (
          <div className="row form-main-cont">
              <div className="col-md-6 px-2 mt-4">
                <div className="form-container">
                  <p>ข้อมูลส่วนบุคคล</p>
                  <div className="input-cont">
                    <label>ชื่อแสดงผลในระบบ</label>
                    <div className="input1">
                      <input
                        type="text"
                        className="col-10"
                        disabled={isDisable}
                        ref={inputRef}
                        value={
                          userName === ""
                            ? myAccountData.userDetails?.username
                            : userName
                        }
                        placeholder=""
                        onInput={handleChange}
                      />
                      <span onClick={() => userNameEdit()}>
                        {!isDisable ? "บันทึก" : "เปลี่ยน"}
                      </span>
                    </div>
                  </div>
                  <div className="input-cont">
                    <label>ชื่อ - นามสกุล</label>
                    <input
                      disabled
                      placeholder=""
                      name="ชื่อ - นามสกุล"
                      value={myAccountData.userDetails?.name +' '+myAccountData.userDetails?.lastname}
                    />
                  </div>
                  <div className="input-cont">
                    <label>เบอร์โทรศัพท์</label>
                    <input
                      disabled
                      placeholder=""
                      name="เบอร์โทรศัพท์"
                      value={myAccountData.userDetails?.contactnumber}
                    />
                  </div>
                  <div className="input-cont">
                    <label>อีเมล</label>
                    <input
                      disabled
                      placeholder=""
                      name="อีเมล"
                      value={myAccountData.userDetails?.email}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 mt-4">
                <div className="form-container">
                  <p>ตั้งค่าที่อยู่</p>
                  <div className="my-account-address-container">
                    {myAccountData.address?.map((data: any, key: number) => (
                      <div className="input-cont" key={key}>
                        <div className="address-txt">
                          {data?.name} <br />
                          {data?.address1}<br />
                          {data?.address2} <br />
                          {data?.province} <br />
                          {data?.city} <br />
                          {data?.country} <br />
                          {data?.pincode} <br />
                        </div>
                      </div>
                    ))}
                  </div>

                  <Spacer height="2rem" width="0" />
                  <div className="col-6 form_right_cont">
                    <Link to={"/myaddress"}>
                      <p>แก้ไขที่อยู่</p>
                    </Link>
                  </div>
                </div>
              </div>
         

            {/* <div className="col-md-4 px-2 mt-4">
              <div className="form-container">
                <p>ตั้งค่าบัญชีคืนเงิน</p>
                <div className="input-cont">
                  <label>ธนาคาร</label>
                  <div className="d-flex">
                    <div style={{position:'absolute', marginRight:'4px', width:26,height:26,borderRadius:'50px',background:'#50277D'}} />
                    <input
                      className="w-100 px-5"
                      disabled
                      placeholder=""
                      value={myAccountData.bankDetails?.bankname}
                    />
                  </div>
                </div>
                <div className="input-cont">
                  <label>ชื่อบัญชี</label>
                  <input
                    disabled
                    placeholder=""
                    value={myAccountData.bankDetails?.accountname}
                  />
                </div>
                <div className="input-cont">
                  <label>เลขที่บัญชี</label>
                  <input
                    disabled
                    placeholder=""
                    value={myAccountData.bankDetails?.accountnumber}
                  />
                </div>
                <div className="d-flex">
                  <img src={ExlamationTriangle} alt="" />
                  <p className="pt-3 mx-2">การฝากเงินจะต้องโอนกลับไปยังชื่อบัญชีที่ตรงกับผู้ใช้เท่านั้น</p>
                </div>
                <Spacer height="10px" width="0" />
                <Link to="/bankaccount">
                  <div className="col-10 form_right_cont">
                    <p>แก้ไขบัญชีธนาคาร</p>
                  </div>
                </Link>
              </div>
            </div> */}

            <div className="col-md-12 px-2 mt-4">
              <div className="middle-container row mx-0">
                <div className="col-lg-8">
                  <div className="changeps_header">เปลี่ยนรหัสผ่าน</div>
                  <Spacer height="10px" width="0" />
                  <p>
                    เมื่อเปลี่ยนรหัสเรียบร้อยแล้ว
                    โปรดเข้าสู่ระบบใหม่อีกครั้งเพื่อดำเนินการต่อ
                  </p>
                </div>
                <div className="col-lg-4">
                  <button
                    type="submit"
                    onClick={() => naviagate("/ChangePassword",{ state: { forgotEmail }, replace: true })}
                    className="right_cont my-4"
                  >
                    เปลี่ยนรหัสผ่าน
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12 px-2 mt-4">
              <div className="table-container text-nowrap overflow-x-auto">
                <p>ประวัติการชำระเงิน</p>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr className="border-b">
                      <th className="px-4 py-2">ID</th>
                      <th className="px-4 py-2">วันที่ชําระเงิน</th>
                      <th className="px-4 py-2">ปริมาณ</th>
                      <th className="px-4 py-2">รายการ</th>
                      <th className="px-4 py-2">ช่อง</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myAccountData.paymentResults?.map((data: any, key: number) => (
                      <tr key={key} className="border-b">
                        <td className="px-4 py-2">INV{data.id}</td>
                        <td className="px-4 py-2">{moment(data.createdon).format('DD/MM/YYYY')}</td>
                        <td className="px-4 py-2">{data.amount}</td>
                        <td className="px-4 py-2">
                          {data.payment_type === 'D' ? `ชําระค่าดําเนินการ ค่าธรรมเนียมแรกเข้า [ ${data?.product_id} ]` :
                          data?.payment_type === 'R' ? `การคืนรายการเงินฝาก [ ${data?.product_id} ]` :
                          `ชําระค่ารายการสินค้า [ ${ data?.product_id} ]`}
                          {data?.remarks}
                        </td>
                        <td className="px-4 py-2">{data.payment_mode === 'Card' ? "บัตร" : "อินเทอร์เน็ต การธนาคาร"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <Spacer height="30px" width="0" />
        <ChangeUserNameModal isOpen={showChangeUserNameModal} 
        isClose={() => {
          setShowChangeUserNameModal(false);
          getMyAccountApi();
        }}  
        userName={myAccountData}
        ></ChangeUserNameModal>
      </div>
    </div>
  );
};

export default MyAccount;
