import types from './actionTypes';

const INITIAL_STATE = {
  currentUser: null,
  loading: false,
  error: null,
};

const authReducer = (state = INITIAL_STATE, action:any) => {
  switch (action.type) {
    case types.REGISTER_START:
      return {
        ...state,
        loading: true, 
        error: null,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false, 
        currentUser: action.payload,
        error: null,
      };
    case types.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;