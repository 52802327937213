import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios"; // Import AxiosResponse

import { logInFailure, logInSuccess } from "./actions";


// function* fetchLoginSaga(action: any): Generator<any> {
//   try {
//     const responseData = yield ApiService.post(
//       "/user/auth/signIn",
//       action.payload
//     );
//     localStorage.setItem("user", JSON.stringify(responseData));
//     return { token: responseData };
//   } catch (error: any) {
//     yield put(logInFailure(error.message));
//   }
// }

export function* checkAuth() {
  const userData = JSON.parse(localStorage.getItem("user") || "");
  yield put(logInSuccess(userData));
}

// function* fetchLoginSaga({email,password}:any): Generator<any, void, AxiosResponse> {
//   try {
//     const response:AxiosResponse = yield call(() => axios.post('https://igadminbk.cts-digital.com/user/auth/userSignIn',{email,password}));
//     yield put(logInSuccess(response.data));
//   } catch (error) {
//     yield put(logInFailure(error));
//   }
// }

// export function* fetchLoginDetail() {
//   yield takeEvery(types.LOGIN_START, fetchLoginSaga);
// }

export function* watchFetchLoginDetails() {
  yield all([call(checkAuth)]);
}
