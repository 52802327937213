import axios from "axios";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import ApiService from "../../config/api";

import { registerFailure, registerSuccess } from "./actions";
import types from "./actionTypes";

function* createUser(action: any): Generator<any> {
  try {
    const response = yield ApiService.post("/user/create", action.payload);
    yield put(registerSuccess(response));
  } catch (error: any) {
    yield put(registerFailure(error.message));
  }
}

export function* watchFetchUserDetails() {
  yield takeEvery(types.REGISTER_START, createUser);
}
