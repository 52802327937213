import { useLocation } from "react-router-dom";
import ".././page.scss";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import ApiService from "../../config/api";
import CardThree from "../../components/CardThree";
import { toast } from "react-toastify";
import CardFour from "../../components/CardFour";

const ProductList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchKeyword = new URLSearchParams(location.search).get('searchKey');
  const sectionName = new URLSearchParams(location.search).get('sectionName');
  const [filterProducts, setFilterProducts] = useState<any>(null);
  const [productTitle, setProductTile] = useState("");
  const [error, setError] = useState(null);

  const getFilterProducts = async () => {
    console.log(sectionName);
    console.log(searchKeyword);
    const data = searchKeyword;
    var payload:any = {};
    if(sectionName){
      payload.section_name = sectionName;
      setProductTile(sectionName);
    } else if(searchKeyword){
      payload.name = sectionName;
      setProductTile(searchKeyword);
    }

    dispatch(showLoader());
    const filterResponse = await ApiService.post("/user/filterProduct", payload);
    if (filterResponse.status === "SUCCESS") {
      setFilterProducts(filterResponse?.response?.products);
      dispatch(hideLoader());
    } else {
      setError(filterResponse.response);
      dispatch(hideLoader());
    }
  };
  const getLatestBidding = async ()=> {
    dispatch(showLoader());
    const filterResponse = await ApiService.get("/user/getAllLatestBiddingItems");
    if (filterResponse.status === "SUCCESS") {
      setFilterProducts(filterResponse?.response);
      setProductTile(searchKeyword || '');
      dispatch(hideLoader());
    } else {
      setError(filterResponse.response);
      dispatch(hideLoader());
    }
  }
  useEffect(() => {
    if(searchKeyword === 'bidding'){
      getLatestBidding()
    } else{
      getFilterProducts();
    }
  }, []);
  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <div className="bidding-page p-3">
      <div className="container">
        <div className="row">
        <p className="productlist-title">{productTitle}</p>
          {filterProducts &&
            filterProducts.map((item: any, key: number) => (
              <div key={key} className="col-sm-12 col-md-6 col-lg-4 col-xl-3 px-1 bidding-card">
              <CardFour datas={item} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default ProductList;
