import ApiService from "../config/api";

export const addFavourite = async (data: any) => {
  try {
    const favData = { productId: data };
    let response = await ApiService.post("/user/addFavorite", favData);
    return { status: true, response: response };
  } catch (error: any) {
    return { status: false, response: error.message };
  }
};

export const getFavourite = async (pageNo: number) => {
  try {
    let response = await ApiService.get(
      `/user/getFavoriteByUserId?pageNo=` + pageNo
    );
    return { status: true, response: response };
  } catch (error: any) {
    return { status: false, response: error.message };
  }
};
