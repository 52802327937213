import React, { useState } from "react";
import cash from "../assets/icon/cash.svg";
import "./component.scss";
import { Modal } from "react-bootstrap";
import close from "../assets/icon/close-white.svg";
import shield from "../assets/icon/shield-check.svg";
import { useNavigate } from "react-router-dom";
import ApiService from "../config/api";
import {
  setBiddingProductData,
  setDepositeData,
  setDepositePaymentType,
} from "../store/bidding/action";
import { useDispatch } from "react-redux";
import ChoosePaymentModal from "./BiddingPaymentModal/ChoosePaymentModal";

const PaymentModal = ({ isOpen, isClose, productData }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isConfirmPaymentModal, setConfirmPaymentModal] = useState(false);

  const paymentAmount =
    parseFloat(productData.productdepositamount) +
    parseFloat(productData.servicecharge);

  const closeModal = () => {
    isClose();
  };

  const handleBiddingPayment = () => {
    console.log(productData);
    dispatch(setDepositePaymentType("D"));
    dispatch(
      setBiddingProductData(
        productData?.product_id,
        paymentAmount,
        productData?.auction_id,
        productData.productdepositamount,
        productData.servicecharge
      )
    );
    
    setConfirmPaymentModal(true);
    // navigate("/myAddress", { state: "depositPayment", replace: true });
  };
  return (
    <>
      <Modal show={isOpen}>
        <img src={close} alt="" onClick={closeModal} />
        <div className="col-md-12 mt-4 payment-modal">
          <div className="text-center">
            <img src={cash} className="close-img" alt=""></img>

            <div className="main-cnt mt-3">
              โปรดชำระเงิน เพื่อเข้าร่วมการประมูล
            </div>

            <table className="my-5">
              <tr>
                <td className="tbl-txt1">ประกันมัดจำสินค้า</td>
                <td className="tbl-txt1">ค่าบริการ</td>
              </tr>
              <tr className="border-btm">
                <td className="tbl-num1">
                  {parseInt(productData.productdepositamount)} ฿
                </td>
                <td className="tbl-num1">{parseInt(productData.servicecharge)} ฿</td>
              </tr>
              <tr className="border-btm">
                <td className="tbl-txt2">รวมทั้งสิ้น</td>
                <td className="tbl-num2">{paymentAmount} ฿</td>
              </tr>
            </table>

            <div className="sub-cnt">
              เมื่อท่านชำระเงิน ถือว่าคุณได้ยอมรับ
              เงื่อนไขการใช้งานและรับทราบเงื่อนไขการประมูลของสินค้าชิ้นนี้แล้ว
            </div>

            <button onClick={handleBiddingPayment} className="white-btn mt-3">
              ชำระเงิน
            </button>

            <button onClick={closeModal} className="mt-3">
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
      <ChoosePaymentModal
        isOpen={isConfirmPaymentModal}
        isClose={() => setConfirmPaymentModal(false)}
      />
      ;
    </>
  );
};

export default PaymentModal;
