import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TagFormat } from ".";
import Spacer from "./Spacer";
import close from "../assets/icon/close-white.svg";
import "./component.scss";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";
import { useDispatch } from "react-redux";
import { addAddressapi, updateAddress } from "../controller/AddressFunction";
import { toast } from "react-toastify";
import ApiService from "../config/api";

const AddAddress = ({ isOpen, isClose, data }: any) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    setName(data?.name);
    setTelephone(data?.contactnumber);
    setAddress(data?.address1);
    setAddress2(data?.address2);
    setSubDistrict(data?.city);
    setDistrict(data?.country);
    setProvince(data?.province);
    setPostOffice(data?.pincode);
  }, [data]);

  const [name, setName] = useState("");
  const [nameFocus, setNameFocus] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressFocus, setAddressFocus] = useState(false);
  const [addressFocus2, setAddressFocus2] = useState(false);
  const [subDistrict, setSubDistrict] = useState("");
  const [subDistrictFocus, setsubDistrictFocus] = useState(false);
  const [district, setDistrict] = useState("");
  const [districtFocus, setDistrictFocus] = useState(false);
  const [province, setProvince] = useState("");
  const [provinceFocus, setProvinceFocus] = useState(false);
  const [postOffice, setPostOffice] = useState("");
  const [postOfficeFocus, setPostOfficeFocus] = useState(false);

  const validationSet = () => {
    if (name === "") {
      setNameFocus(true);
    } else {
      setNameFocus(false);
    }
    if (telephone === "") {
      setPhoneFocus(true);
    } else {
      setPhoneFocus(false);
    }
    if (address === "") {
      setAddressFocus(true);
    } else {
      setAddressFocus(false);
    }
    if (address2 === "") {
      setAddressFocus2(true);
    } else {
      setAddressFocus2(false);
    }
    if (subDistrict === "") {
      setsubDistrictFocus(true);
    } else {
      setsubDistrictFocus(false);
    }
    if (district === "") {
      setDistrictFocus(true);
    } else {
      setDistrictFocus(false);
    }
    if (province === "") {
      setProvinceFocus(true);
    } else {
      setProvinceFocus(false);
    }
    if (postOffice === "") {
      setPostOfficeFocus(true);
    } else {
      setPostOfficeFocus(false);
    }
  };

  const [addAddress, setAddressData] = useState<any>();
  const [error, setError] = useState(null);

  const addAddressData = async (FormData: any) => {
    const response = await ApiService.post(
      "/user/address/createOrUpdateAddress",
      FormData
    );
    if (response.status === "SUCCESS") {
      setAddressData(response);
      dispatch(hideLoader());
      isClose();
    } else {
      setError(response.response);
      dispatch(hideLoader());
    }
  };

  const editAddressData = async (FormData: any) => {
    const addressId = data.id;
    FormData.addressId = addressId;
    const response = await ApiService.post(
      "/user/address/createOrUpdateAddress",
      FormData
    );
    if (response.status === "SUCCESS") {
      setAddressData(response);
      dispatch(hideLoader());
      isClose();
    } else {
      setError(response.response);
      dispatch(hideLoader());
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    validationSet();
    if (
      !nameFocus &&
      !phoneFocus &&
      !addressFocus &&
      !subDistrictFocus &&
      !districtFocus &&
      !provinceFocus &&
      !postOfficeFocus
    ) {
      const formdata = {
        name: name,
        address1: address,
        address2: address2,
        city: subDistrict,
        country: district,
        contactNumber: telephone,
        pinCode: postOffice,
        province:province
      };
      dispatch(showLoader());
      if (data) {
        editAddressData(formdata);
      } else {
        addAddressData(formdata);
      }
    }
  };

  const closeModal = () => {
    isClose();
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <div>
      <Modal show={isOpen}>
        <form onSubmit={handleSubmit}>
          <div className="add-address-modal">
            <div className="text-right">
              <img src={close} alt="" onClick={closeModal} />
            </div>
            <p className="modal-title">ที่อยู่ใหม่</p>
            <Spacer height="30px" width="" />
            <div>
              <div>
                <TagFormat
                  className="input_label"
                  Tag="p"
                  text="ชื่อ-นามสกุล"
                />
                <input
                  className={
                    nameFocus && name === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <Spacer height="20px" width={undefined} />
              <div>
                <TagFormat className="" Tag="p" text="หมายเลขโทรศัพท์" />
                <input
                  className={
                    phoneFocus && telephone === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </div>

              <Spacer height="20px" width={undefined} />
              <div>
                <TagFormat className="" Tag="p" text="ที่อยู่บรรทัดที่ 1" />
                <input
                  className={
                    addressFocus && address === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <Spacer height="20px" width={undefined} />
              <div>
                <TagFormat className="" Tag="p" text="ที่อยู่บรรทัดที่ 2" />
                <input
                  className={
                    addressFocus2 && address2 === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </div>
            </div>

            <Spacer height="20px" width={undefined} />

            <div className="row">
              <div className="col-6">
                <TagFormat className="" Tag="p" text="แขวง/ตำบล" />
                <input
                  className={
                    subDistrictFocus && subDistrict === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={subDistrict}
                  onChange={(e) => setSubDistrict(e.target.value)}
                />
              </div>

              <div className="col-6">
                <TagFormat className="" Tag="p" text="เขต/อำเภอ" />
                <input
                  className={
                    districtFocus && district === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </div>
            </div>

            <Spacer height="20px" width={undefined} />

            <div className="row">
              <div className="col-6">
                <TagFormat className="" Tag="p" text="จังหวัด" />
                <input
                  className={
                    provinceFocus && province === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                />
              </div>

              <div className="col-6">
                <TagFormat className="" Tag="p" text="ไปรษณีย์" />
                <input
                  className={
                    postOfficeFocus && postOffice === ""
                      ? "border-red col-12 register_inputField"
                      : "col-12 register_inputField"
                  }
                  value={postOffice}
                  onChange={(e) => setPostOffice(e.target.value)}
                />
              </div>
            </div>
            <Spacer height="30px" width="" />
            <div className="col-12 register_button_cont text-center">
              <button className="col-12 register_button">บันทึก</button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};
export default AddAddress;
