import React, { useEffect, useState } from "react";
import cash from "../../assets/icon/cash.svg";
import bgImage from "../../assets/refundBg.png";
import firstBgImg from "../../assets/icon/winner_bg.svg";
import "../component.scss";
import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import { useNavigate } from "react-router-dom";
import Spacer from "../Spacer";
import ApiService, { BASE_URL_IMG } from "../../config/api";
import { useDispatch } from "react-redux";
import {
  setBiddingPaymentMode,
  setBiddingPaymentStatus,
  setBiddingProductData,
} from "../../store/bidding/action";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import WaitingModal from "./WaitingModal";
import moment from "moment";
import { getBiddingCounterText } from "../../services/Utils";
import BiddingCounter from "../biddingCounter";

const BiddingConfirmModal = ({ isOpen, isClose, productData }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRefundInitated, setRefundInitiate] = useState(false);
  const paymentAmount = productData?.latestAuctionList?.[0];
  const [confirmPayment, setConfirmPayment] = useState(false);

  const initialTime = {
    hours: 0,
    minutes: 5,
    seconds: 0,
  };

  console.log(productData);

  const originalDateTime = productData?.auctionclosedatetime;
  const parsedDateTime = moment(
    productData?.auctionclosedatetime,
    "YYYY-MM-DD HH:mm"
  );
  const updatedDateTime = parsedDateTime.add(24, "hours");
  const formattedDateTime = updatedDateTime.format("YYYY-MM-DD HH:mm");

  var counter = {
    auctionstartdatetime: productData?.auctionclosedatetime,
    auctionclosedatetime: formattedDateTime,
  };

  const [time, setTime] = useState(initialTime);
  const [isBiddingTimerOver, setBiddingTimeOver] = useState(false);

  const closeModal = () => {
    isClose();
  };
  const [paymentAmountData, setPaymentAmountData] = useState<any>(null);

  const getPayment = async () => {
    dispatch(showLoader());
    const getPaymentAmount = await ApiService.get(
      `/bidding/getPaymentAmount/${productData?.product_id}`
    );
    if (getPaymentAmount.status === "SUCCESS") {
      setPaymentAmountData(getPaymentAmount?.response?.[0]);
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (productData?.is_winner === 1) {
      const timer = setInterval(() => {
        // Decrease seconds
        if (time.seconds > 0) {
          setTime((prevTime) => ({
            ...prevTime,
            seconds: prevTime.seconds - 1,
          }));
        }
        // Decrease minutes
        else if (time.minutes > 0) {
          setTime((prevTime) => ({
            ...prevTime,
            minutes: prevTime.minutes - 1,
            seconds: 59,
          }));
        }
        // Decrease hours
        else if (time.hours > 0) {
          setTime((prevTime) => ({
            ...prevTime,
            hours: prevTime.hours - 1,
            minutes: 59,
            seconds: 59,
          }));
        }
        // Timer is up, clear interval
        else {
          setBiddingTimeOver(true);
          clearInterval(timer);
          // You can add additional logic when the timer reaches 0
        }
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(timer);
    }
  }, [time]);

  const handleConfirmPayment = () => {
    if (isBiddingTimerOver === false) {
      setConfirmPayment(true);
      console.log("1");
      getPayment();
    } else {
      setConfirmPayment(false);
      console.log("1");
    }
  };

  const [isWaitingModal, setwaitingModal] = useState(false);

  const handleWaiting = async () => {
    if (isWaitingModal === false) {

        dispatch(showLoader());
        let data = {
          auction_id: productData?.auction_id,
          isWait:true
        };
        
        const insertWaitingRes = await ApiService.post(
          `/bidding/isSecondWinnerWait`,data
        );
        if (insertWaitingRes.status === "SUCCESS") {
          setwaitingModal(true);
          dispatch(hideLoader());
        }
    
    } else {
      setwaitingModal(false);
    }
  };

  const handleBiddingPayment = () => {
    dispatch(
      setBiddingProductData(
        productData?.product_id,
        productData?.bidding_amount,
        productData?.auction_id
      )
    );
    navigate("/paymentOrderAddress");
  };
  const handleBiddingPaymentNew = () => {
    dispatch(
      setBiddingProductData(
        productData?.product_id,
        paymentAmountData?.pay_amount,
        productData?.auction_id
      )
    );
    navigate("/paymentOrderAddress");
  };

  const handleRefundFlow = async () => {

    let data = {
      auction_id: productData?.auction_id,
      isWait:false
    };
    
    const insertWaitingRes = await ApiService.post(
      `/bidding/isSecondWinnerWait`,data
    );


    if (productData?.paymentDetails[0]?.payment_mode === "Internet Banking") {
      dispatch(
        setBiddingPaymentMode(productData?.paymentDetails[0]?.payment_mode)
      );
      isClose();
    } else {
      dispatch(showLoader());
      let data = {
        productId: productData?.product_id,
        biddingAmount: paymentAmount?.bidding_amount,
        status: "REFUND_INITIATE",
        paymentType: "R",
        auctionId: productData?.auction_id,
      };
      const paymentInitiate = await ApiService.post(
        "/payment/create-refund",
        data
      );
      if (paymentInitiate.status === "SUCCESS") {
        dispatch(setBiddingPaymentStatus(data?.status));
        dispatch(hideLoader());
        setRefundInitiate(true);
        closeModal();
      } else {
        // setError(paymentInitiate.response);
        dispatch(hideLoader());
      }
    }
  };

  return (
    <>
      <Modal show={isOpen && !isRefundInitated}>
        <img
          style={{ position: "relative", zIndex: 99 }}
          src={close}
          alt=""
          onClick={closeModal}
        />
        {isWaitingModal ? (
          <>
            <div className="col-md-12 mt-4 payment-modal">
              <div className="text-center">
                <img src={cash} className="close-img" alt=""></img>
                <div className="main-cnt mt-3">
                คุณได้ยืนยันรอ รับสิทธิการชำระเงิน ในลำดับที่ 2
                </div>
                
              </div>
              <button onClick={() => navigate("/")} className="mt-3">
                เข้าร่วนการประมูล
                </button>
            </div>
          </>
        ) : (
          <>
            {!confirmPayment ? (
              <div className="col-md-12 mt-1 payment-modal">
                <div className="text-center">
                  {productData?.bid_order === 2 ? (
                    <img src={bgImage} className="refund_bgimg" alt=""></img>
                  ) : (
                    <img src={firstBgImg} className="refund_bgimg" alt=""></img>
                  )}
                </div>
                <div className="refund-modal-content mt-3">
                  {productData?.bid_order === 2 ? (
                    <p className="title">คุณประมูลได้ที่ 2</p>
                  ) : (
                    <p className="title">คุณชนะการประมูล</p>
                  )}

                  <Spacer height={".7rem"} width={0} />
                  <img
                    src={productData?.fileList[0]?.filepath}
                    className="prod-img"
                    alt=""
                  ></img>
                  <div className="pt-4">
                    <p>{productData?.name}</p>
                    <h1>{productData?.bidding_amount} ฿</h1>
                  </div>

                  <div className="refund-popupwinner">
                    <Spacer height={"1rem"} width={0} />
                    <p className="sub-cnt px-5">
                      ระบบได้ส่งผลการประมูลและขั้นตอนการชำระเงินเข้า
                      เมลที่คุณสมัคร@gmail.comหากไม่ชำระเงินเราจะยกสิทธิ์ประมูลนี้ให้ผู้ชนะลำดับถัดไป
                    </p>
                    <Spacer height={"1.5rem"} width={0} />
                    <div className="footer-bottom">
                      {productData?.is_winner === 0 && (
                        <button onClick={handleWaiting} className="white-btn">
                          รอรับสิทธิ์ชำระเงินลำดับที่ 2
                        </button>
                      )}

                      {productData?.is_winner === 1 ? (
                        <>
                          <button
                            onClick={handleConfirmPayment}
                            className="white-btn"
                          >
                            ชำระเงินทันที
                          </button>
                          <div className="mt-3">
                            <div className="paywithintext">
                              โปรดชำระเงินภายใน
                            </div>
                            <div className="paywithintime">
                              <BiddingCounter data={counter} />
                            </div>
                          </div>
                        </>
                      ) : (
                        <button onClick={handleRefundFlow} className="mt-3">
                          รับมัดจำสินค้าคืน
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12 mt-4 payment-modal">
                <div className="text-center">
                  <img src={cash} className="close-img" alt=""></img>

                  <div className="main-cnt mt-3">
                    ชำระเงิน เพื่อรับสินค้าประมูล
                  </div>

                  <table className="my-5">
                    <tr>
                      <td className="tbl-txt1">ราคาชนะการประมูล</td>
                      <td className="tbl-num1">
                        {productData?.bidding_amount} ฿
                      </td>
                    </tr>
                    <tr className="border-btm">
                      <td className="tbl-txt1"> ประกันมัดจำสินค้า</td>
                      <td className="tbl-num1 minus_deposit">
                        - {parseInt(paymentAmountData?.productdepositamount)} ฿
                      </td>
                    </tr>
                    <tr className="border-btm">
                      <td className="tbl-txt1">รวมทั้งสิ้น</td>
                      <td className="tbl-num2">
                        {paymentAmountData?.pay_amount} ฿
                      </td>
                    </tr>
                  </table>
                  <button
                    onClick={
                      productData.bid_order == 1
                        ? handleBiddingPaymentNew
                        : handleBiddingPayment
                    }
                    className="white-btn mt-3"
                  >
                    ชำระเงิน
                  </button>

                  <button onClick={closeModal} className="mt-3">
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default BiddingConfirmModal;
