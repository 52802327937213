import { TagFormat } from "../../../components";
import Spacer from "../../../components/Spacer";
import "../styles.scss";

const PDPACompliance = () => {
  return (
    <div className="terms-of-use">
      <div className="max-width py-2">
        <TagFormat
          Tag="h3"
          className="aboutus-title"
          text="การปฏิบัติตามข้อกำหนด PDPA"
        />
        <div>
          <p>
            ประเภทข้อมูลส่วนบุคคล ข้อมูลทั่วไป หมายความว่า
            ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
            แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ ข้อมูลทางการเงิน
            หมายความว่า ข้อมูลหรือสิ่งใด ๆ ที่แสดงออกมาในรูปเอกสาร แฟ้ม รายงาน
            หนังสือ แผนผัง ภาพวาด ภาพถ่าย
            การบันทึกภาพหรือเสียงการบันทึกโดยเครื่องมือทางอิเล็กทรอนิกส์
            หรือวิธีอื่นใดที่ทำให้สิ่งที่บันทึกไว้ปรากฏขึ้นในเรื่องที่เกี่ยวกับข้อมูลทางการเงินของบุคคลที่สามารถระบุตัวบุคคลได้
            วัตถุประสงค์การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            เพื่อบริการให้ตรงตามต้องการของลูกค้า
            บริษัทจะใช้ข้อมูลส่วนบุคคลของลูกค้า
            เพื่อที่ลูกค้าจะสามารถได้รับผลิตภัณฑ์และ/หรือบริการที่ตรงตามวัตถุประสงค์ของลูกค้าตามสัญญาหรือตามที่ลูกค้าร้องขอ
            ในกรณีดังต่อไปนี้ การพิจารณาอนุมัติและ/หรือให้บริการต่าง ๆ เช่น
            การเปิดบัญชีซื้อขายหน่วยลงทุน การลงทุน การทำรายการซื้อ ขาย
            แลกเปลี่ยนหน่วยลงทุน การดำเนินการใด ๆ
            ที่เกี่ยวข้องกับการให้ผลิตภัณฑ์และ/หรือบริการต่าง ๆ เช่น การประมวลผล
            การติดต่อ การแจ้ง การมอบงานให้แก่บุคคลอื่นที่เป็นผู้ให้บริการภายนอก
            การโอนสิทธิและ/หรือหน้าที่ หากลูกค้าปฏิเสธไม่ให้ข้อมูลแก่บริษัท
            อาจทำให้บริษัทไม่สามารถจัดหาผลิตภัณฑ์และ/หรือให้บริการแก่ลูกค้าได้
          </p>
          <Spacer height={20} width={0} />
          <p>
            เพื่อปฏิบัติหน้าที่ตามกฎหมาย ในการปฏิบัติตามกฎหมายที่เกี่ยวข้อง เช่น
            กฎหมายหลักทรัพย์และตลาดหลักทรัพย์ กฎหมายภาษีอากร
            กฎหมายป้องกันและปราบปรามการฟอกเงิน กฎหมายคอมพิวเตอร์
            และกฎหมายล้มละลาย บริษัทมีการเก็บรวบรวม ใช้
            และ/หรือเปิดเผยข้อมูลส่วนบุคคล เช่น ข้อมูลของกรรมการ
            ผู้มีอำนาจกระทำการแทน ตัวแทนของลูกค้านิติบุคคล
            และบุคคลที่ศาลมีคำสั่งพิทักษ์ทรัพย์ เป็นต้น นอกจากนี้
            บริษัทมีหน้าที่ปฏิบัติตามคำสั่งของหน่วยงานหรือเจ้าหน้าที่ผู้มีอำนาจตามกฎหมาย
            รวมถึงบริษัทอาจมีความจำเป็นที่จะต้องให้ความร่วมมือในการให้ข้อมูลแก่หน่วยงานที่เกี่ยวข้องในต่างประเทศ
            หากลูกค้าปฏิเสธไม่ให้ข้อมูลแก่บริษัท
            อาจทำให้บริษัทไม่สามารถจัดหาผลิตภัณฑ์และ/หรือให้บริการแก่ลูกค้าได้
            เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัท หรือของบุคคลหรือนิติบุคคลอื่น
            เช่น มาตรการรักษาความปลอดภัย บริษัทมีการบันทึกเสียงทาง Call Center
            การบันทึกภาพ CCTV ณ ทางเข้าออกบริษัท การรักษาความสัมพันธ์กับลูกค้า
            เช่น การให้บริการลูกค้า การจัดการข้อร้องเรียน การประเมินความพึงพอใจ
            การดูแลลูกค้าโดยพนักงานของบริษัท
            การสื่อสารหรือนำเสนอผลิตภัณฑ์และ/หรือบริการต่าง ๆ
            ประเภทเดียวกันกับที่ลูกค้ามีอยู่กับบริษัทซึ่งเป็นประโยชน์กับลูกค้าเป็นระยะ
            ๆ การบริหารความเสี่ยง การกำกับตรวจสอบ การบริหารจัดการภายในองค์กร
            รวมถึงการป้องกันการกระทำผิดกฎหมาย เช่น การทุจริต การคุกคามทางไซเบอร์
            การฟอกเงิน และกฎหมายอื่น ๆ การรักษาความปลอดภัยของข้อมูลส่วนบุคคล
            เช่น การทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้
            (Anonymous Data) วัตถุประสงค์ทางธุรกิจ
            บริษัทมีการเก็บรวบรวมข้อมูลส่วนบุคคลเพื่อประโยชน์ในการวิเคราะห์ข้อมูลทางสถิติหรือประชาสัมพันธ์บริษัท
            เช่น การบันทึกภาพ บันทึกเสียงในการจัดประชุม อบรม สันทนาการ
            หรือการออกบูธ ในกรณีที่ลูกค้าปฏิเสธไม่ให้ข้อมูลแก่บริษัท
            อาจทำให้บริษัทไม่สามารถให้บริการลูกค้าได้อย่างมีประสิทธิภาพเท่าที่ควร
          </p>
          <Spacer height={20} width={0} />
          <p>
            เพื่อให้ลูกค้าได้รับประโยชน์จากการใช้ผลิตภัณฑ์และ/หรือบริการ
            ตามที่ลูกค้าได้ให้ความยินยอมไว้ เช่น
            เพื่อให้ลูกค้าได้รับผลิตภัณฑ์และ/หรือบริการที่ดียิ่งขึ้นและสอดคล้องกับความต้องการของลูกค้า
            เพื่อให้ลูกค้าได้รับข้อเสนอ สิทธิประโยชน์พิเศษ คำแนะนำ
            และข่าวสารต่าง ๆ รวมถึงสิทธิในการเข้าร่วมกิจกรรมพิเศษ เป็นต้น
            ในกรณีที่ลูกค้าปฏิเสธไม่ให้ข้อมูลแก่บริษัท
            อาจทำให้บริษัทไม่สามารถให้บริการลูกค้าได้อย่างมีประสิทธิภาพเท่าที่ควร
          </p>
          <Spacer height={20} width={0} />
          <p>
            แหล่งที่มาของข้อมูลส่วนบุคคล จากลูกค้าโดยตรง เช่น
            ข้อมูลที่ลูกค้ากรอกขณะลงทะเบียนสมัครใช้บริการ
            ข้อมูลที่ใช้ในการสมัครใช้บริการสมาชิก FINNOMENA,
            ข้อมูลบัญชีผู้ใช้งาน (Account)
            หรือข้อมูลที่ลูกค้าได้แก้ไขปรับปรุงในข้อมูลบัญชีผู้ใช้งาน (Account)
            ของลูกค้า
            หรือข้อมูลที่ได้จากการที่ลูกค้าติดต่อกับบริษัทหรือทีมงานของบริษัท
            (เช่น ตัวแทน หรือข้อมูลที่ได้จากบัญชีผู้ใช้งาน (Account) อื่น ๆ
            ข้อมูลจากการใช้ผลิตภัณฑ์ และ/หรือบริการของบริษัท การติดต่อ เยี่ยมชม
            ค้นหา ผ่านเว็บไซต์ Call Center และช่องทางอื่น ๆ ของบริษัท
            ตลอดจนข้อมูลในการร่วมกิจกรรมต่าง ๆ ทั้งในแพลตฟอร์ม, การทำสำรวจ,
            งานสัมมนาและ Road Show เทคโนโลยีการติดตาม (tracking technology)
            เมื่อลูกค้าใช้งานเว็บไซต์และแอพพลิเคชั่นของบริษัท บริษัทในเครือ
            บุคคลภายนอก เช่น หน่วยงานของรัฐ สถาบันการเงิน ตัวแทน นายหน้า
            และคนกลางอื่น ๆ พันธมิตรทางธุรกิจ ผู้ให้บริการข้อมูล โซเชียลมีเดีย
            แพลตฟอร์ม และผู้ให้บริการโฆษณาต่าง ๆ ทั้งนี้
            เพื่อประโยชน์ในการปรับปรุงข้อมูลส่วนบุคคลของลูกค้าให้เป็นปัจจุบัน
            และเพื่อปรับปรุงคุณภาพและประสิทธิภาพของผลิตภัณฑ์และการให้บริการของบริษัทให้ดียิ่งขึ้น
          </p>
          <Spacer height={20} width={0} />

          <p>
            ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคล
            บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของลูกค้าไว้เป็นระยะเวลาเท่าที่จำเป็นเพื่อการดำเนินการตามวัตถุประสงค์ที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ให้เสร็จสิ้น
            เว้นแต่กรณีที่กฎหมายกำหนดหรืออนุญาตให้เก็บรักษาข้อมูลไว้นานกว่า
            ซึ่งอาจมีกำหนดประมาณ 5 – 10 ปี หรือเกินกว่านั้นเท่าที่จำเป็น เช่น
            ตามอายุความที่กฎหมายกำหนดสำหรับเรื่องที่เกี่ยวข้อง เพื่อการดำเนินคดี
            หรือเพื่อการตรวจสอบของหน่วยงานที่กำกับดูแล
          </p>
          <Spacer height={20} width={0} />
          <p>
            การเปิดเผยข้อมูลส่วนบุคคล
            บริษัทจะไม่เปิดเผยข้อมูลส่วนบุคคลต่อบุคคลใดโดยไม่ได้รับความยินยอมจากลูกค้าก่อน
            อย่างไรก็ดี ลูกค้ารับทราบและยินยอมว่า
            เพื่อประสิทธิภาพในการให้บริการหรือเพื่อปฏิบัติตามกฎหมาย
            บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของลูกค้าให้กับบุคคลต่อไปนี้
          </p>
          <Spacer height={20} width={0} />
          <p>
            บริษัทในเครือ พันธมิตรทางธุรกิจ ผู้ให้บริการประมวลผลข้อมูล
            ทั้งในและต่างประเทศ
            หน่วยงานหรือเจ้าหน้าที่ของรัฐที่ใช้อำนาจหน้าที่ตามกฎหมาย
            โดยในการเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลดังกล่าว
            บริษัทจะดำเนินการให้บุคคลเหล่านั้นเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการไว้เป็นความลับ
            และไม่นำไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่บริษัทได้กำหนดไว้
          </p>
        </div>
      </div>
    </div>
  );
};

export default PDPACompliance;
