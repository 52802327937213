import { useEffect, useState, useCallback } from "react";
import "./component.scss";
import ProductCardThree from "./ProductCardThree";
import "../layout/main.scss";

interface ProductItem {
  filelist: FileDetail[];
  highlight_details: HighlightDetail[];
}

interface FileDetail {
  containerid: number;
  filepath: string;
  filename: string;
  filetype: string;
}

interface HighlightDetail {
  containerid: number;
  highlightsection: string;
  highlightdetail: string;
}

const ProductCardTwo = ({ productItem }: { productItem: ProductItem }) => {
  const [fileList, setFileList] = useState<FileDetail[]>([]);
  const [highLight, setHighLight] = useState<HighlightDetail[]>([]);
  const [unmatchedIds, setUnmatchedIds] = useState<any[]>([]);

  useEffect(() => {
    setFileList(productItem?.filelist || []);
    setHighLight(productItem?.highlight_details || []);
  }, [productItem]);

  const getUnmatchedIds = useCallback(() => {
    const matchedIds = highLight.map((highlight) => highlight.containerid);
    const unmatchedIds = fileList
      .filter((file) => file.filetype === "image")
      .filter((file) => !matchedIds.includes(file.containerid))
      .map((file) => file);
    return unmatchedIds;
  }, [fileList, highLight]);

  useEffect(() => {
    return setUnmatchedIds(getUnmatchedIds());
  }, [getUnmatchedIds]);

  const renderProductCard = (fileDetail: FileDetail, index: number) => {
    const highlightDetail = highLight.find(
      (highlight) => highlight.containerid === fileDetail.containerid
    );
    const shouldDisplayImage =
      highlightDetail && fileDetail.filetype === "image";
    if (!shouldDisplayImage) return null;

    return (
      <div key={index} className="ProductCardTwo">
        <div className="max-width m-auto">


        {index % 2 === 0 ? (
          <>
            {/* <img  className="product-img mobile_view"
              src={fileDetail.filepath}
              alt={fileDetail.filename} /> */}
              <div className="d-flex justify-content-between card-cont left">
              <div className="col product-detail">
              <div className="title">{highlightDetail?.highlightsection}</div>
              <div className="cnt pt-3">{highlightDetail?.highlightdetail}</div>
            </div>
            <img
              className="product-img dsk-view"
              src={fileDetail.filepath}
              alt={fileDetail.filename}
            />
              </div>
            
          </>
        ) : (
          <>
          <div className="d-flex justify-content-between card-cont col-reverse right">
            <img
              className="product-img"
              src={fileDetail.filepath}
              alt={fileDetail.filename}
            />
            <div className="product-detail col">
              <div className="title">{highlightDetail.highlightsection}</div>
              <div className="cnt pt-3">{highlightDetail.highlightdetail}</div>
            </div>
            </div>
          </>
        )}
                </div>
      </div>
    );
  };

  return (
    <>
      {fileList.map((fileDetail, index) =>
        renderProductCard(fileDetail, index)
      )}
      <div className="productCardThree">
        <ProductCardThree galleryList={unmatchedIds} />
      </div>
    </>
  );
};

export default ProductCardTwo;
