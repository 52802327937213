import React, { useEffect, useState } from "react";
import "../page.scss";
import award1 from "../../assets/icon/award 1st.svg";
import award2 from "../../assets/icon/award 2nd.svg";
import award3 from "../../assets/icon/verify.svg";

import my_items_empty from "../../assets/icon/bag-white.svg";
import empty_bid from "../../assets/icon/bid_icon.svg";
import search from "../../assets/icon/magnifying-glass.svg";
import FilterIcon from "../../assets/icon/filter.svg";
import WrongIcon from "../../assets/icon/wrong.svg";
import truck from "../../assets/icon/semi-truck.svg";
import Spacer from "../../components/Spacer";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import { toast } from "react-toastify";
import ApiService, { BASE_URL_IMG } from "../../config/api";
import BiddingCounter from "../../components/biddingCounter";
import moment from "moment";
import { Link } from "react-router-dom";
import { getBiddingCounterText } from "../../services/Utils";
import BiddingOrderIcon from "../../components/BiddingOrderIcon";

const MyCart = () => {
  const dispatch = useDispatch();
  const [myBiddingData, setMyBiddingData] = useState<any>([]);
  const [myFilterBiddingData, setFilterBiddingData] = useState<any>([]);
  const [myItemsData, setMyIemsData] = useState<any>([]);
  const [error, setError] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [noMyItemData, setNoMyItemData] = useState(false);
  const [noMyBiddingData, setNoMyBiddingData] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [actionPriceData, setActionPriceData] = useState("");
  const [actionResultData, setActionResultData] = useState("");
  const [showAddressIndex, setShowAddressIndex] = useState(-1);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [FilterData, setFilterData] = useState(false);
  const [isBiddingFilter, setIsBiddingFilter] = useState(false);
  const [isMyItemFilter, setIsMyItemFilter] = useState(false);
  const userDetail:any = localStorage.getItem('user');

  
  const getMyBidding = async () => {
    dispatch(showLoader());
    let pageNumber = pageNo + 1;
    const myBidding = await ApiService.post(
      "/user/getMyBiddingList",{ filter : null,
        bid_order: null,
        pageNo:pageNumber
        }
    );
    if (myBidding.status === "SUCCESS") {
         setMyBiddingData(myBidding.response);
      if (myBidding?.response?.length === 0) {
        setNoMyBiddingData(true);
      } else {
        setPageNo(pageNumber);
        if(pageNo > 1) {
          setMyBiddingData((myBiddingData: any) => [
            ...myBiddingData,
            ...myBidding?.response,
          ]);
        }
        
      }
      dispatch(hideLoader());
    } else {
      setError(myBidding.response);
      dispatch(hideLoader());
    }
  };

  const filterSubmit = async () => {
    dispatch(showLoader());
    try {
      let pageNumber = pageNo + 1;
      let keyword = {
        filter: actionPriceData,
        bid_order: actionResultData
      };

      const myFilter = await ApiService.post("/user/getMyBiddingList", {
        filter : keyword.filter,
        bid_order: keyword.bid_order,
        page: pageNumber,
        keyword:''  
      });
  
      if (myFilter.status === "SUCCESS") {
        setMyBiddingData(myFilter.response);
        // if (myFilter?.response?.length === 0) {
        //   setNoData(true);  
        // } else {
        //   setPageNo(pageNumber);
        //   setFilterBiddingData((prevData: any) => [
        //     ...prevData,
        //     ...myFilter?.response,
        //   ]);
        // }
        dispatch(hideLoader());
      } else {
        
        setError(myFilter.response);
      }
    } catch (error) {
      console.error("Error occurred while filtering:", error);
      
    } finally {
      
      dispatch(hideLoader());
    }
  };
  
  const buttonHtml:any=(item:any)=>{
    if(item.auctionstatus == 'CLOSED' && item.is_winner == 1){
      return '<button class="button green">ชำระค่าสินค้า</button>';
    } else if(item.auctionstatus != 'CLOSED' && item.deposit_id == '0'){
      return '<button class="button">จองสิทธิ์ประมูล</button>';
    } else if(item.auctionstatus != 'CLOSED' && item.deposit_id != '0'){
      return '<button class="button">เสนอราคา</button>';
    } else if(item.auctionstatus == 'CLOSED'){
      return '<button class="button close_gray">ปิดประมูล</button>';
    }
    
  }
  
  const searchMyBidding = async (event: any) => {
    setIsBiddingFilter(true);
    const nextFormState = event.target.value;
    if (event.key === "Enter") {
      dispatch(showLoader());
      const myBidding = await ApiService.post(
        "/user/getMyBiddingList",{keyword : nextFormState}
      );
      if (myBidding.status === "SUCCESS") {
        setMyBiddingData(myBidding.response);
        dispatch(hideLoader());
      } else {
        setError(myBidding.response);
        dispatch(hideLoader());
      }
    }
  };
  const searchMyItems = async (event: any) => {
    setIsMyItemFilter(true);
    const nextFormState = event.target.value;
    if (event.key === "Enter") {
      dispatch(showLoader());
      let pageNumber = 1;
      const myItems = await ApiService.get(
        `/user/getMyItemList?keyword=${nextFormState}&&pageNo=${pageNumber}`
      );
      if (myItems.status === "SUCCESS") {
        setMyIemsData(myItems.response);
        dispatch(hideLoader());
      } else {
        setError(myItems.response);
        dispatch(hideLoader());
      }
    }
  };
  const getMyItems = async () => {
    dispatch(showLoader());
    let pageNumber = pageNo + 1;
    const myItems = await ApiService.get(
      `/user/getMyItemList?keyword=${""}&&pageNo=${pageNumber}`
    );
    if (myItems.status === "SUCCESS") {
      if (myItems?.response?.length === 0) {
        setNoMyItemData(true);
      } else {
        setPageNo(pageNumber);
        setMyIemsData((myItemsData: any) => [
          ...myItemsData,
          ...myItems?.response,
        ]);
      }
      dispatch(hideLoader());
    } else {
      setError(myItems.response);
      dispatch(hideLoader());
    }
  };
  const handlePriceList = (e: any) => {
    setActionPriceData(e.target.value);
  };
  const handleActionResult = (e: any) => {
    setActionResultData(e.target.value);
  };
  useEffect(() => {
    getMyBidding();
    getMyItems();
  }, []);

  const handleFilterClick = () => {
    setIsFilterClicked((prev) => !prev);
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
 
  const dataToRender = FilterData ? myFilterBiddingData : myBiddingData
  
  return (
    <div className="my-favorite py-5">
      <div className="container">
        <div className="my-bidding">
          <div className="title">My Bidding</div>
          {myBiddingData && myBiddingData.length <= 0 && !isBiddingFilter ? (
            <div
              className="empty-favorite text-center mt-5"
              style={{ display: "block" }}
            >
              <img className="img" src={empty_bid} alt="" />
              <br />
              <div className="mt-3" style={{ color: "#ffffff" }}>
                คุณยังไม่มีรายการสินค้าประมูล
              </div>
            </div>
          ) : (
            <>
              <Spacer height="20px" width="" />
              <div className="search-cont col-md-12 m-auto d-flex justify-content-between align-items-center">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="search-icon">
                      <img src={search} alt="" />
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="ค้นหารายการสินค้า"
                    className="form-control border-0 header-input"
                    id="inlineFormInputGroup"
                    onKeyDown={searchMyBidding}
                  />
                  
                </div>
                <div className="p-2 d-lg-none">
                  <img src={FilterIcon} alt=""  onClick={handleFilterClick} />
                </div>
              </div>

              {isFilterClicked && (
              <div className="category-container d-lg-none d-md-block ">
                <div className="cat-left-cont col-lg-3 col-md-12 col-sm-12 col-12 cat-left-cont z-1">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="filter_text">กรองสินค้า</span>
                      <span className="default_text">ค่าเริ่มต้น</span>
                      <div className="justify-content-end d-flex px-0 h-25">
                        <img src={WrongIcon} alt="WrongIcon" className="cursor-pointer" onClick={() => setIsFilterClicked(false)}/>
                      </div>
                    </div>
                    <div className="sorting_date d-flex flex-column"> 
                      <div className="py-lg-3 py-sm-0 mt-2">
                        <p className="sorting_date_MyBidding">ผลการประมูล</p>
                        <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={handleActionResult}>
                          {actionResult.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="py-lg-3 py-sm-0 mt-2">
                        <p className="sorting_date_MyBidding">การเรียงลำดับข้อมูล</p>
                        <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={handlePriceList}>
                          {priceList.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="d-flex justify-content-center ">
                        <button type="submit" className="my-3 w-75 p-2 filter-products" onClick={filterSubmit}>
                          กรองสินค้า
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              )} 

              {dataToRender.map((item: any, key: number) => (
                  <div key={key} className="favorite-list mt-4">
                   <div className="row favorite-list-row d-none d-md-flex">
                      <div className="col-md-8 d-flex">
                        <div
                          className="col-2 left"
                          style={{
                            backgroundImage: `url("${item?.filepath}"`,
                          }}
                        >
                        <BiddingOrderIcon bid_order={item?.bid_order} productsData={item} userId={userDetail?.userId}></BiddingOrderIcon>
                        </div>
                        <div className="main_container col p-2">
                          <div>
                            <div className="no-txt">{item?.is_winner === 1 && <span className="win-txt mr-2">คุณชนะการประมูล</span>}No. {item.runningno}</div>
                            <div className="name">{item.name}</div>
                          </div>

                          <div className="row py-2" style={{width:'270px'}}>
                            <div className="col-6">
                              <div className="amount-txt">ราคาประมูลล่าสุด</div>
                              <div className="amount">
                                {item.latestbiddingamount} ฿
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="time-txt">
                                {getBiddingCounterText(
                                  item?.auctionstartdatetime,
                                  item?.auctionclosedatetime
                                )}
                              </div>
                              <BiddingCounter data={item} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex justify-content-around flex-column">
                      <Link to={`/productdetails?productId=${item.product_id}`}>
                        <div className="text-right" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: buttonHtml(item) }} />
                      </Link>
                      </div>
                    </div>
                    {/* <div className="row d-md-none d-sm-flex" > */}
                    <div className="d-md-none d-sm-flex" >
                      <div className="d-flex gap-1">
                        <div className="" onClick={() => window.location.href = `/productdetails?productId=${item.product_id}`}
                            style={{ cursor: 'pointer' }}>
                          <div
                            className="left"
                            style={{
                              backgroundImage: `url("${item?.filepath}"`,
                            }}
                          >
                            <img
                              className="m-2"
                              src={
                                item.bid_order === 1
                                  ? award1
                                  : item.bid_order === 2
                                  ? award2
                                  : award3
                              }
                              style={{
                                position: "absolute",
                                borderRadius: "50%",
                              }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="main_container wh-100 px-2" onClick={() => window.location.href = `/productdetails?productId=${item.product_id}`}
                              style={{ cursor: 'pointer' }} >
                        
                            <div className="d-flex flex-column pt-2">
                              <div className="no-txt d-flex flex-column">{item?.is_winner === 1 && <span className="win-txt mr-2">คุณชนะการประมูล</span>}No. {item.runningno}</div>
                               <div className="name truncate-line2">{item.name}</div>    {/* text-truncate */}
                            </div>
                            <div className="d-flex gap-4 justify-content-between">
                              <div className="d-flex gap-2 py-2 flex-column">
                                <div className="amount-txt">ราคาประมูลล่าสุด</div>
                                <div className="amount text-break">
                                  {item.latestbiddingamount} ฿
                                </div>
                              </div>
                              <div className="d-flex gap-2 p-2 flex-column">
                                <div className="time-txt">
                                  {getBiddingCounterText(
                                    item?.auctionstartdatetime,
                                    item?.auctionclosedatetime
                                  )}
                                </div>
                                <BiddingCounter data={item} />
                              </div> 
                            </div>

                            {/* <div className="row">
                              <div className="col-6">
                                <div className="amount-txt">ราคาประมูลล่าสุด</div>
                                <div className="amount">
                                  {item.latestbiddingamount} ฿
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="time-txt">
                                  {getBiddingCounterText(
                                    item?.auctionstartdatetime,
                                    item?.auctionclosedatetime
                                  )}
                                </div>
                                <BiddingCounter data={item} />
                              </div>
                            </div> */}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          {!noMyBiddingData && (parseInt(myBiddingData.totalRecord) > 5) && (
            <>
              <div className="text-center">
                <button
                  className="bottom_btn my-5"
                  onClick={() => [setExpanded(!expanded), getMyBidding()]}
                >
                  {expanded ? "แสดงรายการน้อยลง" : "แสดงรายการเพิ่มเติม"}
                </button>
              </div>
            </>
          )}

          <Spacer height={"30px"} width={0} />
          <div className="title">My Item</div>
          <>
            {myItemsData && myItemsData.length <= 0  && !isMyItemFilter ? (
              <div
                className="empty-favorite text-center mt-5"
                style={{ display: "block" }}
              >
                <img className="img" src={my_items_empty} alt="" />
                <br />
                <div className="mt-3" style={{ color: "#ffffff" }}>
                  ค้นหารายการสินค้าที่ถูกใจ
                </div>
              </div>
            ) : (
              <>
                <Spacer height="20px" width="" />
                <div className="search-cont col-md-12 m-auto">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="search-icon">
                        <img src={search} alt="" />
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="ค้นหารายการสินค้า"
                      className="form-control border-0 header-input"
                      id="inlineFormInputGroup"
                      onKeyDown={searchMyItems}
                    />
                  </div>
                </div>
                {myItemsData &&
                  myItemsData.map((item: any, key: number) => (
                    <div key={key} className="favorite-list my-item mt-4">
                      <div className="main_container d-md-block d-sm-none d-none">
                      <div className="row favorite-list">
                        <div className="col-2 p-0" style={{width:'115px',height:'115px'}} onClick={() => window.location.href = `/productdetails?productId=${item.product_id}`}>
                           <img src={item?.filepath} alt="" className="w-100 h-100" />
                        </div>
                        <div className="col-4 p-2" onClick={() => window.location.href = `/productdetails?productId=${item.product_id}`}>
                          <div className="row">
                            <div className="no-txt">No. {item.runningno}</div>
                            <div className="name text-truncate">{item.name}</div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-6">
                              <div className="amount-txt">ราคา</div>
                              <div className="amount">{item.latestbiddingamount} ฿</div>
                            </div>
                            <div className="col-6">
                              <div className="amount-txt">วันที่ชำระเงิน</div>
                              <span className="time">
                                {moment(item.paymentdate).format("YYYY-MM-DD")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col p-2">
                        <div className="no-txt">ที่อยู่จัดส่ง</div>
                              <div className="address_text_my_item">
                                {item.name}
                                <br />
                                {item.address1}
                                {", "}
                                {item.address2}
                                <br />
                                {item.city}
                                {", "}
                                <br />
                                {item.country}
                                {item.pincode ? ", " + item.pincode : ""}
                              </div>
                        </div>
                        <div className="col-3 p-2 flex-wrap my-auto">
                          <div className="d-flex justify-content-center">
                            <div className="mt-2" style={{width:'48px',height:'48px'}}>
                              <img src={truck} alt="" className="p-2 w-100 h-100"/>
                            </div>
                            <span className="p-2"> 
                              <div className="amount-txt ">Tracking Number</div>
                              <div className="time word-wrap">
                                {item?.transport_type} <br></br>
                                {item.tracking_number ? item.tracking_number : "อยู่ในระหว่างการจัดส่ง"}
                              </div>
                              <div></div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="d-md-none d-sm-block d-block">
                      <div className="col-12">
                        <div className="d-flex gap-1 text-white">                        
                          <div  style={{height:'115px'}} onClick={() => window.location.href = `/productdetails?productId=${item.product_id}`}>
                            <img src={item?.filepath} alt="" className="h-100" />
                          </div>
                          <div className="p-2 col">
                            <div onClick={() => window.location.href = `/productdetails?productId=${item.product_id}`}>
                              <div className="no-txt">No. {item.runningno}</div>
                              <div className="name text-break">{item.name}</div>
                            </div>
                            <div className="d-flex mt-2 justify-content-between">
                              <span> 
                                <div className="text-white" style={{fontSize:"12px"}}>Tracking Number</div>
                                <div className="time text-wrap">
                                  {item?.transport_type}
                                  {item.tracking_number ? item.tracking_number : "อยู่ในระหว่างการจัดส่ง"}
                                </div>
                              </span>
                              <div style={{width:'48px',height:'48px'}}  onClick={() => setShowAddressIndex(key)}>
                                <img src={truck} alt="" className="p-2 w-100 h-100"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {showAddressIndex === key && (
                        <div>
                        <div className="col-12 p-2">
                        <div className="no-txt mt-1 mb-4">ที่อยู่จัดส่ง</div>
                              <div className="address_text_my_item">
                                {item.name}
                                <br />
                                {item.address1}
                                {", "}
                                {item.address2}
                                <br />
                                {item.city}
                                {", "}
                                <br />
                                {item.country}
                                {item.pincode ? ", " + item.pincode : ""}
                              </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center p-2 pb-3">
                          <svg onClick={() => setShowAddressIndex(-1)}  width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.3125 8.33398L8.8125 0.833984L16.3125 8.33398" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {!noMyItemData && (parseInt(myItemsData.totalRecord) > 5) && (
                  <>
                    <div className="text-center">
                      <button
                        className="bottom_btn my-5"
                        onClick={() => [setExpanded(!expanded), getMyItems()]}
                      >
                        {expanded ? "แสดงรายการน้อยลง" : "แสดงรายการเพิ่มเติม"}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
export default MyCart;

const priceList = [
  { id: "", text: "Select" },
  { id: "high-low", text: "ราคาสูงไปต่ำ" },
  { id: "low-high", text: "ราคาต่ำไปสูง" },
];

const actionResult = [
  {id: "", text: "Select"},
  {id: "1", text: "ชนะ / ที่ 1/"},
  {id: "2", text: "ชนะ / ที่ 2/"},
  {id: "3", text: "ชนะ / ที่ 3/"}
]
