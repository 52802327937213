import types from "./actionTypes";


export const registerStart = (form: any) => (
  {
    type: types.REGISTER_START,
    payload: form,
  }
);

export const registerSuccess = (user: any) => ({
  type: types.REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (error: any) => ({
  type: types.REGISTER_FAILURE,
  payload: error,
});

