import React, { useState, useEffect } from "react";
import heart from "../assets/icon/heart.svg";
import heartFull from "../assets/icon/heart-full-red.svg";
import "./component.scss";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { addFavourite } from "../controller/FavouriteFunction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";
import { openLoginModal } from "../store/login/actions";
import ApiService, { BASE_URL_IMG } from "../config/api";
import moment from "moment";
import { response } from "express";
import BiddingCounter from "./biddingCounter";
import { getBiddingCounterText } from "../services/Utils";
import BiddingStar from "./BiddingOrderIcon";
import BiddingOrderIcon from "./BiddingOrderIcon";

const CardThree = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionData = useSelector((state: any) => state.login);
  const datasList = props?.datas;
  const [error, setError] = useState(null);
  const userDetail:any = localStorage.getItem('user');

  const addFavouriteSubmit = async (event: any, productId: bigint) => {
    event.preventDefault();
    if (sessionData.isAuthenticated) {
      dispatch(showLoader());
      let favRes = await addFavourite(productId);
      if (favRes?.status === true && favRes?.response?.status === "SUCCESS") {
        datasList.is_favourite === 1
          ? (datasList.is_favourite = 0)
          : (datasList.is_favourite = 1);
        datasList.like_count = favRes.response.like_count;
      } else {
        toast.warning("Oops! Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        datasList.is_favourite = 0;
      }
      dispatch(hideLoader());
    } else {
      dispatch(openLoginModal(true));
    }
  };

  if (error) {
    toast.warning("error", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const currencyFormat = (num: any) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  return (
    <Link to={`/productdetails?productId=${datasList?.product_id}`}>
      <div className="col-md-12 mt-4 card-three">
        <div className="top position-relative">
        {(datasList?.product_purchased_by == null || datasList?.product_purchased_by == userDetail?.userId) &&  (
          <BiddingOrderIcon bid_order={datasList?.bid_order} productsData={datasList} userId={userDetail?.userId} ></BiddingOrderIcon>
        )}
          
          <img src={datasList?.filepath} alt="" />
        </div>
        <div className="main_container">
          <div className="row mx-0 my-2 mt-3">
            <div className="col-12">
              <div className="no-txt">No. {datasList?.runningno}</div>
              <div className="name">{datasList?.name}</div>
            </div>

            <div
              onClick={(e) => addFavouriteSubmit(e, datasList?.product_id)}
              className="like_cnt"
            >
              <img
                className="like_img"
                src={datasList?.is_favourite === 1 ? heartFull : heart}
                alt=""
              />
              <span className="like_count">{datasList?.like_count}</span>
            </div>
          </div>
          <div className="row mx-0 my-2">
            <div className="col-md-6 my-1">
              <div className="time-txt">
                {getBiddingCounterText(
                  datasList?.auctionstartdatetime,
                  datasList?.auctionclosedatetime
                )}
              </div>
              {/* <div className="time">{timerValue.hours} hours, {timerValue.minutes} minutes, {timerValue.seconds} seconds</div> */}
              <BiddingCounter data={datasList} />
            </div>
            <div className="col-md-6 text-lg-end text-md-right my-1">
              <div className="amount-txt">ราคาประมูลล่าสุด</div>
              <div className="amount">
                {datasList?.latestbiddingamount
                  ? parseInt(datasList?.latestbiddingamount)
                  : parseInt(datasList?.minbidamount)}{" "}
                ฿
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardThree;
