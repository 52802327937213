import React, { Component } from "react";
import Script from "react-load-script";

import "./checkout.scss";
import { useDispatch } from "react-redux";
import { showLoader } from "../../store/loader/loaderSlice";

const publicKey = "pkey_test_5y6j1irupynr28gdmsi";
let OmiseCard: any;

declare global {
  interface Window {
    OmiseCard: string;
  }
}

interface MyComponentProps {
  cart: any; // replace 'any' with the appropriate type for 'cart'
  createCreditCardCharge: any; // replace 'any' with the appropriate type for 'createCreditCardCharge'
}
const CheckoutInternetBanking: React.FC<MyComponentProps> = (props) => {

  const dispatch = useDispatch();

  const handleLoadScript = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey,
      currency: "thb",
      frameLabel: "Sabai Shop",
      submitLabel: "PAY NOW",
      buttonLabel: "Pay with Omise",
    });
  };

  const internetBankingConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "internet_banking",
      otherPaymentMethods: [
        "bill_payment_tesco_lotus",
        "alipay",
        "pay_easy",
        "net_banking",
        "convenience_store",
      ],
    });
    OmiseCard.configureButton("#internet-banking");
    OmiseCard.attach();
  };

  const omiseCardHandler = () => {
    const { cart, createCreditCardCharge } = props;
    OmiseCard.open({
      frameDescription: "Invoice #3847",
      amount: cart.amount,
      onCreateTokenSuccess: (token: any) => {
        createCreditCardCharge(token);
      },
      onFormClosed: () => {},
    });
  };

  const handleClick = (e: any) => {
    dispatch(showLoader());
    e.preventDefault();
    internetBankingConfigure();
    omiseCardHandler();
  };

  return (
    <div className="own-form">
      <Script url="https://cdn.omise.co/omise.js" onLoad={handleLoadScript} />
      <form>
        <button
          id="internet-banking"
          className="btn internet-banking"
          type="button"
          disabled={props.cart.amount === 0}
          onClick={handleClick}
        >
          Pay with Internet Banking / Others
        </button>
      </form>
    </div>
  );
};

export default CheckoutInternetBanking;
