import React from "react";

import first from "../assets/icon/award1.svg";
import second from "../assets/icon/award 2nd.svg";
import award_icon from "../assets/icon/verify.svg";

interface ChildProps {
  bid_order: any;
  productsData :any,
  userId:any
}

const BiddingOrderIcon: React.FC<ChildProps> = ({
  bid_order,
  productsData,
  userId
}) => {
  console.log(userId)
  return (
   
    <>
      {bid_order === 1 && (
          <img className="verify-icon-home" src={first} alt="" />
      )}

      {bid_order === 2 && (
          <img className="verify-icon-home" src={second} alt="" />
      )}

      {bid_order === 3 && (
          <img className="verify-icon-home" src={award_icon} alt="" />
      )}

      {/* {productsData?.product_purchased_by && productsData?.product_purchased_by == userId && (
          <img className="verify-icon-home" src={first} alt={userId} />
      )} */}
    </>
  );
};

export default BiddingOrderIcon;
