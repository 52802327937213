// actions.js
export const setBiddingProductData = (
  productId: number,
  biddingAmount: number,
  auctionId: number,
  depositAmount?:number,
  serviceAmount?:number,
  isBuyOutPayment?:boolean,
) => ({
  type: "SET_BIDDING_DATA",
  payload: { productId, biddingAmount, auctionId,depositAmount,serviceAmount, isBuyOutPayment },
});

export const setBiddingPaymentStatus = (paymentStatus: any) => ({
  type: "SET_BIDDING_PAYMENT_STATUS",
  payload: paymentStatus,
});

export const setBiddingPaymentMode = (paymentMode: any) => ({
  type: "SET_BIDDING_PAYMENT_MODE",
  payload: paymentMode,
});

export const setDepositePaymentType = (paymentType: any) => ({
  type: "SET_DEPOSITE_PAYMENT_TYPE",
  payload: paymentType,
});

export const setIsBuyOutPayment = (isBuyOutPayment: any) => ({
  type: "SET_BUYOUT_PAYMENT",
  payload: isBuyOutPayment,
});

export const setDepositeData = (
  depositAmount: number,
  serviceAmount: number) => ({
  type: "SET_DEPOSITE_DATA",
  payload: {depositAmount,serviceAmount},
});

export const setBiddingShippingId = (shippingId: any) => ({
  type: "SET_BIDDING_SHIPPING_STATUS",
  payload: shippingId,
});

export const setBiddingBillingAddressId = (billingAddressId: any) => ({
  type: "SET_BIDDING_BILLING_ADDRESS_STATUS",
  payload: billingAddressId,
});

export const clearBiddingProductData = () => ({
  type: "CLEAR_BIDDING_DATA",
});

export const setBiddingAllPaymentData = (
  productId: number,
  biddingAmount: number,
  auctionId: number,
  paymentStatus: string,
  shippingId: number,
  billingAddressId: number
) => ({
  type: "SET_BIDDING_ALL_PAYMENT_DATA",
  payload: {
    productId,
    biddingAmount,
    auctionId,
    paymentStatus,
    shippingId,
    billingAddressId,
  },
});
