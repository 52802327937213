import React, { useState, useEffect } from "react";
import logo from "../assets/Logo2.png";
import search from "../assets/icon/magnifying-glass.svg";
import hambager from "../assets/icon/menu.svg";
import heart from "../assets/icon/heart-full.svg";
import closeIcon from "../assets/icon/close-white.svg";
import user from "../assets/icon/avatar-full.svg";
import bag from "../assets/icon/bag-full.svg";
import { Link, useNavigate } from "react-router-dom";
import "./main.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { logOut, openLoginModal } from "../store/login/actions";
import Spacer from "../components/Spacer";
import Filter from "../assets/icon/filter.svg";
import WrongIcon from "../assets/icon/wrong.svg";
import ApiService from "../config/api";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionData = useSelector((state: any) => state.login);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const handleSubmit = (event: any) => {
    let path = event;
    if (sessionData.isAuthenticated && path === "myAccount") {
      window.location.replace("/myAccount");
    } else if (sessionData.isAuthenticated && path === "myCart") {
      navigate("/MyCart");
    } else if (sessionData.isAuthenticated && path === "myFavourite") {
      navigate("/myFavorite");
    } else if (sessionData.isAuthenticated && path === "logout") {

      ApiService.post("/user/logout", {})
  .then(getRes => {

    if (getRes.status === "SUCCESS") {
      dispatch(logOut());
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      navigate("/Home");
    } else {
      console.error("Logout failed:", getRes.data);
    }
  })
  .catch(error => {
    console.error("Error occurred during logout:", error);
    // Handle error appropriately
  });

     
     
    } else if (path === "Faq") {
      navigate("/FAQ");
    } else {
      // navigate("/Register");
      dispatch(openLoginModal(true))
    }
  };

  const handleFilterClick = () => {
    setIsFilterClicked((prev) => !prev);
    navigate('/category', { state: { filterClicked: !isFilterClicked } });
  };
  const searchCategory = (event: any) => {
    const nextFormState = event.target.value;
    setShowFilterImage(nextFormState.trim() !== '');
    if (event.key === "Enter") {
      navigate("/category", { state: { data: nextFormState }, replace: true });
    } else {
      navigate("/category", { state: { data: "" }, replace: true });
    }
  };
  const [isMenu, setMenu] = useState(false);
  const [showExtraRow, setShowExtraRow] = useState(false);
  const [showFilterImage, setShowFilterImage] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const toggleExtraRow = () => {
    setShowExtraRow(!showExtraRow);
  };

  const clearSearchBar = () => {
    setInputValue('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="bg-dark header-page">
      {/* Header Start */}
      <div className="py-3 px-4 container-bg">
        <div className="row disktop-view">
          <div className="col-md-7 col-lg-8 d-flex px-0">
            <div
              onClick={() => navigate("/Home")}
              className="col-md-3 d-flex align-items-center c-pointer"
            >
              <img src={logo} className="m-auto w-100 h-100" alt="" />
            </div>
            <div className="col-md-6 m-auto">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="bg-dark search-icon">
                    <img src={search} alt="" />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="ค้นหาสินค้าที่เข้าร่วมประมูล..."
                  className="form-control bg-dark border-0 header-input"
                  id="inlineFormInputGroup"
                  onKeyDown={searchCategory}
                />
                {showFilterImage && (
                  <img  onClick={handleFilterClick} src={Filter} alt="Filter"className="bg-dark cursor-pointer p-2" />
                )}
                </div>
            </div>
          </div>
          <div className="col-md-5 col-lg-4">
            <ul className="list-group list-group-horizontal">
              {/* <li className="list-group-item border-0">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    Products
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li> */}
              <li
                onClick={() => handleSubmit("Faq")}
                className="list-group-item border-0 d-flex align-items-center text-white"
                style={{ backgroundColor: "#000000" }}
              >
                FAQ
              </li>
              {sessionData.isAuthenticated && (
                <li
                  onClick={() => handleSubmit("myFavourite")}
                  className="list-group-item border-0 d-flex align-items-center text-white"
                  style={{ backgroundColor: "#000000" }}
                >
                  <img src={heart} alt="" />
                </li>
              )}
              {sessionData.isAuthenticated && (
                <li
                  onClick={() => handleSubmit("myCart")}
                  className="list-group-item border-0 d-flex align-items-center text-white"
                  style={{ backgroundColor: "#000000" }}
                >
                  <img src={bag} alt="" />
                </li>
              )}
              <li
                onClick={() => handleSubmit("myAccount")}
                className="list-group-item border-0 d-flex align-items-center text-white"
                style={{ backgroundColor: "#000000" }}
              >
                <img src={user} alt="" />
                {/* <span
                className="px-2 border-0 d-flex align-items-center text-white"
              >AA</span> */}
              </li>
              {sessionData.isAuthenticated && (
                <li
                  onClick={() => handleSubmit("logout")}
                  className="list-group-item border-0 d-flex align-items-center text-white"
                  style={{ backgroundColor: "#000000" }}
                >
                  <span className="px-2 border-0 d-flex align-items-center text-white">
                    Logout
                  </span>{" "}
                </li>
              )}
            </ul>
          </div>
        </div>
         <div className="mobile-view">
          <Row>
            <Col className="d-flex align-items-center">
              <ul className="list-group list-group-horizontal">
                <li className="list-group-item border-0 p-0">
                  <div
                    className="col-md-3 d-flex align-items-center c-pointer"
                  >
                    <img src={logo} className="m-auto logo" alt="" onClick={() => navigate("/Home")} />
                    <Spacer width={"1rem"} height={0} />
                    <img
                      src={hambager}
                      className="m-auto"
                      alt=""
                      onClick={() => setMenu(true)}
                    />
                  </div>
                </li>
              </ul>
            </Col>
            {isMenu && (
              <div
                style={{
                  zIndex: 999,
                  padding: "0px",
                  position: "absolute",
                  right: 0,
                  top: showExtraRow ? "6.5rem" : "4.5rem",
                  backgroundColor: "#2c2c2e",
                  color: "#ffffff",
                }}
              >
                <img
                  onClick={() => setMenu(false)}
                  src={closeIcon}
                  style={{
                    zIndex: 999,
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                  }}
                  alt=""
                />
                <div className="my-4">
                  <p
                    onClick={() => navigate("/Home")}
                    style={{ padding: "0px 10px", fontSize: "18px", cursor:"pointer" }}
                  >
                    Home
                  </p>
                  <p onClick={() => navigate("/productlist?searchKey=bidding")} style={{ padding: "0px 10px", fontSize: "18px", cursor:"pointer" }}>
                    Bidding Item
                  </p>
                  <p  onClick={() => navigate("/AboutUs")} style={{ padding: "0px 10px", fontSize: "18px", cursor:"pointer" }}>
                    About Us
                  </p>
                  {sessionData.isAuthenticated && (
                    <>
                      <hr
                        style={{
                          padding: "10px 10px 0px",
                          fontSize: "18px",
                          borderTop: "0.1px solid rgb(138 130 130)",
                          cursor:'pointer'
                        }}
                      />
                      <p
                        onClick={() => navigate("/myAccount")}
                        style={{ padding: "0px 10px", fontSize: "18px",cursor:'pointer' }}
                      >
                        My Account
                      </p>
                      <p
                        onClick={() => navigate("/MyCart")}
                        style={{ padding: "0px 10px", fontSize: "18px",cursor:'pointer' }}
                      >
                        My Bidding
                      </p>
                      <p
                        onClick={() => navigate("/myFavorite")}
                        style={{ padding: "0px 10px", fontSize: "18px",cursor:'pointer' }}
                      >
                        My Favourite
                      </p>
                    </>
                  )}
                  <p
                    onClick={() => handleSubmit("logout")}
                    style={{
                      padding: "10px 10px 0px",
                      fontSize: "18px",
                      borderTop: "0.1px solid rgb(138 130 130)",
                      cursor:'pointer'
                    }}
                  >
                    Logout
                  </p>
                </div>
              </div>
            )}
            <Col className="px-0">
              <ul className="list-group list-group-horizontal float-right">
                <li
                  className="list-group-item border-0 d-flex align-items-center text-white"
                  style={{ backgroundColor: "#000000" }}
                >
                  <img src={search} alt="search" onClick={toggleExtraRow} />
                </li>

                <li
                  onClick={(e) => handleSubmit("myAccount")}
                  className="list-group-item border-0 d-flex align-items-center text-white"
                  style={{ backgroundColor: "#000000" }}
                >
                  <img src={user} alt="user" />
                </li>
              </ul>
            </Col>
          </Row>
          {showExtraRow && (
            <Row className="d-flex align-items-center pt-1" style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}>
              <div className="col-10 justify-content-end px-0">
                <Row>
                  <div className="position-relative">
                    <input
                      className="override-input"
                      type="text"
                      id="inlineFormInputGroup"
                      placeholder="ค้นหาชื่อสินค้า"
                      value={inputValue}
                      onChange={handleInputChange}    
                      onKeyDown={searchCategory}
                      style={{
                        color: "white",
                        border: "none",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                        borderBottom: "1px solid #979797",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        width: '100%',
                        outline: 'none'
                      }}
                    />
                    <div style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}>
                      <img src={WrongIcon} alt="WrongIcon" onClick={clearSearchBar} />
                    </div>
                  </div>
                </Row>
              </div>
              <div className="col-2 justify-content-end d-flex px-0" onClick={handleFilterClick}><img src={Filter} alt="Filter" /></div>
            </Row>
          )}
        </div>
      </div>
     {/* Header End */}
    </div>
  );
};

export default Header;
