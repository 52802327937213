import React, { useState } from "react";
import "./component.scss";
import { Modal } from "react-bootstrap";
import close from "../assets/icon/close-white.svg";
import Spacer from "./Spacer";
import TagFormat from "./TagFormat";
import { hideLoader, showLoader } from "../store/loader/loaderSlice";
import { useDispatch } from "react-redux";
import ApiService from "../config/api";
import { toast } from "react-toastify";
import { setBiddingPaymentStatus } from "../store/bidding/action";

const BankDetailModal = ({ isOpen, isClose, productData }: any) => {
  const dispatch = useDispatch();

  const [bank, setBank] = useState("");
  const [accountNo, setaccountNo] = useState("");
  const [accountName, setaccountName] = useState("");

  const validationSet = () => {
    if (bank === "" || accountNo === "" || accountName === "") {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let isVaild = validationSet();
    if (isVaild) {
      dispatch(showLoader());
      let data = {
        productId: productData?.product_id,
        biddingAmount: productData?.latestAuctionList?.[0]?.bidding_amount,
        status: "REFUND_INITIATE",
        paymentType: "R",
        auctionId: productData?.auction_id,
        bankname: bank,
        accountname: accountName,
        accountnumber: accountNo,
      };
      const paymentInitiate = await ApiService.post(
        "/payment/create-refund",
        data
      );
      if (paymentInitiate.status === "SUCCESS") {
        dispatch(setBiddingPaymentStatus(data?.status));
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
      }
    } else {
      toast.warning("Please fill the form fields!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  return (
    <Modal show={isOpen}>
      <div className="add-bank-modal">
        <div className="text-right">
          <img src={close} alt="" onClick={() => isClose()} />
        </div>

        <div className="header my-3">กรอกบัญชีธนาคาร</div>
        <Spacer height="20px" width={undefined} />

        <div className="row">
          <div className="col-12">
            <TagFormat className="" Tag="p" text="ธนาคาร" />
            <input
              className="col-12 register_inputField"
              placeholder=""
              name="bank"
              value={bank}
              onChange={(e) => setBank(e.target.value)}
            />
          </div>
          <Spacer height="20px" width={undefined} />
          <div className="col-12 ">
            <TagFormat className="" Tag="p" text="ชื่อบัญชี" />
            <input
              className="col-12 register_inputField"
              placeholder=""
              name="accountName"
              value={accountName}
              onChange={(e) => setaccountName(e.target.value)}
            />
          </div>
          <Spacer height="20px" width={undefined} />

          <div className="col-12">
            <TagFormat className="" Tag="p" text="เลขที่บัญชี" />
            <input
              className="col-12 register_inputField"
              placeholder=""
              name="accountNo"
              value={accountNo}
              onChange={(e) => setaccountNo(e.target.value)}
            />
          </div>
        </div>
        <Spacer height="30px" width="" />
        <div
          onClick={handleSubmit}
          className="col-12 register_button_cont text-center"
        >
          <button className="col-12 register_button">บันทึก</button>
        </div>
        <Spacer height="30px" width="" />
        <div className="col-12 register_button_cont text-center">
          <button
            onClick={() => isClose()}
            className="col-12 cancel_button register_button"
          >
            ยกเลิก
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BankDetailModal;
