import bgImage from "../../assets/refundBg.png";
import "../component.scss";
import { Modal } from "react-bootstrap";
import close from "../../assets/icon/close-white.svg";
import { useNavigate } from "react-router-dom";
import biddingImage from "../../assets/Image-Product.png";
import Spacer from "../Spacer";
import {
  setBiddingPaymentMode,
  setBiddingPaymentStatus,
  setBiddingProductData,
} from "../../store/bidding/action";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loader/loaderSlice";
import ApiService from "../../config/api";

const BiddingAmountRefundModal = ({ isOpen, isClose, productData }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeModal = () => {
    isClose();
  };
  console.log(productData);
  const handleBiddingPayment = async () => {
    // dispatch(
    //   setBiddingProductData(
    //     productData?.product_id,
    //     productData?.productdepositamount,
    //     productData?.auction_id
    //   )
    // );

    // navigate("/bankaccount", {state:'refundFlow3', replace:true});
    if (productData?.paymentDetails[0]?.payment_mode === "Internet Banking") {
      dispatch(
        setBiddingPaymentMode(productData?.paymentDetails[0]?.payment_mode)
      );
      isClose();
    } else {
      dispatch(showLoader());
      let data = {
        productId: productData?.product_id,
        status: "REFUND_INITIATE",
        paymentType: "R",
        auctionId: productData?.auction_id,
      };
      const paymentInitiate = await ApiService.post(
        "/payment/create-refund",
        data
      );
      if (paymentInitiate.status === "SUCCESS") {
        dispatch(setBiddingPaymentStatus(data?.status));
        dispatch(hideLoader());
        //setRefundInitiate(true);
        closeModal();
      } else {
        // setError(paymentInitiate.response);
        dispatch(hideLoader());
      }
    }
  };
  return (
    <>
      <Modal show={isOpen}>
        {/* <img
          style={{ position: "relative", zIndex: 99 }}
          src={close}
          alt=""
          onClick={closeModal}
        /> */}
        <div className="col-md-12 mt-4 payment-modal">
          <div className="text-center">
            <img src={bgImage} className="refund_bgimg" alt=""></img>
          </div>
          <div className="refund-modal-content mt-5">
            <p className="title">สินค้านี้ได้ผู้ชนะการประมูลแล้ว</p>
            <Spacer height={".7rem"} width={0} />
            <img
              src={productData?.fileList[0]?.filepath}
              className="close-img"
              alt=""
            ></img>
            <div className="pt-4">
              <p>{productData.name}</p>
              <h1>{parseInt(productData?.productdepositamount)} ฿</h1>
            </div>
            <div>
              <Spacer height={"1rem"} width={0} />
              <p className="sub-cnt">
                ระบบได้ส่งผลการประมูลเมลที่คุณสมัคร@gmail.comหากไม่ชำระเงินเราจะยกสิทธิ์ประมูลนี้ให้ผู้ชนะลำดับถัดไป
              </p>
              <Spacer height={"1.5rem"} width={0} />
              <div className="footer-bottom">
                <button onClick={handleBiddingPayment} className="white-btn">
                  รับมัดจำสินค้าคืน
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BiddingAmountRefundModal;
