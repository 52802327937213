import { TagFormat } from "../../../components";
import Spacer from "../../../components/Spacer";
import "../styles.scss";

const Contactus = () => {
  return (
    <div className="terms-of-use">
      <div className="max-width py-2">
        <TagFormat Tag="h3" className="aboutus-title" text="ติดต่อเรา" />
        <div>
          <p>กรอก</p>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
