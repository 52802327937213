import "./page.scss";
import { TagFormat } from "../components";

const ProductAction = () => {
  const productList = [
    {
      id: 1,
      img: require("../assets/Rectangle.png"),
      title:
        "IGNITE STORE (อิ๊กไนท์ สโตร์) แบรนด์สินค้า Life -style ที่ออกแบบโดยใช้ลิขสิทธิ์คาแรคเตอร์ยอดฮิตจากภาพยนตร์จากประเทศสหรัฐอเมริกา และอนิเมชั่นการ์ตูนญี่ปุ่นที่มีชื่อเสียง มาผลิตเป็นสินค้าที่มีดีไซน์ ราคาจับต้องได้และตอบโจทย์ทุกไลฟ์สไตล์ในปัจจุบัน  มีดีไซน์ที่ชัดเจนโดดเด่นและทันสมัย สามารถนำไปใช้ได้ในชีวิตประจำวัน เป็นสินค้าที่ถูกลิขสิทธิ์จึงสามารถเก็บเป็นของสะสมหรือใช้เป็นของขวัญ ของฝากได้ในทุกโอกาส  มั่นใจได้ว่าสินค้าของอิ๊กไนท์ เป็นสินค้าลิขสิทธิแท้ทุกรายการ",
    },
    {
      id: 2,
      img: require("../assets/Rectangle.png"),
      title:
        "IGNITE STORE (อิ๊กไนท์ สโตร์) แบรนด์สินค้า Life -style ที่ออกแบบโดยใช้ลิขสิทธิ์คาแรคเตอร์ยอดฮิตจากภาพยนตร์จากประเทศสหรัฐอเมริกา และอนิเมชั่นการ์ตูนญี่ปุ่นที่มีชื่อเสียง มาผลิตเป็นสินค้าที่มีดีไซน์ ราคาจับต้องได้และตอบโจทย์ทุกไลฟ์สไตล์ในปัจจุบัน  มีดีไซน์ที่ชัดเจนโดดเด่นและทันสมัย สามารถนำไปใช้ได้ในชีวิตประจำวัน เป็นสินค้าที่ถูกลิขสิทธิ์จึงสามารถเก็บเป็นของสะสมหรือใช้เป็นของขวัญ ของฝากได้ในทุกโอกาส  มั่นใจได้ว่าสินค้าของอิ๊กไนท์ เป็นสินค้าลิขสิทธิแท้ทุกรายการ",
    },
    {
      id: 3,
      img: require("../assets/Rectangle.png"),
      title:
        "IGNITE STORE (อิ๊กไนท์ สโตร์) แบรนด์สินค้า Life -style ที่ออกแบบโดยใช้ลิขสิทธิ์คาแรคเตอร์ยอดฮิตจากภาพยนตร์จากประเทศสหรัฐอเมริกา และอนิเมชั่นการ์ตูนญี่ปุ่นที่มีชื่อเสียง มาผลิตเป็นสินค้าที่มีดีไซน์ ราคาจับต้องได้และตอบโจทย์ทุกไลฟ์สไตล์ในปัจจุบัน  มีดีไซน์ที่ชัดเจนโดดเด่นและทันสมัย สามารถนำไปใช้ได้ในชีวิตประจำวัน เป็นสินค้าที่ถูกลิขสิทธิ์จึงสามารถเก็บเป็นของสะสมหรือใช้เป็นของขวัญ ของฝากได้ในทุกโอกาส  มั่นใจได้ว่าสินค้าของอิ๊กไนท์ เป็นสินค้าลิขสิทธิแท้ทุกรายการ",
    },
    {
      id: 1,
      img: require("../assets/Rectangle.png"),
      title:
        "IGNITE STORE (อิ๊กไนท์ สโตร์) แบรนด์สินค้า Life -style ที่ออกแบบโดยใช้ลิขสิทธิ์คาแรคเตอร์ยอดฮิตจากภาพยนตร์จากประเทศสหรัฐอเมริกา และอนิเมชั่นการ์ตูนญี่ปุ่นที่มีชื่อเสียง มาผลิตเป็นสินค้าที่มีดีไซน์ ราคาจับต้องได้และตอบโจทย์ทุกไลฟ์สไตล์ในปัจจุบัน  มีดีไซน์ที่ชัดเจนโดดเด่นและทันสมัย สามารถนำไปใช้ได้ในชีวิตประจำวัน เป็นสินค้าที่ถูกลิขสิทธิ์จึงสามารถเก็บเป็นของสะสมหรือใช้เป็นของขวัญ ของฝากได้ในทุกโอกาส  มั่นใจได้ว่าสินค้าของอิ๊กไนท์ เป็นสินค้าลิขสิทธิแท้ทุกรายการ",
    },
  ];
  return (
    <div className="terms-of-use">
      <div className="product-pading">
        <TagFormat
          Tag="h3"
          className="aboutus-title"
          text="วิธีการประมูลสินค้า"
        />
        <div className="row col-12 p-0 " >
          {productList.map((item, key) => (
            <div key={key} className="col-lg-6 col-md-12 col-sm-12 relative p-2">
              <img src={item.img} alt=""  style={{ boxSizing : 'content-box' }}  />
              <div className="number">{key + 1}</div>
              <p style={{ fontSize: "16px", padding: "3rem 0px" }}>
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductAction
