import { TagFormat } from "../../../components";
import Spacer from "../../../components/Spacer";
import "../styles.scss";

const PrivacyPolicy = () => {
  return (
    <div className="terms-of-use">
      <div className="max-width py-2">
        <TagFormat
          Tag="h3"
          className="aboutus-title"
          text="นโยบายความเป็นส่วนบุคคล"
        />
        <div>
          <p>
            บริษัท พีเคเอ็น อินเตอร์โฮลดิ้ง จำกัด
            ตระหนักถึงความสำคัญของข้อมูลส่วนบุคคล (“ข้อมูลส่วนบุคคล” หมายถึง
            ข้อมูลใดๆ ที่ถูกบันทึกไว้
            ทั้งที่อยู่และไม่อยู่ในรูปแบบข้อมูลที่มีสาระสำคัญ
            ซึ่งสามารถระบุตัวบุคคลดังกล่าวปรากฏชัดเจนหรือสามารถตรวจสอบยืนยันได้อย่างสมเหตุสมผลและโดยตรงจากบริษัทที่ถือครองข้อมูล
            หรือเมื่อรวมเข้ากับข้อมูลอื่นๆ
            แล้วจะสามารถระบุตัวบุคคลได้โดยตรงและแน่นอน)
            รวมถึงข้อมูลส่วนบุคคลที่ละเอียดอ่อนซึ่งมีข้อมูลเกี่ยวกับเชื้อชาติ
            เผ่าพันธุ์ ความคิดเห็นทางการเมือง ลัทธิ ความเชื่อทางศาสนาหรือปรัชญา
            พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ทุพพลภาพ
            ข้อมูลสหภาพการค้า ข้อมูลทางพันธุกรรม ข้อมูลไบโอเมตริกซ์
            หรือข้อมูลใดๆ ที่อาจส่งผลกระทบต่อเจ้าของข้อมูลในลักษณะเดียวกัน
            (“ข้อมูลส่วนบุคคลที่ละเอียดอ่อน”)
            ตามที่กำหนดไว้ภายใต้ระเบียบข้อบังคับ แนวทางปฏิบัติ
            คำสั่งที่ออกภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
            (2019) ที่บังคับใช้ และการแก้ไขเพิ่มเติมกฎหมายใดๆ
            หรือการออกกฎหมายใดๆ ภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
            2562 (2019) เป็นครั้งคราว (เรียกรวมกันว่า “PDPA”)
            เราพยายามที่จะปกป้องข้อมูลส่วนบุคคลด้วยการปฏิบัติตาม PDPA
            และนโยบายความเป็นส่วนตัวฉบับนี้เมื่อต้องทำการประมวลผลข้อมูลส่วนบุคคล
            (“นโยบายความเป็นส่วนตัว”) และด้วยการประมวลผลข้อมูลส่วนบุคคลของลูกค้า
            ผู้ถือหุ้น พนักงาน และผู้สมัครงานสำหรับการจ้างงาน
            หรือกิจกรรมการสรรหาพนักงานที่อาจเกิดขึ้นของบริษัท
          </p>
          <Spacer height={20} width={0} />
          <p>
            เราจะเก็บรวบรวมข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนในลักษณะที่เหมาะสมซึ่งรวมถึง
            (แต่ไม่จำกัดเฉพาะ) เพื่อผลประโยชน์สาธารณะ,
            ผลประโยชน์ที่มีความสำคัญสูงสุด, เพื่อการปฏิบัติตามสัญญา,
            เพื่อการเข้าทำสัญญา, ภาระผูกพันตามสัญญา,
            การปฏิบัติงานตามภาระหน้าที่, ผลประโยชน์ตามกฎหมาย
            และภาระผูกพันทางกฎหมาย
          </p>
          <Spacer height={20} width={0} />
          <p>
            เราจะไม่เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            เว้นแต่จะได้รับความยินยอมจากเจ้าของข้อมูลเป็นลายลักษณ์อักษรหรือวิธีการทางอิเล็กทรอนิกส์ก่อนหรือในเวลาที่เก็บรวบรวม
            ใช้ หรือเปิดเผย
          </p>
          <Spacer height={20} width={0} />
          <p>
            ข้อกำหนดผู้ขาย หากคุณส่งล็อตสำหรับการประมูลในตลาดออนไลน์ของเรา
            จะมีการใช้กฎเฉพาะสำหรับผู้ขาย อ่านได้ที่นี่ ข้อกำหนดของผู้ซื้อ
            หากคุณซื้อล็อตในตลาดออนไลน์ของเรา จะใช้กฎเฉพาะสำหรับผู้ซื้อ
            อ่านได้ที่นี่ นโยบาย เพื่อให้ตลาดออนไลน์ของเราปลอดภัยและเชื่อถือได้
            เรามีนโยบายหลายอย่างที่ใช้กับทุกการใช้งานตลาดออนไลน์ของเรา (เช่น
            นโยบายคำติชม นโยบายสิทธิ์ในการถอนของสหภาพยุโรป
            และนโยบายผู้ขายมืออาชีพ) คุณสามารถดูนโยบายของเราได้ที่นี่
          </p>
          <Spacer height={20} width={0} />

          <p>
            เราจะแจ้งให้ท่านทราบเกี่ยวกับวัตถุประสงค์ในการใช้ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของท่าน
            และจะใช้ข้อมูลดังกล่าวภายในขอบเขตของวัตถุประสงค์ที่ระบุและ/หรือเพื่อวัตถุประสงค์รอง
          </p>
          <Spacer height={20} width={0} />
          <p>
            เราจะใช้มาตรการที่จำเป็นและเหมาะสมในการจัดการข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนอย่างปลอดภัย
            เราจะเก็บรวบรวม / ใช้ /
            เปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับความยินยอมจากเจ้าของข้อมูลในกรณีที่
            จำเป็นต่อวัตถุประสงค์, สถานการณ์ที่คุกคามชีวิต,
            ข้อมูลที่เปิดเผยต่อสาธารณะ, ผลประโยชน์ส่วนรวม,
            ผลประโยชน์ที่มีความสำคัญสูงสุด, ภาระผูกพันตามสัญญา,
            งานตามภาระหน้าที่, ผลประโยชน์ตามกฎหมาย, ภาระผูกพันทางกฎหมาย,
            การสืบสวน, วัตถุประสงค์ในการประเมินผล, วัตถุประสงค์ด้านศิลปะ,
            กิจกรรมข่าว, การให้บริการทางกฎหมาย, มีการเก็บรวบรวมโดยเครดิตบูโร,
            วัตถุประสงค์ในการจ้างงาน, วัตถุประสงค์เพื่อผลประโยชน์ของพนักงาน,
            มีการเปิดเผยโดยหน่วยงานราชการ
            เป็นต้นเว้นแต่จะได้กำหนดไว้เป็นอย่างอื่น
          </p>
          <Spacer height={20} width={0} />
          <p>
            เราจะไม่แบ่งปันหรือเปิดเผยข้อมูลส่วนบุคคลหรือข้อมูลส่วนบุคคลที่ละเอียดอ่อนแก่บุคคลที่สามใดๆ
            ยกเว้นกรณีที่มีการระบุไว้เป็นอย่างอื่น เว้นแต่ในกรณีที่
            ได้รับความยินยอม, ถือว่าได้รับความยินยอม,
            ความยินยอมไม่จำเป็นสำหรับการประมวลผล
          </p>
          <Spacer height={20} width={0} />
          <p>
            เราจะตอบสนองอย่างเหมาะสมในกรณีที่เจ้าของข้อมูลติดต่อจุดติดต่อที่เหมาะสมเกี่ยวกับการแสดง
            การแก้ไข การลบ การถอน (ทั้งหมดหรือบางส่วน)
            และ/หรือการยกเลิกการใช้ข้อมูลส่วนบุคคลที่เกี่ยวข้องหรือข้อมูลส่วนบุคคลที่ละเอียดอ่อน
          </p>
          <Spacer height={20} width={0} />
          <p>
            วัตถุประสงค์ในการใช้ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อน
            เราใช้ข้อมูลส่วนบุคคลที่เจ้าของข้อมูลแต่ละรายให้ไว้เพื่อวัตถุประสงค์ดังต่อไปนี้เท่านั้น
            (“ วัตถุประสงค์”) : (1)
            ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของลูกค้า
            (รวมถึงผู้ใช้แอพพลิเคชันซอฟต์แวร์) ของเรา เพื่อตอบ ยืนยัน
            และเก็บข้อมูลเกี่ยวกับการสอบถาม การให้คำปรึกษา การซ่อมแซม
            และการสนับสนุนด้านอื่นๆ ไว้เป็นการถาวร
            เพื่อให้ข้อมูลเกี่ยวกับผลิตภัณฑ์ บริการ และแคมเปญผ่านจดหมายเชิญ
            อีเมล หรือวิธีการอื่นๆ เพื่อจัดส่งสินค้า
            เพื่อพัฒนาสินค้าและปรับปรุงบริการอื่นๆ และ
            เพื่อวัตถุประสงค์อื่นใดที่จำเป็นในการดำเนินการ การบำรุงรักษา
            รวมถึงการจัดการธุรกิจของเราและความสัมพันธ์ของท่านกับเรา
            ซึ่งเราแจ้งให้ท่านทราบในเวลาที่ขอความยินยอมจากท่าน (2)
            ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของผู้ถือหุ้นของเรา
            เพื่อใช้สิทธิและ/หรือปฏิบัติงานตามภาระหน้าที่ภายใต้ PDPA และ
            เพื่อจัดการงานที่เกี่ยวข้องกับผู้ถือหุ้นของเรา เช่น
            การจัดทำบันทึกตามกฎหมายและคำสั่งต่างๆ (3)
            ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของผู้สมัครงาน
            เพื่อติดต่อและ / หรือให้ข้อมูลแก่ผู้สมัครงาน
            รวมถึงการใช้ในรูปแบบอื่นๆที่จำเป็นสำหรับกิจกรรมการจ้างงานและการสรรหา
            (4) ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของพนักงานของเรา
            เพื่อสื่อสารกับพนักงานของเราเกี่ยวกับงานหรือเพื่อวัตถุประสงค์อื่น
            เพื่อจัดการสุขภาพของพนักงานของเรา และ เพื่อปฏิบัติตามกฎหมาย
            (หรือกฎหมายคุ้มครองแรงงาน) ที่บังคับใช้
            เพื่อรักษาและคงไว้ซึ่งประโยชน์และผลประโยชน์ของพนักงาน
            รวมถึงการประเมินผลการปฏิบัติงาน (5)
            ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของบุคคลที่โพสต์ /
            ส่งข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนบนเว็บไซต์หรือแอพพลิเคชันซอฟต์แวร์ของเรา
            เพื่อใช้ในงานที่โพสต์/ส่งบนเว็บไซต์หรือแอพพลิเคชันซอฟต์แวร์ของเราในชิ้นงานโฆษณาสำหรับบริษัทหรือบริษัทในเครือ
          </p>
          <Spacer height={20} width={0} />
          <p>
            *สิทธิการเผยแพร่ ลิขสิทธิ์ และสิทธิในทรัพย์สินทางอุตสาหกรรมอื่นๆ
            ทั้งหมดในงานที่โพสต์/ส่งนั้นจะถือว่าเป็นของผู้ที่โพสต์/ส่งงานดังกล่าว
            อย่างไรก็ตาม เมื่อมีการโพสต์/ส่งงานดังกล่าวจะถือว่าผู้ที่โพสต์
            อนุญาตให้บริษัทใช้สิทธิอย่างครอบคลุมโดยไม่มีค่าตอบแทนการใช้สิทธิและโดยไม่จำกัดแต่เพียงผู้เดียว
            (มีสิทธิอนุญาตให้บุคคลที่สามใช้สิทธิได้)
            (ซึ่งรวมถึงแต่ไม่จำกัดเพียงการทำซ้ำ เผยแพร่ ส่ง แจกจ่าย มอบหมาย
            ให้ยืม แปล และดัดแปลง) งานดังกล่าว
            และผู้โพสต์ตกลงว่าเขาหรือเธอจะไม่อ้างความชอบธรรมในงานดังกล่าว
          </p>
          <Spacer height={20} width={0} />
          <p>
            (6)
            ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของผู้จำหน่ายและซัพพลายเออร์ของเรา
            เพื่อสื่อสารกับผู้จำหน่ายและซัพพลายเออร์ของเรา
            เพื่อตอบคำถามหรือข้อร้องเรียนจากผู้จำหน่ายและซัพพลายเออร์
            เพื่อประมวลผลใบแจ้งหนี้ของผู้จำหน่ายและซัพพลายเออร์ที่เกี่ยวข้องกับการขาย/การจัดหาสินค้าและ/หรือบริการ
            รวมถึงวัตถุประสงค์อื่นๆที่จำเป็นในการดำเนินงานและทำธุรกิจของเรา **
            ท่านตกลงและยินยอมให้เราใช้และประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ตามที่ระบุไว้
            หากท่านไม่ยินยอมให้เราประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์อย่างใดอย่างหนึ่ง
            โปรดแจ้งให้เราทราบตามรายละเอียดการติดต่อด้านล่าง
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
